
import React, { Component, Fragment } from 'react'

import featureFlagConfig from '../../../featureFlagConfig.json'

class FeatureFlag extends Component {
  isFeatureEnabled = (feature) => {
    return featureFlagConfig[feature]
  }

  render() {
    const { feature, children } = this.props

    if (this.isFeatureEnabled(feature)) {
      return <Fragment>{children}</Fragment>
    }

    return null
  }
}

export default FeatureFlag


// @ try this
