const actions = {
  SET_STATE: 'qup/SET_STATE',
  SUBMIT_QUP_REDUCERS: 'qup/SUBMIT_QUP_REDUCERS',
  CHANGE_POINT_VALUE: 'qup/CHANGE_POINT_VALUE',
  GET_QUP_REPORT_DATA: 'qup/GET_QUP_REPORT_DATA',
  GET_QUP_REPORTS: 'qup/GET_QUP_REPORTS',
  GET_QUP_REPORTS_FROM_TABLE: 'qup/GET_QUP_REPORTS_FROM_TABLE',
  FETCH_QUP: 'qup/FETCH_QUP',
}

export default actions
