const endpoints = {
  entities: (id = '') => `entities/${id}`,
  settings: () => `investor_accounts`,
  settingsUpdate: (id = '') => `investor_accounts/${id}`,
  fundMemberSettings: (id = '') => `fund_member_settings/${id}`,
  users: (id = '') => `users/${id}`,
  contactAddresses: (id = '') => `contact_addresses/${id}`,
}

export default endpoints
