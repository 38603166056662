import React, {Fragment} from 'react'

const FormPartContainer = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

export default FormPartContainer
