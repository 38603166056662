import React, { Component, Fragment } from 'react'
import { Form, Radio, Row, Col, Input } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import ContactInformation from 'components/AppComponents/FormSection/formParts/ContactInformation'
// import DBAField from 'components/AppComponents/FormSection/formFields/DBAField'
import BusinessTypeField from 'components/AppComponents/FormSection/formFields/BusinessTypeField'
import TaxNumberField from 'components/AppComponents/FormSection/formFields/TaxNumberField'
import IncorporationStateField from 'components/AppComponents/FormSection/formFields/IncorporationStateField'
import TermsField from 'components/AppComponents/FormSection/formFields/TermsField'

import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import EmailField from 'components/AppComponents/FormSection/formFields/EmailField'
import PhoneNumberField from 'components/AppComponents/FormSection/formFields/PhoneNumberField'
import StreetOneField from 'components/AppComponents/FormSection/formFields/StreetOneField'
import StreetTwoField from 'components/AppComponents/FormSection/formFields/StreetTwoField'
import CityField from 'components/AppComponents/FormSection/formFields/CityField'
import StateField from 'components/AppComponents/FormSection/formFields/StateField'
import CountryField from 'components/AppComponents/FormSection/formFields/CountryField'
import ZipField from 'components/AppComponents/FormSection/formFields/ZipField'
import FinancialSettings from './FinancialSettings'
// import FirstNameField from '../../../../components/AppComponents/FormSection/formFields/FirstNameField'
// import MiddleInitialField from '../../../../components/AppComponents/FormSection/formFields/MiddleInitialField'
// import LastNameField from '../../../../components/AppComponents/FormSection/formFields/LastNameField'
import styles from '../userEditStyle.module.scss'


const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
}

const FORM_NAME='PersonalInfo'

class PersonalInfo extends Component {
  render() {

    const {user} = this.props
    const {entity} = this.props

    return (
      <Fragment>
        <Form className={styles.formLayout} layout="horizontal" {...formItemLayout} colon={false}>
          <h3 className=" h3Border">Personal Information</h3>
          <Form.Item label="Account Type">
             <Input value={user.accountType} />
          </Form.Item>
          <Form.Item label="First Name">
            <Input value={user.firstName} />
          </Form.Item>
          <Form.Item label="Middle Name">
            <Input value={user.middleName} />
          </Form.Item>
          <Form.Item label="Last Name">
            <Input value={user.lastName} />
          </Form.Item>
          <Form.Item label="Email Address">
           <EmailField name="email" placeholder="Email Address" required />
          </Form.Item>
          <Form.Item label="Phone Number">
            <PhoneNumberField placeholder="Phone Number" />
            <RadioField name="isMobile">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioField>
          </Form.Item>
          <Form.Item label="Physical Address">
            <StreetOneField placeholder="Mailing Address Line 1" />
          </Form.Item>
          <Form.Item label=" ">
            <StreetTwoField placeholder="Address Line 2" />
          </Form.Item>
          <Form.Item label="Zip Code">
            <ZipField placeholder="Zip Code" />
            <StateField placeholder="State" />
          </Form.Item>
          <Form.Item label="City">
            <div className="cityFieldWidth">
              <CityField placeholder="City" />
            </div>
          </Form.Item>
          <Form.Item label="Country">
            <div className="countryFieldWidth">
              <CountryField placeholder="Country" />
            </div>
          </Form.Item>
          <h3 className=" h3Border">Investor Account Information</h3>
          <Form.Item label="DBA">
              <Input value={user.firstName + user.lastName} />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item label="Tax ID">
                <Input value={user.taxId} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Inc. State">
                <Input value={user.incorporationState} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="Notes">
                <TermsField />
              </Form.Item>
            </Col>
          </Row>
          <FinancialSettings />
        </Form>
      </Fragment>
    )
  }
}

export default compose(
  reduxForm({
    form: FORM_NAME,
  }),
  connect(
    state => ({
      user: state.user,
    }),
    {},
  ),
)(PersonalInfo)

