import actions from './actions'

const initialState = {
  QUPReportId: 0,

  Entity: "",
  underwriterNameValue: "",
  Loan: "Loan Name",
  date: '',

  ltvRequestedLoan: 0.0,
  ltvAssetValue: 0.0,
  ltvPoints: 0.0,

  arvRequestedLoan: 0.0,
  afterRepairedValue: 0.0,
  arvPoints: 0.0,



  liquidityValue: 0.0,
  balanceValue: 0.0,
  creditworthinessValue: 0.0,
  guarantorFactorsValue: 0.0,
  takeoutValue: 0.0,
  guarantorPoints: 0.0,

  classValue: 0.0,
  stabilizedValue: 0.0,
  marketValue: 0.0,
  assetFactorsValue: 0.0,
  assetTypePoints: 0.0,

  cashFlowValue: 0.0,
  dscrValue: 0.0,
  incomePoints: 0.0,

  evidenceValue: 0.0,
  possibilitiesValue: 0.0,
  exitStrategyPoints: 0.0,

  cashContributedValue: 0.0,
  liquidContributionValue: 0.0,
  illiquidContributionValue: 0.0,
  skinInTheGamePoints: 0.0,

  currentConditionValue: 0.0,
  contemplatedConditionValue: 0.0,
  conditionPoints: 0.0,

  marketabilityValue: 0.0,
  packagingValue: 0.0,
  marketabilityPoints: 0.0,

  valueAddonsValue: 0.0,
  valueAddonsPoints: 0.0,

  staffingValue: 0.0,
  costValue: 0.0,
  timeValue: 0.0,
  managementBurdenPoints: 0.0,

  conditionsValue: 0.0,
  trendsValue: 0.0,
  activityValue: 0.0,
  totalMarketPoints: 0.0,

  returnValue: 0.0,
  immediacyValue: 0.0,
  profitabilityValue: 0.0,
  totalProfitabilityPoints: 0.0,

  marginValue: 0.0,
  syndicationsValue: 0.0,
  totalCashAllocationPoints: 0.0,

  completednessValue: 0.0,
  totalCompletednessPoints: 0.0,

  totalQUPscore: 0.0,
}

export default function qupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
