const actions = {
  SET_STATE: 'menu/SET_STATE',
  GET_DATA: 'menu/GET_DATA',
  SEARCH_ON_MENU: 'menu/SEARCH_ON_MENU',
  BROKER_LEFT_MENU: 'menu/BROKER_LEFT_MENU',
  INVESTOR_LEFT_MENU: 'menu/INVESTOR_LEFT_MENU',
  FUND_LEFT_MENU: 'menu/FUND_LEFT_MENU',
}

export default actions
