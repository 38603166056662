const actions = {
  FETCH_TEAM_MEMBERS: 'teams/FETCH_TEAM_MEMBERS',
  FETCH_TEAM_MEMBERS_SUCCESS: 'teams/FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_LEADERSHIP_MEMBERS: 'teams/FETCH_LEADERSHIP_MEMBERS',
  FETCH_LEADERSHIP_MEMBERS_SUCCESS: 'teams/FETCH_LEADERSHIP_MEMBERS_SUCCESS',
  FETCH_BOARD_MEMBERS: 'teams/FETCH_BOARD_MEMBERS',
  FETCH_BOARD_MEMBERS_SUCCESS: 'teams/FETCH_BOARD_MEMBERS_SUCCESS',
  SEARCH_TEAM_MEMBERS: 'teams/SEARCH_TEAM_MEMBERS',
  SEARCH_TEAM_MEMBERS_SUCCESS: 'teams/SEARCH_TEAM_MEMBERS_SUCCESS',
}

export default actions
