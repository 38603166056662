import * as type from './types'
import { GET_ACCOUNT_TYPES } from './types'

const actions = {
  SUBMIT_IO_INVITATION:           'investorInvitation/SUBMIT_IO_INVITATION',
  SUBMIT_IO_INVITATION_REDUCERS:  'investorInvitation/SUBMIT_IO_INVITATION_REDUCERS',
  SUBMIT_IO_INVITATION_SUCCESS:   'investorInvitation/SUBMIT_IO_INVITATION_SUCCESS',

  FETCH_INVESTOR:                 'investorInvitation/FETCH_INVESTOR',
  FETCH_INVESTOR_SUCCESS:         'investorInvitation/FETCH_INVESTOR_SUCCESS',
  SEARCH_INVESTOR:                'investorInvitation/SEARCH_INVESTOR',
}

export default actions

export function getFundTypes(fundTypes){
  return{
    type: type.GET_FUND_TYPES,
    payload: fundTypes

  }
}
export function getAccountTypes(accountTypes){
  return{
    type: type.GET_ACCOUNT_TYPES,
    payload: accountTypes

  }
}

export function sendInvitation(investoronboarding,userId,entityId, investorId){
  return{
    type: type.SUBMIT_TO_INVITATION,
    investoronboarding,
    userId,
    entityId,
    investorId
  }
}

export function searchInvestor(email){
  return{
    type: type.SEARCH_INVESTOR,
    email
  }
}
// Not used yet
// export function fetchInvestor(investor){
//   return{
//     type:type.FETCH_INVESTOR,
//     payload: investor
//   }
// }

// Not used yet
// export function fetchInvestor(investor){
//   return{
//     type:type.FETCH_INVESTOR,
//     payload: investor
//   }
// }
