const errors = [
  {
    original: 'Request has been terminated',
    match: 'You are offline. Please check your internet connection.',
  },
]

export default function ErrorLookup(error) {
  for (let i = 0; i < errors.length; i += 1) {
    if (error.startsWith(errors[i].original)) {
      return errors[i].match
    }
  }
  return error
}
