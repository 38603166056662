import actions from './actions'

const initialState = {
  userFormList: [],
  initialLoad: false,
}

export default function userFormReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_USERFORM_SUCCESS:
      return { ...state, userFormList: action.payload, initialLoad: action.initialLoad }
    default:
      return { ...state }
  }
}
