import actions from './actions'
import * as type from './types'


const initialState = {
    stats: '',
    error: null
}


export default function InvestorCrmReducer(state = initialState, action){
  // eslint-disable-next-line default-case
  switch (action.type){
    case type.GET_WIDGET_STATS:
      return{
        ...state
      }

    case type.GET_WIDGET_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats
      }

    case type.GET_WIDGET_STATS_FAILED:
      return {
        ...state,
        error: action.message
      }

    default:
      return state
  }
}
