import * as type from './types'

const actions = {
  SET_STATE: 'investorVerification/SET_STATE',
  CHANGE_INVESTOR_NAME: 'investorVerification/CHANGE_INVESTOR_NAME',
  FETCH_ACTIVE_INVITATIONS: 'investorVerification/FETCH_ACTIVE_INVITATIONS',
  FETCH_ACTIVE_RIA_INVITATIONS: 'investorVerification/FETCH_ACTIVE_RIA_INVITATIONS',
  GET_ACTIVE_INVITATIONS: 'investorVerification/GET_ACTIVE_INVITATIONS',
  UPDATE_INVESTOR_STATUS: 'investorVerification/UPDATE_INVESTOR_STATUS',
  ON_DECLINE_INVESTOR: 'investorVerification/ON_DECLINE_INVESTOR',
  ON_APPROVE_INVESTOR: 'investorVerification/ON_APPROVE_INVESTOR',
}

export default actions


export function updateInvestorStatus(statusId,checked){
  return{
    type: type.UPDATE_INVESTOR_STATUS,
    statusId,
    checked
  }
}
export function getInvestorStatus(){
  return{
    type: type.GET_ACTIVE_INVITATIONS
  }
}
export function getOnboardingTasks(entityMembershipId){
  return{
    type: type.GET_ONBOARDING_TASKS,
    taskID: entityMembershipId
  }
}
