import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import qup from './qup/sagas'
import settingsmenu from './settingsmenu/sagas'
import settings from './settings/sagas'
import userForm from './userForm/sagas'
import entity from './entity/sagas'
import userprofile from './userprofile/sagas'
import general from './general/sagas'
import investordashboard from './investordashboard/sagas'
import dropdownmenu from './dropdownmenu/saga'
import team from './team/sagas'
import news from './news/sagas'
import advisor from './advisor/sagas'
import funddashboard from './funddashboard/sagas'
import investormanagement from './investormanagement/sagas'
import investoronboarding from './investoronboarding/sagas'
import ioinvitationpreview from './ioinvitationpreview/sagas'
import ioverification from './ioverification/sagas'
import publishing from './publishing/sagas'
import publishingDesk from './publishingDesk/sagas'
import onboarding from './onboarding/sagas'
import fundentities from './fundEntities/sagas'
import investorreporting from './investorReporting/sagas'
import statements from './statements/sagas'

/* scss loan from './loan/sagas'
scss dropdown from './dropdown/sagas'
scss media from './media/sagas' */

export default function* rootSaga() {
  yield all([
    user(),
    qup(),
    fundentities(),
    menu(),
    settingsmenu(),
    settings(),
    /* loan(),
    dropdown(),
    media(), */
    entity(),
    userprofile(),
    general(),
    investordashboard(),
    dropdownmenu(),
    team(),
    news(),
    advisor(),
    userForm(),
    statements(),
    funddashboard(),
    investormanagement(),
    investoronboarding(),
    ioinvitationpreview(),
    ioverification(),
    publishing(),
    publishingDesk(),
    onboarding(),
    investorreporting(),
  ])
}
