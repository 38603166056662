import React from 'react'
import { Field } from 'redux-form'

import RadioFieldComponent from '../component/RadioFieldComponent'

const RadioField = ({ name, label, children, onChange, buttonStyle, defaultValue, validate,size }) => {
  return(
    <Field
      name={name}
      component={RadioFieldComponent}
      label={label}
      onChange={onChange}
      defaultValue={defaultValue}
      buttonStyle={buttonStyle}
      validate={validate}
      size={size}
    >
      {children}
    </Field>
    )
}

export default RadioField
