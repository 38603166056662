import { takeEvery, all, put, call } from 'redux-saga/effects'

import getMessageMenu from 'services/messagemenu'
import getFundGrowthMenu from 'services/fundgrowthmenu'
import actions from './actions'

export function* GET_MENU() {
  const messageMenu = yield call(getMessageMenu)
  const fundGrowthMenu = yield call(getFundGrowthMenu)

  yield put({
    type: actions.SET_MENU,
    payload: {
      messageMenu,
      fundGrowthMenu,
    },
  })
}

export default function* rootSaga() {
  yield all([GET_MENU(), takeEvery(actions.GET_MENU, GET_MENU)])
}




// @todo i think this is an example of when not to use sagas.