import React from 'react'
import { connect } from 'react-redux'
import item from './StandardPlanDetails.json'
import styles from './pricingCardsStyle.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Standard extends React.Component {
  static defaultProps = {
    data: item.item,
    type: '',
    extended: false,
  }

  openCCModal = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'showCCModal',
        value: true,
      },
    })
  }

  render() {
    const { data, extended, type } = this.props

    return (
      <div
        className={`${styles.itemstd}  ${extended ? styles.extended : ''} ${
          type ? styles[type] : ''
        }`}
      >
        <div className={styles.header}>
          <div className={styles.price}>
            <span className={styles.currency}>{data.currency}</span>
            <span className={styles.amount}>{data.amount}</span>
            <span className={styles.period}>{data.period}</span>
          </div>
          <div className={styles.title}>{data.title}</div>
        </div>
        <ul className={styles.features}>
          {data.features.map(feature => (
            <li key={feature.id}>
              <strong>{feature.main}</strong> {feature.description}
            </li>
          ))}
        </ul>
        <div className={styles.footer}>
          <a
            onClick={this.openCCModal}
            href="#"
            className={styles.buttonColored}
            role="button"
          >
            {data.btnText}
          </a>
        </div>
      </div>
    )
  }
}

export default Standard
