import React, { Suspense, lazy, Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import Auth0 from 'services/auth0'
import fundRoutes from 'pages/fund/routes'
import investorRoutes from 'pages/investor/routes'
import brokerRoutes from 'pages/losApp/routes'

const auth0 = new Auth0()

const publicRoutes = [
  {
    path: '/user/signup',
    Component: lazy(() => import('pages/investor/investorsignup')),
    exact: true,
    key: 1
  },
  {
    path: '/user/login',
    Component: lazy(() => import('pages/shared/user/login')),
    exact: true,
    key: 2
  },
  {
    path: '/user/forgot',
    Component: lazy(() => import('pages/shared/user/forgot')),
    exact: true,
    key: 3
  },
  {
    path: '/404',
    Component: lazy(() => import('components/SystemComponents/Construction/')),
    exact: true,
    key: 4
  },
  {
    path: '/bios',
    Component: lazy(() => import('pages/shared/team/bios')),
    exact: true,
    key: 5
  },
  {
    path: '/user/pwl',
    Component: lazy(() => import('pages/shared/user/pwl')),
    exact: true,
    key: 2
  },
]

const getRestrictedRoutes = (accountType) => {
  if (accountType.includes('fund_manager') || accountType.includes('ria_admin')) {
    return fundRoutes
  }

  if (accountType.includes('investor_accredited') || accountType.includes('ria_admin')) {
    return investorRoutes
  }

  if (accountType.includes('broker_premium')) {
    return brokerRoutes
  }

  return []
}

const RouterHelper = ({ location, history, accountType, routerAnimation, dispatch,isPwdLess }) => {
  const restrictedRoutes = getRestrictedRoutes(accountType)
  // console.log('restrictedRoute',restrictedRoutes)
  // console.log({ location, history, accountType, routerAnimation, dispatch, restrictedRoutes })

  return (
    <Switch location={location}>
      <Route exact path="/" render={() => <Redirect to="/home" />} />
      {publicRoutes.map(({ path, Component, exact, key }) => (
        <Route
          path={path}
          key={key}
          exact={exact}
          render={() => {
            return (
              <div className={routerAnimation}>
                <Suspense fallback={null}>
                  <Component />
                </Suspense>
              </div>
            )
          }}
        />
      ))}
      {restrictedRoutes.map(({ path, Component, role, exact, key }) => {
        return (
          <Route
            path={path}
            key={key}
            exact={exact}
            render={(props) => {
              if (auth0.isTokenExpired()) {
                history.push('/user/login')
                dispatch({
                  type: 'user/LOGOUT',
                })
              } else if (Auth0.userHasRole(role).hasRole && isPwdLess === false) {
                return (
                  <div className={routerAnimation}>
                    <Suspense fallback={null}>
                      <Component {...props} role={role} />
                    </Suspense>
                  </div>
                )
              }else{
                return (
                  <div className={routerAnimation}>
                    <Suspense fallback={null}>
                      <Component {...props} role={role} />
                    </Suspense>
                  </div>
                )
              }
              return <Fragment>Access Denied</Fragment>
            }}
          />
        )
      })}
      <Redirect to="/404" />
    </Switch>
  )
}

export default connect(
  (state) => ({
    accountType: state.user.accountType,
    routerAnimation: state.settings.routerAnimation,
    isPwdLess: state.user.isPwdLess
  }),
  (dispatch) => ({ dispatch }),
)(RouterHelper)
