import actions from './actions'

const initialState = {
  advisorList: [],
  initialLoad: false,
}

export default function advisorReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ADVISOR_SUCCESS:
      return { ...state, advisorList: action.payload, initialLoad: action.initialLoad }
    default:
      return { ...state }
  }
}
