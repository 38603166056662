import { lazy } from 'react'

const brokerRoutes = []

const investorRoutes = []

const fundRoutes = []

const sharedRoutes = [
  {
    path: '/library/news',
    Component: lazy(() => import('pages/shared/news')),
    role: ['investor_accredited','fund_manager'],
    exact: true,
    key: 6
  },
  {
    path: '/library/team',
    Component: lazy(() => import('pages/shared/team')),
    role: ['fund_manager'],
    exact: true,
    key: 7
  },
  {
   path: '/library/forms',
    Component: lazy(() => import('pages/shared/onlineForms')),
    role: ['investor_accredited','fund_manager'],
    exact: true,
    key: 8
  },

]

export { brokerRoutes, investorRoutes, fundRoutes, sharedRoutes }
