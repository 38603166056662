import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const { emptyValidator, nameValidator } = Validators

const LastNameField = ({ check }) => (
  <Field
    disabled={check}
    name="lastName"
    placeholder="Last Name"
    maxLength={25}
    component={inputType}
    validate={[emptyValidator, nameValidator]}
  />
)

export default LastNameField
