import React from 'react'

import styles from '../entityUserStyle.module.scss'

const EntityUserActions = ({ handleSubmit, onSubmit }) => {
  const routePath = {
    savePath: '/accreditation/lobby',
    continuePath: '/accreditation/contribution',
  }

  return (
    <div className="clearfix">
      <div className={styles.formActionWrapper}>
        <button
          type="submit"
          className="btn-curved upperCase"
          onClick={handleSubmit(formValue => {
            onSubmit(formValue, routePath.continuePath)
          })}
        >
          UPDATE
        </button>
        <button
          type="submit"
          className="btn-curved upperCase"
          onClick={handleSubmit(formValue => {
            onSubmit(formValue, routePath.continuePath)
          })}
        >
          CREATE
        </button>
      </div>
    </div>
  )
}

export default EntityUserActions
