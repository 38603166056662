import React from 'react'
import { Layout } from 'antd'

import Settings from 'layouts/SettingsMenu'
import styles from 'layouts/Main/Settings/settngsStyle.module.scss'
// import UserSetting from './UserSetting';
// working code
const { Content } = Layout

class SettingsLayout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Layout className={styles.layout}>
        <Settings />
        <Content>
          <div className="utils__content">{children}</div>
        </Content>
      </Layout>
    )
  }
}

export default SettingsLayout
