import { lazy } from 'react'

import { sharedRoutes } from '../shared/routes'

const losAppRoutes = [
  {
    path: '/los/loan-flow',
    Component: lazy(() => import('pages/losApp/losDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 9
  },
  {
    path: '/los/funded',
    Component: lazy(() => import('pages/losApp/losDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 10
  },
  {
    path: '/los/syndicated',
    Component: lazy(() => import('pages/losApp/losDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 11
  },
  {
    path: '/los/assets',
    Component: lazy(() => import('pages/losApp/losDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 12
  },
  ...sharedRoutes,
]

export default losAppRoutes

