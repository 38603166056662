import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import actions from 'redux/investoronboarding/actions'
import EntityUserComponent from '../components/EntityUserComponent'

const FORM_NAME = 'NewEntityUser'

class EntityUserContainer extends Component {
  componentDidMount() {
    const { initialize, ioInvitationFormData } = this.props

    initialize(ioInvitationFormData)
  }

  onSubmit = (formValues, route) => {
    const { dispatch } = this.props

    new Promise((resolve, reject) => {
      dispatch({
        type: actions.SAVE_IO_INVITATION_FORM_DATA,
        resolve,
        reject,
        formValues,
      })
    })
      .then(() => {
        const { history } = this.props

        history.push(route)
      })
      .catch(error => console.log({ error }))
  }

  render() {
    const { handleSubmit, ioInvitationFormData } = this.props

    return (
      <EntityUserComponent
        handleSubmit={handleSubmit}
        onSubmit={this.onSubmit}
        ioInvitationFormData={ioInvitationFormData}
      />
    )
  }
}

const NewEntityUser = reduxForm({
  form: FORM_NAME,
})(EntityUserContainer)

const connectedForm = connect(
  state => ({ ioInvitationFormData: state.investoronboarding.ioInvitationFormData }),
  dispatch => ({ dispatch }),
)(NewEntityUser)

export default withRouter(connectedForm)
