import actions from './actions'

const initialState = {
  teamMembers: [],
  leadershipMembers: [],
  boardMembers: [],
  searchedTeamMembers: [],
  initialLoad: false,
}

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TEAM_MEMBERS_SUCCESS:
      return { ...state, teamMembers: action.payload, initialLoad: action.initialLoad }
    case actions.FETCH_LEADERSHIP_MEMBERS_SUCCESS:
      return { ...state, leadershipMembers: action.payload, initialLoad: action.initialLoad }
    case actions.FETCH_BOARD_MEMBERS_SUCCESS:
      return { ...state, boardMembers: action.payload, initialLoad: action.initialLoad }
    case actions.SEARCH_TEAM_MEMBERS_SUCCESS:
      return { ...state, searchedTeamMembers: action.payload, initialLoad: action.initialLoad }
    default:
      return { ...state }
  }
}
