import React from 'react'
import { Checkbox } from 'antd'

const CheckboxField = ({ input, children, ...rest }) => {
  const { onChange } = input

  return (
    <Checkbox {...rest} onChange={onChange}>
      {children}
    </Checkbox>
  )
}

export default CheckboxField
