// import { Children } from "react"
//
// HOME FOR ALL APPS
// dont forget the routes.js file

export async function getLeftMenu() {
  return [
    {
      title: 'Home',
      key: 'home',
      url: '/home',
      icon: 'fal fa-home-lg-alt',
      role: ['broker_premium', 'investor_accredited', 'fund_manager', 'ria_admin'],
      status: 'active',
    },
    // BROKER APP MENU
    /*
    {
      title: 'Loans',
      key: 'loans',
      url: '/loans',
      icon: 'fal fa-handshake-alt',
      role: ['broker_premium'],
      status: 'active',
      nestedURL: [
        {
          key: 'create',
          title: 'Create',
          url: /^\/loans\/[0-9]*\/create/,
        },
        {
          key: 'edit',
          title: 'Edit',
          url: /^\/loans\/[0-9]*\/edit/,
        },
        {
          key: 'detail',
          title: 'Detail',
          url: /^\/loans\/[0-9]*\/detail/,
        },
        {
          key: 'customize',
          title: 'Customize',
          url: '/loans/customize',
        },
      ],
    },
    {
      title: 'Leads',
      key: 'leads',
      url: '/leads',
      icon: 'fal fa-address-book',
      role: 'broker_premium',
      status: 'active',
    },
     {
       title: 'Users',
       key: 'users',
       url: '/users',
       icon: 'fal fa-users',
       permissionRequired: 'read:users',
       nestedURL: [
         {
           key: 'create',
           title: 'Create',
           url: '/users/create',
         },
       ],
     },
     {
       title: 'Preferences',
       key: 'preferences',
       url: '/preferences/create',
       icon: 'fal fa-cog',
       permissionRequired: 'read:lender_preferences',
     },
     {
       title: 'Application Settings',
       key: 'setting',
       url: '/setting',
       icon: 'fal fa-sliders-h',
       permissionRequired: 'read:home',
     {
      title: 'Preferences',
      key: 'preferences',
      url: '/preferences/create',
      nestedURL: [
        {
          key: 'Preferences',
          title: 'Preferences',
          url: '/preferences/create',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      url: '/setting',
      nestedURL: [
        {
          key: 'setting',
          title: 'Setting',
          url: '/setting',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      url: '/setting/edit-profile',
      nestedURL: [
        {
          key: 'setting',
          title: 'Setting',
          url: '/setting',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      url: '/setting/applications',
      nestedURL: [
        {
          key: 'setting',
          title: 'Setting',
          url: '/setting',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      url: '/setting/users',
      nestedURL: [
        {
          key: 'setting',
          title: 'Setting',
          url: '/setting',
        },
      ],
    },
    {
      title: 'Edit Profile',
      key: 'profile',
      url: '/edit-profile',
      nestedURL: [
        {
          key: 'edit-profile',
          title: 'Edit Profile',
          url: '/edit-profile',
        },
      ],
    },
*/
    // Investor Management Application CRM Features
    {
      title: 'CRM',
      key: 'ima-crm',
      url: '/ima/crm/investor',
      icon: 'fal fa-handshake',
      role: ['fund_manager'],
      status: 'active',
      children: [
        {
          title: 'LP Dashboards',
          key: 'ima-crm-investor-dashboard',
          url: '/ima/crm/investor',
          // icon: 'fal fa-tasks',
          role: ['fund_manager'],
        },
        {
          title: 'Invitation',
          key: 'ima-investor-invite',
          url: '/ima/crm/invitation',
          // icon: 'fal fa-envelope',
          role: ['fund_manager'],
        },
        {
          title: 'Verification',
          key: 'ima-investor-verify',
          url: '/ima/crm/verification',
          // icon: 'fal fa-user-check',
          role: ['fund_manager'],
        },
        // {
        //   title: 'Due Diligence',
        //   key: 'ima-investor-due-diligence',
        //   url: '/ima/crm/due-diligence',
        //   icon: 'fal fa-book',
        //   role: ['fund_manager'],
        // },
        // {
        //   title: 'Coming Soon',
        //   key: 'ima-investor-coming-soon',
        //   url: '/ima/crm/coming-soon',
        //   icon: 'fal fa-books',
        //   role: ['fund_manager'],
        // },
      ],
    },

    // Investor Management Application Investor Portal
    {
      title: 'Investor Portal',
      key: 'ima',
      url: '/ima/investor-reporting',
      icon: 'fal fa-user-chart',
      role: ['fund_manager'],
      status: 'active',
      children: [
        // not sure if I am going to do this screen?
        /*
        {
          title: '*Overview',
          key: 'overview',
          url: '/ima/overview',
          icon: 'fal fa-bar-chart',
          role: ['fund_manager'],
        },
        */
        {
          title: 'Reporting',
          key: 'ima-investor-reporting',
          url: '/ima/investor-reporting',
          // icon: 'fal fa-file-chart-pie',
          role: ['fund_manager'],
        },

        {
          title: 'Advisors',
          key: 'ima-advisor',
          url: '/ima/advisors',
          // icon: 'fal fa-user-chart',
          role: ['fund_manager'],
        },
        /* this should not be in the Investor side of the application fix I removed it from the Investor App
         url should not be accreditation, should investor-onboarding */
      ],
    },
    /* ----- Fund Management Application ----- */
    {
      title: 'Fund Manager',
      key: 'fma',
      url: '/fma/fund-management-portal',
      icon: 'fal fa-university',
      role: ['fund_manager'],
      status: 'active',
      children: [
        {
          title: 'FM Dashboard',
          key: 'fma-fundmanager',
          url: '/fma/fund-management-portal',
          // icon: 'fal fa-chart-pie',
          role: ['fund_manager'],
        },
        {
          title: 'Schedules',
          key: 'fma-fundSchedule',
          url: '/fma/schedules',
          // icon: 'fal fa-radar',
          role: ['fund_manager'],
        },
        {
          title: 'Fund Ledger',
          key: 'fund-ledger',
          url: '/fma/fund-ledger',
          // icon: 'fal fa-file-invoice',
          role: ['fund_manager'],
        },
        {
          title: 'General Ledger',
          key: 'general-ledger',
          url: '/fma/general-ledger',
          // icon: 'fas fa-file-invoice',
          role: ['fund_manager'],
        },
        // {
        //   title: 'Modeling',
        //   key: 'fund-Modeling',
        //   url: '/fma/fund-modeling/coming-soon',  // coming soon widget
        //   icon: 'fal fa-chart-line',
        //   role: ['fund_manager'],
        // },
      ],
    },
    /* ----- QUP ----- */
    {
      title: 'QUP',
      key: 'fma-qup',
      url: '/fma/qup/report',
      icon: 'fal fa-alicorn',
      role: ['fund_manager'],
      status: 'active',
      children: [
        {
          title: 'QUP Report',
          key: 'fma-qup-report',
          url: '/fma/qup/report',
          // icon: 'fal fa-abacus',
          role: ['fund_manager'],
        },
        {
          title: 'QUP Dashboard',
          key: 'fma-qup-dashboard',
          url: '/fma/qup/dashboard',
          // icon: 'fal fa-project-diagram',
          role: ['fund_manager'],
        },
      ],
    },
    // Fund Admin Application
    // Eventually this will only be seen by the Fund Admin role.
    // faa Fund Admin System
    {
      title: 'Fund Admin',
      key: 'faa-dashboard',
      url: '/faa/fund-profiles',
      icon: 'fal fa-balance-scale',
      role: ['fund_manager'],
      status: 'active',
      children: [

        {
          title: 'Fund Profiles',
          key: 'faa-fund-profiles',
          url: '/faa/fund-profiles',
          // icon: 'fal fa-chart-scatter-bubble',
          role: ['fund_manager'],
        },
        {
          title: 'Ledger',
          key: 'faa-fund-ledger',
          url: '/faa/fund-ledger',
          // icon: 'fal fa-file-invoice',
          role: ['fund_manager'],
        },
        {
          title: 'Billing',
          key: 'faa-fund-admin-billing',
          url: '/faa/fund-billing',
          // icon: 'fa-light fa-chart-bullet',
          role: ['fund_manager'],
        },
        {
          title: 'Fund Settings',
          key: 'faa-fund-settings',
          url: '/faa/fund-settings',
          // icon: 'fal fa-ballot',
          role: ['fund_manager'],
        },
      ],
    },
    // LOS Loan Origination System
    // todo
    // broken urls
    // @daniel
    /*
    {
      title: 'LOS',
      key: 'loanFlow-Home',
      url: '/los/loan-flow',
      icon: 'fal fa-funnel-dollar',
      role: ['fund_manager'],
      children: [
        {
          title: 'Loan Flow',
          key: 'loanFlow',
          url: '/los/loan-flow',
          icon: 'fal fa-handshake-alt',
          role: ['fund_manager'],
        },
        {
          title: 'QUP',
          key: 'qupHome',
          url: 'los/qup',
          //          icon: 'fad fa-project-diagram',
          role: ['fund_manager'],
          children: [
            {
              title: 'Inputs',
              key: 'qupInput',
              url: '/los/qup/inputs',
              icon: 'fad fa-project-diagram',
              role: ['fund_manager'],
            },
            {
              title: 'Reports',
              key: 'qupReport',
              url: '/los/qup/reports',
              icon: 'fal fa-project-diagram',
              role: ['fund_manager'],
            },
          ],
        },
        {
          title: 'Funded',
          key: 'fundedLoans',
          url: '/los/funded',
          icon: 'fal fa-bar-chart',
          role: ['fund_manager'],
        },
        {
          title: 'Syndicated',
          key: 'syndicatedLoans',
          url: '/los/syndicated',
          icon: 'fal fa-bar-chart',
          role: ['fund_manager'],
        },
        {
          title: 'Assets',
          key: 'managedAssets',
          url: '/los/managed-assets',
          icon: 'fal fa-bar-chart',
          role: ['fund_manager'],
        },
        {
          title: 'Archived',
          key: 'archivedLoans',
          url: '/los/archived',
          icon: 'fal fa-bar-chart',
          role: ['fund_manager'],
        },
      ],
    },
    */

    /* ----- Publishing ----- */
    {
      title: 'Publishing',
      key: 'publishing',
      url: '/publishing/desk',
      icon: 'fal fa-newspaper',
      role: ['fund_manager'],
      status: 'active',
      children: [
        {
          title: 'Desk',
          key: 'publishing-desk',
          url: '/publishing/desk',
          // icon: 'fal fa-typewriter',
          role: ['fund_manager'],
        },
        {
          title: 'Designer',
          key: 'publishing-designer',
          url: '/publishing/designer',
          // icon: 'fal fa-palette',
          role: ['fund_manager'],
        },
      ],
    },
    // INVESTOR APP
    // INVESTOR MENU
    {
      title: 'Accounts',
      key: 'accounts',
      icon: 'fal fa-hand-holding-usd',
      role: 'investor_accredited',
      status: 'active',
      url: '/accounts'
    },
    // added lobby to investor
    {
      title: 'Lobby',
      key: 'investor-lobby',
      url: '/lobby/onboarding',
      icon: 'fal fa-desktop',
      role: ['investor_accredited'],
      status: ['invited', 'active'],
    },
    // THIS NEEDS TO BE FIXED SO IT IS SHARED, maybe another file, it is confusing as all get out!
    {
      title: 'Prospects',
      key: 'prospects',
      url: '/ria/prospects',
      icon: 'fal fa-handshake',
      role: ['ria_admin', 'ria_advisor'],
      status: ['active'],
    },
    {
      title: 'Investors',
      key: 'investors',
      url: '/ria/details',
      icon: 'fal fa-file-chart-pie',
      role: ['ria_admin', 'ria_advisor'],
      status: ['active'],
    },
    // Shared menu
    {
      title: 'Library',
      key: 'library',
      url: '/library/news',
      icon: 'fal fa-books',
      role: ['broker_premium', 'investor_accredited', 'fund_manager', 'fund_admin', 'ria_admin'],
      status: ['invited', 'active'],
      children: [
        {
          title: 'News',
          key: 'library-news',
          url: '/library/news',
          // icon: 'fal fa-newspaper',
          role: ['investor_accredited', 'fund_manager', 'fund_admin', 'ria_admin'],
        },
        {
          title: 'Forms',
          key: 'library-forms',
          url: '/library/forms',
          // icon: 'fab fa-wpforms',
          role: ['investor_accredited', 'fund_manager', 'fund_admin', 'ria_admin'],
        },
        {
          title: 'Team',
          key: 'library-team',
          url: '/library/team',
          // icon: 'fas fa-users',
          role: [ 'fund_manager', 'fund_admin', 'ria_admin'],
        },
        /* Escondido
        {
          title: 'Due Diligence',
          key: 'due',
          url: '/due-diligence',
          icon: 'fal fa-book',
          role: ['fund_manager'],
        },
        {
          title: 'Lender Resources',
          key: 'lender',
          url: '/lender-resources',
          icon: 'fal fa-newspaper-o',
          role: ['broker_premium'],
        },
        */

        /* bios only rendered when you are asked or required to fill it out. @todo
        {
          title: 'Bios',
          key: 'bios',
          url: '/bios',
          icon: 'fal fa-book',
          role: ['broker_premium', 'investor_accredited', 'fund_manager'],
        },
        /* need to find some faqs fix Patrick
        {
          title: 'FAQ',
          key: 'faq',
          url: '/faq',
          icon: 'fal fa-question-circle',
          role: ['broker_premium', 'investor_accredited', 'fund_manager'],
        },
        */
        // not sure what we want to do here?
        /*
        {
          title: 'Chat PublishingHistory',
          key: 'chatHistory',
          url: '/chat-history',
          icon: 'fal fa-comment',
          role: ['broker_premium', 'investor_accredited', 'fund_manager'],
        },
        */
        // need to come back and fix this to say the name of the Entity instead of Prospera @todo
      ]
    }
  ]
}

export async function getTopMenuData() {
  return [
    {
      title: 'Home',
      key: 'home',
      url: '/home',
      icon: 'fal fa-home-lg-alt',
      role: ['investor_accredited'],

      // children: [
      //   {
      //     title: 'Dashboard Alpha',
      //     key: 'dashboardAlpha',
      //     url: '/dashboard/alpha',
      //   },
      // ],
    },
  ]
}
