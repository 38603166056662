import { all, call, takeEvery } from 'redux-saga/effects'

import actions from './actions'

export function* FETCH_USERFORM({ resolve, reject }) {
  try {
    const  UserForm = [
      {
        id: 1,
        userFormTitle: 'W2',
        userFormImage: '/resources/images/documents/doc.png',
        publishedDate: '05/06/2021',
      },
      {
        id: 2,
        userFormImage: '/resources/images/documents/doc2.png',
        userFormTitle: 'Distribution',
        publishedDate: '05/06/2021',
      },
      {
        id: 3,
        userFormImage: '/resources/images/documents/doc3.jpeg',
        userFormTitle: 'Advisor Switch',
        publishedDate: '05/06/2021',
      },
      {
        id: 4,
        userFormImage: '/resources/images/documents/doc3.jpeg',
        userFormTitle: 'Account Suspend',
        publishedDate: '05/06/2021',
      },
    ]

    yield call(resolve, UserForm)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_USERFORM, FETCH_USERFORM)])
}
