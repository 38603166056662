export default function getFundGrowthMenu() {
  return [
    {
      title: 'Fund Growth menu 1',
      key: 'fund-growth-menu-1',
      permissionRequired: 'read:investor_dashboard',
    },
    {
      title: 'Fund Growth menu 2',
      key: 'fund-growth-menu-2',
      permissionRequired: 'read:investor_dashboard',
    },
    {
      title: 'Fund Growth menu 3',
      key: 'fund-growth-menu-3',
      permissionRequired: 'read:investor_dashboard',
    },
    {
      title: 'Fund Growth menu 4',
      key: 'fund-growth-menu-4',
      permissionRequired: 'read:investor_dashboard',
    },
  ]
}
