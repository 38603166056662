import React from 'react'
import { Field } from 'redux-form'
import Formatters from 'utils/Formatters'

import InputFieldComponent from '../component/InputFieldComponent'

const { formatPhone } = Formatters

const PhoneNumberField = ({ placeholder,check }) => {
  return <Field name="phoneNumber" format={value => formatPhone(value, "-")} placeholder={placeholder} component={InputFieldComponent} disabled={check} />
}

export default PhoneNumberField
