export default async function getSettingsMenu() {
  return [
    {
      title: 'Personal Info',
      key: 'PersonalInfo',
      className: 'fa fa-user',
      select: 'PersonalInfo',
      // user
    },
    {
      title: 'Add User',
      key: 'AddUser',
      className: 'fa fa-user-plus',
      select: 'AddUser',
      // create user
    },
    {
      title: 'User Management',
      key: 'UserManagement',
      className: 'fa fa-users',
      select: 'UserManagement',
      // manager users
    },
    {
      title: 'Appearance',
      key: 'AppSettings',
      className: 'fal fa-cog',
      select: 'AppSettings',
      // app appearance
    },
    {
      title: 'Payments & Services',
      key: 'payments-services',
      className: 'fal fa-credit-card',
      select: 'Payments & Services',
      // other services for brokers mostly
    },
    {
      title: 'Security',
      key: 'security',
      className: 'fa fa-lock',
      select: 'Security',
      // history of changes to account and ip traffic items
    },
  ]
}
