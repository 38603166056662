import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const { emptyValidator, emailValidator } = Validators

const EmailField = ({ name, placeholder, required, emailExistenceValidator,check }) => (
  <Field
    disabled={check}
    name={name}
    placeholder={placeholder}
    maxLength={50}
    component={inputType}
    // onBlur={e => emailExistenceValidator(e.target.value)}
    validate={required && [emptyValidator, emailValidator]}
  />
)

export default EmailField
