import { takeEvery, all, call, put } from 'redux-saga/effects'
import Api from 'services/api/Api'

import actions from './actions'
import * as type from './types'

const api = new Api()

export function* FETCH_STATEMENTS_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/investor_statements/11')
    response.data[0].results.shift()
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_INVESTOR_FUNNEL_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/CRMInvestorFunnel')
    yield call (resolve, response.data.data)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_OPEN_DEALS_DATA({ resolve, reject, entityId }) {
  try {
    const response = yield call(api.get, `/entities/${1}/crm-widget/open-deal-count`)
    yield call(resolve, response)
    // const firstEntity = response.data.owningEntityFunds[0] ? response.data.owningEntityFunds[0] : undefined
    // if(firstEntity){
    //   const temp = firstEntity ? firstEntity.substring(4) : firstEntity
    //   const tableData = firstEntity ? yield call(api.get, temp) : firstEntity
    //   yield call(resolve, response)
    // }
    // else{
    //   yield call(resolve, [])
    // }

  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_MONTHLY_CAPITAL_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, `/entities/${1}/crm-widget/monthly-capital`)
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_DEALS_BY_STAGES_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, `/entities/${1}/crm-widget/deal-count-by-stage`)
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_LEADS_BY_SOURCE_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, `/entities/${1}/crm-widget/deal-count-by-source`)
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_ANNUAL_CAPITAL_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, `entities/${1}/crm-widget/annual-capital`)
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_INVESTOR_MANAGEMENT_TABLE_DATA({ resolve, reject, entityFundId }) {
  try {
    const response = yield call(api.get, `/entity/invite-list/${1}`)
    const entities = response.data

    yield call(resolve, entities)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* getCrmWidgetStats(entityFundId ){
    try {
       const crmStats = yield call(api.get, `/entities/${1}/crm-widget/open-deal-count`)
       yield put({type: type.GET_WIDGET_STATS_SUCCESS, stats:crmStats.data})
    }catch (e){
      yield put({type: type.GET_WIDGET_STATS_FAILED,e})
    }


}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVESTOR_FUNNEL_DATA, FETCH_INVESTOR_FUNNEL_DATA),
    takeEvery(actions.FETCH_OPEN_DEALS_DATA, FETCH_OPEN_DEALS_DATA),
    takeEvery(actions.FETCH_STATEMENTS_DATA, FETCH_STATEMENTS_DATA),
    takeEvery(actions.FETCH_MONTHLY_CAPITAL_DATA, FETCH_MONTHLY_CAPITAL_DATA),
    takeEvery(actions.FETCH_DEALS_BY_STAGES_DATA, FETCH_DEALS_BY_STAGES_DATA),
    takeEvery(actions.FETCH_LEADS_BY_SOURCE_DATA, FETCH_LEADS_BY_SOURCE_DATA),
    takeEvery(actions.FETCH_ANNUAL_CAPITAL_DATA, FETCH_ANNUAL_CAPITAL_DATA),
    takeEvery(actions.FETCH_INVESTOR_MANAGEMENT_TABLE_DATA, FETCH_INVESTOR_MANAGEMENT_TABLE_DATA),
    takeEvery(type.GET_WIDGET_STATS, getCrmWidgetStats),
  ])
}
