const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; path=/`
}

const getCookies = () => {
  const cookies = {}
  const all = document.cookie
  if (all === '') {
    return all
  }
  const normalizedCookies = all.split('; ')
  for (let i = 0; i < normalizedCookies.length; i += 1) {
    const cookie = normalizedCookies[i]
    const index = cookie.indexOf('=')
    const name = cookie.substring(0, index)
    const value = cookie.substring(index + 1)
    cookies[name] = value
  }
  return cookies
}

const removeCookies = name => {
  setCookie(name, '')
}

export { setCookie, getCookies, removeCookies }
