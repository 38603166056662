import React from 'react'
import { Field } from 'redux-form'

import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const CountryField = ({placeholder}) => (
  <Field name="country" disabled placeholder={placeholder} component={inputType} />
)

export default CountryField
