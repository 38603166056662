import React, { Component, Fragment } from 'react'
import { Form, Radio, Select,  } from 'antd'
import { Field } from 'redux-form'

import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import InputFieldComponent from 'components/AppComponents/FormSection/component/InputFieldComponent'
import SelectField from 'components/AppComponents/FormSection/formFields/SelectField'
import CheckboxFieldComponent from '../../../../components/AppComponents/FormSection/component/CheckboxFieldComponent'
import PhysicalAddress from '../../../../components/AppComponents/FormSection/formParts/PhysicalAddress'
import styles from '../../../investor/onboarding/onboardingStyle.module.scss'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}


class FinancialSettings extends Component {
  state = {
    contribution: null,
    periodic: null,
    receivePaymentType: '',
    isTaxAdvisor: '',
  }

  handleBlur = (event) => {
    event.preventDefault()
  }

  render() {
    const { contribution, periodic, receivePaymentType, isTaxAdvisor } = this.state

    return (
      <Fragment>
       <Form layout="horizontal" {...formItemLayout} colon={false}>
        <h3 className=" h3Border">Financial Information</h3>
          <Form.Item
            label="Contribution Transfer Via"
            onChange={(e) => {
              this.setState({ contribution: e.target.value })
            }}
          >
            <RadioField name="contributionTransfer">
              <Radio value="ACH">ACH</Radio>
              <Radio value="Wire">Wire</Radio>
              <Radio value="Check">Check</Radio>
            </RadioField>
          </Form.Item>
          {(contribution === 'ACH' || contribution === 'Wire') && (
            <Form.Item
              label="Financial Institution"
            >
              <div style={{ width: '286px' }}>
                <Field
                  name="financialInstitution"
                  component={InputFieldComponent}
                  placeholder="Financial Institution"
                />
              </div>
            </Form.Item>
          )}
          <Form.Item
            label="Periodic Contributions?"
            onChange={(e) => {
              this.setState({ periodic: e.target.value })
            }}
          >
            <RadioField name="periodicContributions">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioField>
          </Form.Item>
          {periodic === 'yes' && (
            <Form.Item
              label="Frequency?"
            >
              <div style={{ width: '286px' }}>
                <SelectField
                  name="contributionFrequency"
                  placeholder="Frequency"
                  handleBlur={this.handleBlur}
                >
                  <Option value="Monthly">Monthly</Option>
                  <Option value="Quarterly">Quarterly</Option>
                  <Option value="Yearly">Yearly</Option>
                </SelectField>
              </div>
            </Form.Item>
          )}
        <Form.Item label="How will you receive payments?">
          <RadioField name="receivePayments" onChange={(e) => this.setState({receivePaymentType: e.target.value})}>
            <Radio value="check">Check</Radio>
            <Radio value="wire">Wire</Radio>
            <Radio value="ach">ACH</Radio>
          </RadioField>
        </Form.Item>
        {receivePaymentType === 'check' && (
          <Fragment>
            <Form.Item label=" " labelCol={{span: 12}}>
              <Field
                name="sameAddress"
                component={CheckboxFieldComponent}
              >
                Same as previous address
              </Field>
            </Form.Item>
            <Form.Item label="Physical Address">
              <PhysicalAddress />
            </Form.Item>
          </Fragment>
        )}
        {(receivePaymentType === 'wire' || receivePaymentType === 'ach') && (
          <Form.Item label=" ">
            NOTE: You will be asked to provide this information at time of your distribution.
          </Form.Item>
        )}

        <Form.Item label="Electronic Monthly Statements ">
          <RadioField name="monthlyStatement">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </RadioField>
        </Form.Item>
        <Form.Item label="Fund Management Notifications">
          <RadioField name="managementNotification">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </RadioField>
        </Form.Item>
        <Form.Item label="Tax Advisor Statements">
          <RadioField name="taxAdvisorStatements">
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </RadioField>
        </Form.Item>
        <Form.Item label="Tax Advisor Authorization">
          <div className={styles.taxAdvisorWrapper}>
            <RadioField name="taxAdvisorCommunication" onChange={(e) => this.setState({isTaxAdvisor: e.target.value})}>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </RadioField>
          </div>
        </Form.Item>
        {isTaxAdvisor === 'yes' && (
          <Form.Item label="Tax Advisor Email">
            <Field name="taxAdvisorEmail" component={InputFieldComponent} placeholder="Email" />
          </Form.Item>
        )}
       </Form>
      </Fragment>
    )
  }
}

export default FinancialSettings

