const endpoints = {
  user: (id = '') => 'users/${id}',
  entity: (id = '') => 'entities/${id}',
  entityContactEmail: (id = '') => 'entity_contact_emails/${id}',
  entityContactAddress: (id = '') => 'entity_contact_addresses/${id}',
  entityContactPhone: (id = '') => 'entity_contact_phones/${id}',
  entityPerson: (id = '') => 'people/${id}',
  contactAddress: (id = '') => 'contact_addresses/${id}',
}

export default endpoints
