import React from 'react'
import { Field } from 'redux-form'

import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'

const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent


const MiddleInitialField = ({check}) => (
  <Field name="middleInitial" placeholder="MI" maxLength={15} component={inputType} disabled={check} />
)

export default MiddleInitialField
