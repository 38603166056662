import React, { Component } from 'react'
import { Form, Radio } from 'antd'
import { connect } from 'react-redux'
import { change, FormSection } from 'redux-form'

import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import EmailField from 'components/AppComponents/FormSection/formFields/EmailField'
import PhoneNumberField from 'components/AppComponents/FormSection/formFields/PhoneNumberField'
import StreetOneField from 'components/AppComponents/FormSection/formFields/StreetOneField'
import StreetTwoField from 'components/AppComponents/FormSection/formFields/StreetTwoField'
import CityField from 'components/AppComponents/FormSection/formFields/CityField'
import StateField from 'components/AppComponents/FormSection/formFields/StateField'
import CountryField from 'components/AppComponents/FormSection/formFields/CountryField'
import ZipField from 'components/AppComponents/FormSection/formFields/ZipField'
import actions from 'redux/user/actions'


import FirstNameField from '../formFields/FirstNameField'
import MiddleInitialField from '../formFields/MiddleInitialField'
import LastNameField from '../formFields/LastNameField'

import styles from './styles/style.module.scss'

/* ------- invitation contact information form ----- */

class ContactInformation extends Component {

  zipCodeLocation = (zip) => {
    const { dispatch, updateField, formName } = this.props

    new Promise((resolve, reject) => {
      dispatch({
        type: actions.ZIP_CODE_SEARCH,
        zipCode: zip,
        resolve,
        reject,
      })
    })
      .then((response) => {
        dispatch(updateField(formName, "contactInformation.state", response.state))
        dispatch(updateField(formName, "contactInformation.city", response.city))
      })
      .catch((error) => console.log({ error }))
  }




  render() {

    const formItemLayout = {
      labelCol: {
         span: 6
      },
      wrapperCol: {
        span: 19
      }
    };
// this should be functioning. not sure when it was taken out @todo
    const { checkEmailExistence, emailExists, requiredEmail, isExistingInvestor } = this.props
   // console.log(this.props)
    const isDisable = isExistingInvestor === 'yes' ? 'disabled':''
    const isRequired = this.props.isOnboarding
    const title = 'Contact Information'
    return (
      <Form className={styles.formLayout} layout="horizontal" {...formItemLayout} colon={false} {...formItemLayout}>
        <div style={{marginTop: "2rem"}}>
          <h3 className="formHeadingBorder">{title}</h3>
          <FormSection style={{paddingTop: "3%"}} name="contactInformation">
            <Form.Item label={<p> First Name</p>} required>
              <FirstNameField name="firstName" requiredMark check={isDisable} />
            </Form.Item>
            <Form.Item label={<p> Middle Name</p>}>
              <MiddleInitialField check={isDisable} />
            </Form.Item>
            <Form.Item label={<p> Last Name</p>} required>
              <LastNameField name="lastName" check={isDisable} />
            </Form.Item>
            <Form.Item required={requiredEmail} label={<p>Email Address</p>} validateStatus={emailExists ? "error" : undefined}>
              <EmailField name="email" placeholder="Email Address" required={requiredEmail} check={isDisable} />
            </Form.Item>
            <Form.Item label={<p> Phone Number</p>}>
              <div className={styles.phoneNumberField}>
                <PhoneNumberField name="phoneNumber" placeholder="Phone Number" check={isDisable} />
              </div>
              <div className={styles.phoneRadio}>
                <RadioField buttonStyle="solid" name="isMobile" label="Mobile">
                  <Radio.Button value="yes">Yes</Radio.Button>
                  <Radio.Button value="no">No</Radio.Button>
                </RadioField>
              </div>
            </Form.Item>
            <Form.Item label={<p> Physical Address</p>}>
              <StreetOneField placeholder="Address Line 1" check={isDisable} isStreetRequired={isRequired} />
            </Form.Item>
            <Form.Item style={{justifyContent: "right", paddingLeft: "3rem"}} colon={false} label="">
              <StreetTwoField placeholder="Address Line 2" check={isDisable} />
            </Form.Item>
            <Form.Item label={<p> Postal Code</p>}>
              <div className={styles.postalCodeField}>
                <ZipField placeholder="Postal Code" onBlur={e => this.zipCodeLocation(e.target.value)} check={isDisable} />
              </div>
              <div className={styles.stateField}>
                <StateField label='State' placeholder="State Abbreviation" check={isDisable} />
              </div>
            </Form.Item>
            <Form.Item label={<p> City</p>}>
              <CityField isCityRequired={isRequired} placeholder="City" check={isDisable} />
            </Form.Item>
            <Form.Item label={<p> Country</p>}>
              <CountryField placeholder="Country" />
            </Form.Item>
          </FormSection>
        </div>
      </Form>
    )
  }
}

export default connect(null, (dispatch) => {
  return{
    dispatch,
    updateField: (formName, field, data) => dispatch(change( formName, field, data ))
  }
},
  )(ContactInformation)

