import actions from './actions'

const initialState = {
  loading: false,
  accounts: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.SET_MENU_DATA:
      return { ...state, ...action.payload }
    case actions.SET_LOADING_STATE:
      return { ...state, ...action.payload }
    default:
      return { ...state }
  }
}
