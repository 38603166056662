import actions from './actions'

const initialState = {
  selectedTemplate: { },
  investorName: 'Investor_Name',
}

export default function ioInvitationPreviewReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ON_TEMPLATE_SELECT_SUCCESS:
      return { ...state, selectedTemplate: action.payload }
    default:
      return { ...state }
  }
}
