import React, { Component } from 'react'
import { Form } from 'antd'
import { connect } from 'react-redux'
import ClassNames from 'classnames'
import EntityUserActions from './EntityUserActions'
import EntityTypeInfo from './EntityTypeInfo'
import styles from '../../../../investor/onboarding/onboardingStyle.module.scss'
import EntityUserPermissions from './EntityUserPermission'
import NameContainer from '../../../../../components/AppComponents/FormSection/formParts/NameContainer'
import ContactInformation from '../../../../../components/AppComponents/FormSection/formParts/ContactInformation'

@connect(({ user }) => ({ user }))
class EntityUserComponent extends Component {
  render() {
    const { handleSubmit, onSubmit, user } = this.props
    const { fundName } = user

    return (
      <div className={ClassNames(styles.defaultCard)}>
        <p>Please fill out the following form to create new user for {fundName}.</p>
        <Form layout="vertical" colon={false}>
          <EntityTypeInfo />

          <NameContainer />

          <ContactInformation />

          <EntityUserPermissions />

          <EntityUserActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
        </Form>
      </div>
    )
  }
}

export default EntityUserComponent
