import { all, put, call, takeEvery } from 'redux-saga/effects'
import getSettingsMenu from 'services/ProfileSettingsMenu'
import actions from './actions'

export function* GET_DATA() {
  const settingsMenuData = yield call(getSettingsMenu)
  yield put({
    type: 'settingsmenu/SET_STATE',
    payload: {
      settingsMenuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
    takeEvery(actions.GET_DATA, GET_DATA),
  ])
}
