import { lazy } from 'react'

import { sharedRoutes } from '../shared/routes'

const fundRoutes = [
  {
    path: '/home',
    Component: lazy(() => import('components/S2NComponents/HomeDashboard')),
    role: ['fund_manager', 'ria_admin'],
    exact: true,
    key: 1
  },
  // {
  //   path: '/lobby/onboarding',
  //   Component: lazy(() => import('pages/investor/onboarding/lobby')),
  //   role: 'investor_accredited',
  //   exact: true,
  //   key: 17
  // },
  // {
  //   path: '/onboarding/investment-detail',
  //   Component: lazy(() => import('pages/investor/onboarding/investmentDetails')),
  //   role: 'investor_accredited',
  //   key: 18
  // },
  // Investor Management Application CRM Portal
  {
    path: '/ima/crm/investor/',
    Component: lazy(() => import('pages/fund/investorCRM')),
    role: 'fund_manager',
    exact: true,
    key: 27
  },
  {
    path: '/ima/crm/invitation',
    Component: lazy(() => import('pages/fund/investorInvitation')),
    role: 'fund_manager',
    exact: true,
    key: 29
  },
  {
    path: '/ima/crm/verification',
    Component: lazy(() => import('pages/fund/investorVerification')),
    role: 'fund_manager',
    exact: true,
    key: 31
  },
  {
    path: '/ima/crm/invitation-preview',
    Component: lazy(() => import('pages/fund/ioinvitationpreview')),
    role: 'fund_manager',
    exact: true,
    key: 33
  },
  {
    path: '/ima/crm/due-diligence',
    Component: lazy(() => import('components/SystemComponents/Construction/')),
    role: 'fund_manager',
    exact: true,
    key: 35
  },
  {
    path: '/ima/crm/coming-soon',
    Component: lazy(() => import('components/S2NComponents/ComingSoon')),
    role: 'fund_manager',
    exact: true,
    key: 37
  },

// Investor Management Application Investor Portal

  {
    path: '/ima/investor-reporting',
    Component: lazy(() => import('pages/fund/investorReporting')),
    role: 'fund_manager',
    exact: true,
    key: 43
  },
  {
    path: '/ima/advisors',
    Component: lazy(() => import('pages/fund/advisor')),
    role: 'fund_manager',
    exact: true,
    key: 45
  },

  // Fund Management Application

  {
    path: '/fma/fund-management-portal',
    Component: lazy(() => import('pages/fund/fundManagerDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 51
  },
  {
    path: '/fma/schedules',
    Component: lazy(() => import('pages/fund/fundSettings')),
    role: 'fund_manager',
    exact: true,
    key: 53
  },
  {
    path: '/fma/qup/report',
    Component: lazy(() => import('pages/fund/QUP/Input')),
    role: 'fund_manager',
    exact: true,
    key: 55
  },
  {
    path: '/fma/qup/dashboard',
    Component: lazy(() => import('pages/fund/QUP/Report')),
    role: 'fund_manager',
    exact: true,
    key: 57
  },
  {
    path: '/fma/fund-ledger',
    Component: lazy(() => import('pages/fund/ledgers/fundLedger')),
    role: ['fund_manager'],
    exact: true,
    key: 59
  },
  {
    path: '/fma/general-ledger',
    Component: lazy(() => import('pages/fund/ledgers/fundLedger')),
    role: ['fund_manager'],
    exact: true,
    key: 61
  },
  {
    path: '/fma/fund-modeling/coming-soon',
    Component: lazy(() => import('components/S2NComponents/ComingSoon')),
    role: 'fund_manager',
    exact: true,
    key: 63
  },
  // use to test INVITEE WITHOUT HAVING TO LOG IN AS ONE.
  // {
  //   path: '/lobby/onboarding',
  //   Component: lazy(() => import('pages/investor/onboarding/lobby')),
  //   role: 'fund_manager',
  //   exact: true,
  //   key: 17
  // },
  // Fund Admin Application FAA
  // dashboard - fund profiles
  {
    path: '/faa/fund-profiles',
    Component: lazy(() => import('pages/fund/fundAdminDashboard')),
    role: 'fund_manager',
    exact: true,
    key: 71
  },
  // Schedules
  // Ledger
  {
    path: '/faa/fund-ledger',
    Component: lazy(() => import('pages/fund/faa_ledgers/fundLedger')),
    role: 'fund_manager',
    exact: true,
    key: 77
  },
  // construction
  {
    path: '/faa/fund-billing',
    Component: lazy(() => import('components/S2NComponents/ComingSoon/')),
    role: 'fund_manager',
    exact: true,
    key: 79
  },
  {
    path: '/faa/fund-settings',
    Component: lazy(() => import('pages/fund/fundSettings')),
    role: 'fund_manager',
    exact: true,
    key: 81
  },
  // TOOLS for FUND MANAGER and FUND ADMIN application
  {
    path: '/publishing/desk',
    Component: lazy(() => import('pages/fund/publishingDesk')),
    role: 'fund_manager',
    exact: true,
    key: 101
  },
  {
    path: '/publishing/designer',
    Component: lazy(() => import('pages/fund/publishingDesigner')),
    role: 'fund_manager',
    exact: true,
    key: 103
  },
  {
    path: '/ria/details',
    Component: lazy(() => import('pages/RIA')),
    role: 'ria_admin',
    exact: true,
    key: 104
  },
  {
    path: '/ria/prospects',
    Component: lazy(() => import('pages/RIA/prospects')),
    role: 'ria_admin',
    exact: true,
    key: 107
  },

/* ---------------- Testing ROUTES for DEVELOPMENT ONLY  ----------------*/

  // {
  //   path: '/lobby/onboarding',
  //   Component: lazy(() => import('pages/investor/onboarding/lobby')),
  //   role: 'investor_accredited',
  //   exact: true,
  //   key: 17
  // },
  // {
  //   path: '/onboarding/investment-detail',
  //   Component: lazy(() => import('pages/investor/onboarding/investmentDetails')),
  //   role: 'investor_accredited',
  //   key: 18
  // },

  /* ---------------- Testing ROUTES for DEVELOPMENT ONLY ---------------- */

  ...sharedRoutes,
]

export default fundRoutes
