// import entityEndpoints from './entity/endpoints'
// import loanEndpoints from './loan/endpoints'
// import mediaEndpoints from './media/endpoints'
// import teamEndpoints from './team/endpoints'
import userEndpoints from './user/endpoints'
import entityEndpoints from './entity/endpoints'
// import userprofileEndpoints from './userprofile/endpoints'
import endpoints from './investoronboarding/endpoints'
import ioInvitationPreviewEndPoints from './ioinvitationpreview/endpoints'
import onBoardingEndPoints from './onboarding/endpoints'

const endPoints = {
   ...entityEndpoints,
  // ...loanEndpoints,
  // ...mediaEndpoints,
  // ...teamEndpoints,
  ...userEndpoints,
  // ...userprofileEndpoints,
  ...endpoints,
  ...ioInvitationPreviewEndPoints,
  ...onBoardingEndPoints,
}

const getEndPoints = (endPoint, id) => {
  if (Array.isArray(endPoint)) {
    return endPoints[endPoint[0]](endPoint[1], id)
  }

  if (!id) {
    const path = endPoints[endPoint](id)
    return path.replace(/\/$/, '')
  }

  return endPoints[endPoint](id)
}

export default getEndPoints
