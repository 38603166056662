import React from 'react'
import { connect } from 'react-redux'
import styles from 'layouts/TopBar/TopBarStyle.module.scss'
import FeatureFlag from 'components/DevComponents/FeatureFlag'
import AppSwitcher from 'components/DevComponents/AppSwitcher'
import ProfileMenu from 'layouts/ProfileMenu'


@connect( (dispatch) => ({ dispatch }))
class TopBar extends React.Component {

  render() {
    return (
      <div className={styles.topNavBar}>
        <React.Fragment>
          <FeatureFlag feature="appSwitcher">
            <AppSwitcher />
          </FeatureFlag>
          <ProfileMenu className={styles.profileMenuNav} />
        </React.Fragment>
      </div>
    )
  }
}

export default TopBar


// className={styles.appSwitcherNav}