import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Menu, Row, Col } from 'antd'
import ClassNames from 'classnames'

import PersonalInfo from 'pages/shared/ProfileComponents/Personal/PersonalInfo.js'
import UserManagement from 'pages/shared/ProfileComponents/InternalUserManagement/containers/EditUserContainer'
import AddUser from 'pages/shared/ProfileComponents/AddUser'
import AppSettings from 'pages/shared/ProfileComponents/ApplicationSettings'
import Tables from 'pages/shared/ProfileComponents/PricingCards'
import UserAvatar from 'layouts/Settings/UserAvatar'

import styles from 'layouts/Settings/SettingsStyle.module.scss'

// this is still working
// some of it is

const mapStateToProps = ({ settings, settingsmenu }) => ({
  settings,
  settingsmenu,
})

@connect(mapStateToProps)
@connect(({ user }) => ({ user }))
class Settings extends Component {
  closeSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: false,
      },
    })
  }

  changeSetting = input => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuSettingsValue',
        value: input,
      },
    })
  }

  render() {
    const {
      user,
      settings: { isSettingsOpen, menuSettingsValue, isRoundedCorners },
      settingsmenu: { settingsMenuData },
    } = this.props

    const {
       name
    } = user

    return (
      <div
        className={isSettingsOpen ? `${styles.settingsWrapper} closeSettings` : styles.hidden}
        role="presentation"
        onClick={event => {
          if (event.target.nodeName === 'DIV') {
            if (event.target.className.includes('closeSettings')) {
              this.closeSettings()
            }
          }
        }}
      >
        <div className={isSettingsOpen ? styles.settingsOpened : ''}>
          <div className={styles.container}>
            <div className={styles.exit}>
              <button
                className={`${styles.close} fa fa-times fa-2x`}
                onClick={this.closeSettings}
                type="button"
              />
            </div>
            <Row gutter={16}>
              <Col span={6} className={styles.sideMenu}>
                <Menu
                  className={ClassNames(
                    { defaultCard: isRoundedCorners },
                    styles.navigation
                  )}
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  defaultOpenKeys={['sub1']}
                >
                  {settingsMenuData.map(menu => (
                    <Menu.Item
                      key={menu.key}
                      className={
                        menuSettingsValue === menu.select
                          ? 'ant-menu-item ant-menu-item-selected' : ''}
                      onClick={() => {
                        this.changeSetting(menu.select)
                      }}
                    >
                      <span className={styles.tabIcon}>
                        <i className={menu.className} />
                        <span>{menu.title}</span>
                      </span>
                    </Menu.Item>
                  ))}
                </Menu>
              </Col>
              <Col span={18}>
                <Row className={styles.topRow}>
                  <Col span={24}>
                    <UserAvatar />
                    <div className={ClassNames(styles.userInfo, styles.bottomSpace, styles.defaultCard)}>
                      <bdo>
                        {`${name}`}
                      </bdo>
                      <br />
                      You can update some of your account settings and manage any additional applications that you might use.
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className={ClassNames(styles.centeredCard, styles.bottomSpace)}>
                      {menuSettingsValue === 'PersonalInfo' ? <PersonalInfo /> : null}
                      {menuSettingsValue === 'AddUser' ? <AddUser /> : null}
                      {menuSettingsValue === 'UserManagement' ? <UserManagement /> : null}
                      {menuSettingsValue === 'AppSettings' ? <AppSettings /> : null}
                      {menuSettingsValue === 'Payments & Services' ? <Tables /> : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}

export default Settings
