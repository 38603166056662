import actions from './actions'

const initialState = {
  editorValue: '',
}

export default function publishingDeskReducer(state = initialState, action) {
  switch (action.type) {
    case actions.STORE_ARTICLE_SUCCESS:
      return { ...state, ...action.payload }
    default:
      return { ...state }
  }
}
