     import { takeEvery, call, all } from 'redux-saga/effects'
import Api from 'services/api/Api'
import actions from './actions'

const api = new Api()

export function* FETCH_ENTITY_FUND_DATA({resolve, reject, entityFundId}) {
  try {
    const response = yield call(api.get, `/fund/aum-overview/${entityFundId}`)
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_PDF_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/PDF')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_PGF_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/PGF')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_LP_DIST_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/LPDistData')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_LP_ALL_FUND_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/LPAllFundData')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_TOTAL_AUM_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/totalAUM')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_INVESTOR_LOCATION_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/investorLocationData')
    yield call (resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_AUM_CHART_DATA({resolve, reject}) {
  try {
    const response = yield call(api.get, '/graphs/combined')
    const { columnChartData } = response.data
    yield call (resolve, columnChartData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_PDF_CHART_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/PDF')
    const { columnChartData } = response.data
    yield call (resolve, columnChartData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_PGF_CHART_DATA({ resolve, reject }) {
  try {
    const response = yield call(api.get, '/graphs/PGF')
    const { columnChartData } = response.data
    yield call (resolve, columnChartData)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ENTITY_FUND_DATA, FETCH_ENTITY_FUND_DATA),
    takeEvery(actions.FETCH_PDF_DATA, FETCH_PDF_DATA),
    takeEvery(actions.FETCH_PGF_DATA, FETCH_PGF_DATA),
    takeEvery(actions.FETCH_LP_DIST_DATA, FETCH_LP_DIST_DATA),
    takeEvery(actions.FETCH_LP_ALL_FUND_DATA, FETCH_LP_ALL_FUND_DATA),
    takeEvery(actions.FETCH_TOTAL_AUM_DATA, FETCH_TOTAL_AUM_DATA),
    takeEvery(actions.FETCH_INVESTOR_LOCATION_DATA, FETCH_INVESTOR_LOCATION_DATA),
    takeEvery(actions.FETCH_AUM_CHART_DATA, FETCH_AUM_CHART_DATA),
    takeEvery(actions.FETCH_PDF_CHART_DATA, FETCH_PDF_CHART_DATA),
    takeEvery(actions.FETCH_PGF_CHART_DATA, FETCH_PGF_CHART_DATA),
  ])
}
