import actions from './actions'


// until we need it in a database.
const initialState = {
  countdownEndDate: 'Oct 31, 2022 12:59:59'
}

export default function countdownTimerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SUBMIT_IO_INVITATION_SUCCESS:
      return { ...state, ...action.payload }
    case actions.FETCH_INVESTOR_SUCCESS:
      return {
        ...state,
        communicationsId: action.payload.communicationsId,
        userSoftwareAccountsId: action.payload.userSoftwareAccountsId,
        userSoftwareAccountMembershipsId: action.payload.userSoftwareAccountMembershipsId,
      }
    default:
      return { ...state }
  }
}
