import React from 'react'

import styles from './FooterStyle.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.copyright}>
      <p>&copy; 2019 S2N. All rights reserved.</p>
    </div>
  </div>
)

export default Footer
