export const LoanEntityTypes = {
  BORROWER: 'borrower',
  GUARANTOR: 'guarantor',
  CONTACT: 'contact',
  HAVINGDETAILINFO: ['borrower', 'guarantor'],
  HAVINGSUMMARYINFO: ['contact'],
}

export const EntityTypes = {
  INDIVIDUAL: 'individual',
  CORPORATION: 'corporation',
  LLC: 'llc',
  BUSINESS: ['corporate', 'llc'],
  PERSON: ['individual'],
}
