import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import Api from '../../services/api/Api'
import Formatters from '../../utils/Formatters'

const api = new Api()
const { numbersOnly } = Formatters


export function* CHANGE_POINT_VALUE({ payload: { pointCategory, value } }) {
  yield put({
    type: 'qup/SET_STATE',
    payload: {
      [pointCategory]: value,
    },
  })
}

export function* SUBMIT_QUP_REDUCERS({
  resolve,
  reject,
  formValues,
  pointsValues,
  entityId,
  entityFundId
}) {

  const qupReportData = {
    Entity: `/api/entities/${entityFundId}`,
    createdBy: entityId,
    name: formValues.loanName,
    date: formValues.date,
    ltvRequestedLoan: parseFloat(numbersOnly(formValues.ltvRequestedLoan)),
    ltvAssetValue: parseFloat(numbersOnly(formValues.ltvAssetValue)),
    ltvPoints: parseFloat(pointsValues.ltvPoints),
    arvRequestedLoan: parseFloat(numbersOnly(formValues.arvRequestedLoan)),
    afterRepairedValue: parseFloat(numbersOnly(formValues.afterRepairedValue)),
    arvPoints: parseFloat(pointsValues.arvPoints),
    liquidityValue: formValues.liquidity,
    balanceValue: formValues.balanceSheet,
    creditworthinessValue: formValues.creditworthiness,
    guarantorFactorsValue: formValues.guarantorIndependentFactors ? formValues.guarantorIndependentFactors : [],
    takeoutValue: formValues.adjustment,
    guarantorPoints: pointsValues.guarantorPoints,
    classValue: formValues.assetClass,
    stabilizedValue: formValues.stabilized,
    marketValue: formValues.marketGrowth,
    assetFactorsValue: formValues.assetIndependentFactors ? formValues.assetIndependentFactors : [],
    assetTypePoints: pointsValues.assetTypePoints,
    cashFlowValue: formValues.cashFlow,
    dscrValue: formValues.dscr,
    incomePoints: pointsValues.incomePoints,
    evidenceValue: formValues.takeOutEvidence,
    possibilitiesValue: formValues.internalPossibilities,
    exitStrategyPoints: pointsValues.exitStrategyPoints,
    cashContributedValue: formValues.cashContributed,
    liquidContributionValue: formValues.liquidContributions,
    illiquidContributionValue: formValues.illiquidContributions,
    skinInTheGamePoints: pointsValues.skinInTheGamePoints,
    currentConditionValue: formValues.currentCondition,
    contemplatedConditionValue: formValues.contemplatedCondition,
    conditionPoints: pointsValues.conditionPoints,
    marketabilityValue: formValues.marketability,
    packagingValue: formValues.loanPackaging,
    marketabilityPoints: pointsValues.marketabilityPoints,
    valueAddonsValue: formValues.valueAdd,
    valueAddonsPoints: pointsValues.valueAddonsPoints,
    staffingValue: formValues.staffingResources,
    costValue: formValues.costManagement,
    timeValue: formValues.liquidationTime,
    managementBurdenPoints: pointsValues.managementBurdenPoints,
    conditionsValue: formValues.marketConditions,
    trendsValue: formValues.trends,
    activityValue: formValues.activity,
    totalMarketPoints: pointsValues.totalMarketPoints,
    returnValue: formValues.profitabilityReturn,
    immediacyValue: formValues.returnImmediacy,
    profitabilityValue: formValues.cashProfitability,
    totalProfitabilityPoints: pointsValues.totalProfitabilityPoints,
    marginValue: formValues.marginAUM,
    syndicationsValue: formValues.syndications,
    totalCashAllocationPoints: pointsValues.totalCashAllocationPoints,
    completednessValue: formValues.completedness,
    totalCompletednessPoints: pointsValues.totalCompletednessPoints,
    totalQUPscore: parseFloat(pointsValues.currentQUPScore)
  }

  yield put({
    type: actions.SET_STATE,
    payload: qupReportData,
  })

  console.log("qupReportData", qupReportData)

 yield call(api.post, `/qup_reports`, qupReportData)

  try {
    yield call(resolve, "success")
  } catch (error) {
    yield call(reject, error)
  }
}


export function* GET_QUP_REPORTS({ resolve, reject }) {
  try {
    const response = yield call(api.get, `/qup_reports`)
    const reportsData = response.data

    yield call(resolve, reportsData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* GET_QUP_REPORT_DATA({ resolve, reject, qupReportId }) {

  let reportData
  if(qupReportId === 0){
    reportData = {
      liquidityValue: 0,
      balanceValue: 0,
      creditworthinessValue: 0,
      guarantorFactorsValue: 0,
      takeoutValue: 0,
      classValue: 0,
      stabilizedValue: 0,
      marketValue: 0,
      assetFactorsValue: 0,
      cashFlowValue: 0,
      dscrValue: 0,
      evidenceValue: 0,
      possibilitiesValue: 0,
      cashContributedValue: 0,
      liquidContributionValue: 0,
      illiquidContributionValue: 0,
      currentConditionValue: 0,
      contemplatedConditionValue: 0,
      marketabilityValue: 0,
      packagingValue: 0,
      valueAddonsValue: 0,
      staffingValue: 0,
      costValue: 0,
      timeValue: 0,
      conditionsValue: 0,
      trendsValue: 0,
      activityValue: 0,
      returnValue: 0,
      immediacyValue: 0,
      profitabilityValue: 0,
      marginValue: 0,
      syndicationsValue: 0,
      completednessValue: 0
    }

  }
  else{
    const response = yield call(api.get, `/qup_reports/${qupReportId}`)
    reportData = response.data
  }
  try {
    yield call(resolve, reportData)
  } catch (error) {
    yield call(reject, error)
  }
}


export function* GET_QUP_REPORTS_FROM_TABLE({ resolve, reject, qupReportId }) {
  try {
    const response = yield call(api.get, `/qup_reports/${qupReportId}`)
    const reportData = response.data

    yield put({
      type: 'qup/SET_STATE',
      payload: {
        Loan: reportData.name,
        QUPReportId: reportData.id,
        date: reportData.date,
        ltvRequestedLoan: reportData.ltvRequestedLoan,
        ltvAssetValue: reportData.ltvAssetValue,
        afterRepairedValue: reportData.afterRepairedValue,
        ltvPoints: reportData.ltvPoints,
        arvPoints: reportData.arvPoints,
        guarantorPoints: reportData.guarantorPoints,
        assetTypePoints: reportData.assetTypePoints,
        incomePoints: reportData.incomePoints,
        exitStrategyPoints: reportData.exitStrategyPoints,
        skinInTheGamePoints: reportData.skinInTheGamePoints,
        conditionPoints: reportData.conditionPoints,
        marketabilityPoints: reportData.marketabilityPoints,
        valueAddonsPoints: reportData.valueAddonsPoints,
        managementBurdenPoints: reportData.managementBurdenPoints,
        totalMarketPoints: reportData.totalMarketPoints,
        totalProfitabilityPoints: reportData.totalProfitabilityPoints,
        totalCashAllocationPoints: reportData.totalCashAllocationPoints,
        totalCompletednessPoints: reportData.totalCompletednessPoints,
        totalQUPScore: reportData.totalQUPscore,
      },
    })
    yield call(resolve, reportData)
  } catch (error) {
    yield call(reject, error)
  }
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_POINT_VALUE, CHANGE_POINT_VALUE),
    takeEvery(actions.SUBMIT_QUP_REDUCERS, SUBMIT_QUP_REDUCERS),
    takeEvery(actions.GET_QUP_REPORT_DATA, GET_QUP_REPORT_DATA),
    takeEvery(actions.GET_QUP_REPORTS, GET_QUP_REPORTS),
    takeEvery(actions.GET_QUP_REPORTS_FROM_TABLE, GET_QUP_REPORTS_FROM_TABLE),
  ])
}
