import React from 'react'
import { Input, Alert } from 'antd'

const InputField = ({ input, meta, defaultValue, saveFieldValue, ...custom }) => {
  const { value, onChange } = input
  const { touched, error } = meta
  const hasError = touched && error !== undefined
  return (
    <div className={hasError ? 'has-error' : ''}>
      <Input
        onChange={onChange}
        value={value || defaultValue || ''}
        // onBlur={() => {
        //   onBlur()
        //   if (saveFieldValue && valid) {
        //     saveFieldValue(name, value)
        //   }
        // }}
        {...input}
        {...custom}
      />
      {hasError && <Alert className="alert" type="error" message={error} banner />}
    </div>
  )
}
export default InputField
