import React, { Component } from 'react'
import { Select, Spin, Alert } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import actions from 'redux/investoronboarding/actions'

const { Option } = Select
const NoInvestorFound = (text) => {console.log('text',text)
  return (
     <Option style={{color: text.text === 'No Investor Found' ? 'red':'green'}} key={text.value}>{text.text}</Option>
    )
}
const SelectFieldComponent = ({ input, meta, children, refProps, ...custom }) => {
  const { value, onChange, onBlur, defaultValue } = input
  const { error, touched } = meta
  const hasError = touched && error !== undefined
  const { placeholder } = custom

  return (
    <div className={hasError ? 'has-error' : ''}>
      <Select
        value={value ? value : placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
        {...custom}
        ref={refProps}
      >
        {children}
      </Select>
    </div>
  )
}

class SelectSearchField extends Component {
  constructor(props) {
    super(props)

    this.searchInvestor = debounce(this.searchInvestor, 800)

    this.state = {
      data: [],
      value: [],
      fetching: false,
    }
  }

  searchInvestor = (email) => {
    if (email !== '') {
      this.setState({
        fetching: true,
      })

      const { dispatch } = this.props

      new Promise((resolve, reject) => {
        dispatch({
          type: actions.SEARCH_INVESTOR,
          resolve,
          reject,
          email: email.toLowerCase(),
        })
      })
        .then((response) => {
          const investors = response.map((investor) => {
            const {
              id,
              person: { firstName, lastName },
            } = investor

            return {
              value: id,
              text: `${firstName} ${lastName}`,
            }
          })
          console.log('investors',investors)
          if (investors.length === 0){
            this.setState({
              data: [{color: "red",text:'No Investor Found'}],
              fetching: false,
            })
          }else{
            this.setState({
              data: investors,
              fetching: false,
            })
          }
        })
        .catch((error) => console.log({ error }))
    }
  }

  handleChange = (value) => {
    const { dispatch, initializeWithExistingInvestor, handleLoadingInvestor } = this.props
    const { key } = value

    handleLoadingInvestor(true)

    new Promise((resolve, reject) => {
      dispatch({
        type: actions.FETCH_INVESTOR,
        resolve,
        reject,
        userId: key,
      })
    })
      .then((response) => {console.log(response)
        initializeWithExistingInvestor(response)
        handleLoadingInvestor(false)
      })
      .catch((error) => console.log({ error }))
  }

  render() {
    const { fetching, data, value } = this.state
    const { handleBlur } = this.props

    return (
      <Field
        name="investorSearch"
        component={SelectFieldComponent}
        showSearch
        labelInValue
        value={value}
        placeholder="enter name to search..."
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={this.searchInvestor}
        onChange={this.handleChange}
        onBlur={handleBlur}
        suffixIcon={<SearchOutlined />}
      >
        {data.map((d) => (
          NoInvestorFound(d)
        ))}
      </Field>
    )
  }
}

export default connect(null, (dispatch) => ({ dispatch }))(SelectSearchField)
