import { takeEvery, all, call } from 'redux-saga/effects'
import Api from 'services/api/Api'

import actions from './actions'

const api = new Api()

export function* FETCH_INVESTOR_REPORTING_TABLE_DATA({ resolve, reject, entityId }) {
  try {
    const response = yield call(api.get, `/entities/${entityId}`)
    const firstEntity = response.data.owningEntityFunds[0] ? response.data.owningEntityFunds[0] : undefined
    if(firstEntity) {
      const tempData = yield call(api.get, firstEntity.substring(4))
      const tableData = yield call(api.get, `entities/${tempData.data.entity.id}`)
      const accounts = tableData.data.fundInvestorAccounts
      const array = []
      for (let i = 0; i < accounts.length; i += 1) {
        const out = yield call(api.get, `${accounts[i].substring(4)}`)
        array.push(out.data)
      }
      yield call(resolve, array)
    }
    else{
      yield call(resolve, [])
    }

  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVESTOR_REPORTING_TABLE_DATA, FETCH_INVESTOR_REPORTING_TABLE_DATA),
  ])
}
