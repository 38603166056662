import React from 'react'
import { DatePicker } from 'antd'

const DatePickerField = ({
                      input,
                      defaultValue,
                    }) => {
  const { value, onChange } = input

  return (
    <DatePicker
      format="MM/DD/YYYY"
      onChange={onChange}
      value={value || defaultValue || ''}
      style={{width: "50%"}}
    />
  )
}
export default DatePickerField
// the code above needs to be changed, it will break or send crap into db
