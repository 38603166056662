const actions = {
  FETCH_ENTITY_FUND_DATA: 'fund/FETCH_ENTITY_FUND_DATA',
  FETCH_PDF_DATA: 'fund/FETCH_PDF_DATA',
  FETCH_PGF_DATA: 'fund/FETCH_PGF_DATA',
  FETCH_LP_DIST_DATA: 'fund/FETCH_LP_DIST_DATA',
  FETCH_LP_ALL_FUND_DATA: 'fund/FETCH_LP_ALL_FUND_DATA',
  FETCH_TOTAL_AUM_DATA: 'fund/FETCH_TOTAL_AUM_DATA',
  FETCH_INVESTOR_LOCATION_DATA: 'fund/FETCH_INVESTOR_LOCATION_DATA',
  FETCH_AUM_CHART_DATA: 'fund/FETCH_AUM_CHART_DATA',
  FETCH_PDF_CHART_DATA: 'fund/FETCH_PDF_CHART_DATA',
  FETCH_PGF_CHART_DATA: 'fund/FETCH_PGF_CHART_DATA',
}

export default actions
