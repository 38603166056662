import { takeEvery, all, call, put } from 'redux-saga/effects'

import { notification } from 'antd'
import actions from './actions'
import activeInvitationsData from './mockData/data'
import endpoints from '../endpoints'
import Api from '../../services/api/Api'
import * as type from './types'

const api = new Api()

export function* CHANGE_INVESTOR_NAME({ payload: { value } }) {
  // yield store.set(`app.qup.${pointCategory}`, value)
  yield put({
    type: 'investorVerification/SET_STATE',
    payload: {
      investorName: value,
    },
  })
}

export function* FETCH_ACTIVE_INVITATIONS({ resolve, reject }) {
  try {
    const invitationsData  = yield call(api.get, endpoints('investorInvitationInformation', 1))
    yield call(resolve, invitationsData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_ACTIVE_RIA_INVITATIONS({ resolve, reject }) {
  try {
    const invitationsData  = yield call(api.get, endpoints('riaInvestorsSummary', 1))
    yield call(resolve, invitationsData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* GET_ONBOARDING_TASKS(entityMembershipId) {console.log('entityMembershipId.id', entityMembershipId)
  try {

    const onboardingTasks  = yield call(api.get, endpoints('onboardingTasks', entityMembershipId.taskID))

    yield put({ type: type.GET_ONBOARDING_TASKS_SUCCESS, onboardingStepsData: onboardingTasks.data[0] })
  }catch (e) {
    console.log(e)
  }
}

export function* GET_ACTIVE_INVITATIONS() {
  try {
    const invitationsData  = yield call(api.get, endpoints('investorInvitationInformation', 1))
    console.log(invitationsData)
    yield call(invitationsData)
  } catch (error) {
    yield call(error)
  }
}


export function* UPDATE_INVESTOR_STATUS(id ) {
  try {
    const completed = { complete: true }
    const unCompleted = {complete: false}

    if (!id.checked){
      const info = yield call(api.patch, endpoints('investorEntityStatusUpdate', id.statusId), unCompleted)
      yield put({type: type.UPDATE_INVESTOR_STATUS_SUCCESS, invitationData: info.data })
    }

    if (id.checked){
      const info = yield call(api.patch, endpoints('investorEntityStatusUpdate', id.statusId), completed)
      yield put({type: type.UPDATE_INVESTOR_STATUS_SUCCESS, invitationData: info.data })
    }
    // yield call(api.put, getEndPoints('entities', entityId), investmentDetailData)
    //  const info = yield call(api.put, endpoints('investorEntityStatusUpdate', id.statusId), completed)
    //  yield put({type: type.UPDATE_INVESTOR_STATUS_SUCCESS, invitationData: info.data })
    // notification.success({
    //   message: 'Status Updated',
    //   description: 'Thanks'
    // })
    // yield put({type: type.UPDATE_INVESTOR_STATUS, invitationData: updateInvestorStatus.data })
  } catch (error) {
   console.log('error', error)
    // yield put({ type: type.UPDATE_INVESTOR_STATUS_FAILED, message: error.message })
    notification.error({
      message: 'Status Update Failed',
      description: 'Please Try Again'
    })
  }
}

export function* ON_DECLINE_INVESTOR({ resolve, reject }) {
  try {
    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export function* ON_APPROVE_INVESTOR({ resolve, reject }) {
  try {
    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ACTIVE_INVITATIONS, FETCH_ACTIVE_INVITATIONS),
    takeEvery(actions.FETCH_ACTIVE_RIA_INVITATIONS, FETCH_ACTIVE_RIA_INVITATIONS),
    takeEvery(actions.GET_ACTIVE_INVITATIONS, GET_ACTIVE_INVITATIONS),
    takeEvery(type.GET_ONBOARDING_TASKS, GET_ONBOARDING_TASKS),
    takeEvery(actions.UPDATE_INVESTOR_STATUS, UPDATE_INVESTOR_STATUS),
    takeEvery(actions.ON_DECLINE_INVESTOR, ON_DECLINE_INVESTOR),
    takeEvery(actions.ON_APPROVE_INVESTOR, ON_APPROVE_INVESTOR),
    takeEvery(actions.CHANGE_INVESTOR_NAME, CHANGE_INVESTOR_NAME),
  ])
}
