import actions from './actions'
import * as type from './types'
import { GET_ONBOARDING_TASKS_FAILED, GET_ONBOARDING_TASKS_SUCCESS } from './types'

const initialState = {
  investorName: "",
  invitationData: null,
  onboardingStepsData: null,
  error: '',
  loading: false
}

export default function ioverificationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case type.UPDATE_INVESTOR_STATUS:
      return {
        ...state
      }
    case type.UPDATE_INVESTOR_STATUS_SUCCESS:
      return {
        ...state,
        invitationData: action.payload
      }
    case type.UPDATE_INVESTOR_STATUS_FAILED:
      return {
        ...state,
        error: action.error
      }
    case type.GET_ACTIVE_INVITATIONS:
      return {
        ...state
      }
    case type.GET_ACTIVE_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitationData: action.payload
      }
    case type.GET_ACTIVE_INVITATIONS_FAILED:
      return {
        ...state,
        error: action.error
      }
    case type.GET_ONBOARDING_TASKS:
      return {
        ...state,
        loading: true
      }
    case type.GET_ONBOARDING_TASKS_SUCCESS:
      return {
        ...state,
        onboardingStepsData: action.onboardingStepsData,
        loading: false
      }
    case type.GET_ONBOARDING_TASKS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    default:
      return state
  }
}
