import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const { emptyValidator } = Validators

const ZipField = ({ placeholder, onBlur, check,isZipRequired }) => (
  <Field
    disabled={check}
    name="zip"
    placeholder={placeholder}
    maxLength={10}
    component={inputType}
    onBlur={onBlur}
    validate={isZipRequired ? [emptyValidator]: null}
  />
)

export default ZipField
