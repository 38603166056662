import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'antd'

// import FeatureFlag from './FeatureFlag' //todo add this back in
// import ProfileMenu from '../../LayoutComponents/ProfileMenu'
import LedgerEntryModal from '../LedgerEntryModal'
import styles from './appSwitcherStyle.module.scss'
import Auth0 from '../../../services/auth0'
// import InvestorBrokerSwitcher from '../../DevComponents/AppSwitcher'


@withRouter
class AppSwitcher extends Component {
  constructor(props) {
    super(props)

    this.state = {
      investorDashboard: Auth0.userHasRole('investor_accredited').hasRole,
      fundDashboard: Auth0.userHasRole('fund_manager').hasRole,
 //     invitedDashboard: Auth0.userHasRole('investor_invited').hasRole,
 //    brokerDashboard: Auth0.userHasRole('broker_premium').hasRole,
    }
  }

  signIn = (data) => {
    const { dispatch } = this.props

    return new Promise((resolve, reject) => {
      dispatch({
        type: 'user/LOGIN',
        payload: data,
        resolve,
        reject,
      })
    })
      .then(() => {
        const { history } = this.props
        const investorDashboard = Auth0.userHasRole('investor_accredited').hasRole
        const invitedDashboard = Auth0.userHasRole('investor_invited').hasRole
   //     const brokerDashboard = Auth0.userHasRole('broker_premium').hasRole
        const fundDashboard = Auth0.userHasRole('fund_manager').hasRole
        this.setState({
          investorDashboard,
          fundDashboard,
   //       brokerDashboard,
          invitedDashboard
        })

        history.push('/home')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  showModal = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'general/CHANGE_LEDGER_MODAL_STATUS',
      payload: {
        value: true,
      },
    })
  }

  render() {
    const investor = {
      username: 'investor@s2n.solutions',
      password: 'Password2020!',
    }
// backup user since the above is broken
    const investorInvited = {
      username: 'investorinvited@s2n.solutions',
      password: 'Password2020!',
    }
    const fund = {
      username: 'fundmanager@s2n.solutions',
      password: 'Password2020!',
    }
    const { investorDashboard, fundDashboard, invitedDashboard } = this.state

    return (
      <div className={styles.navigationBar}>
         {/*
        <div className={styles.appButtonWrapper}>
          { !investorDashboard ? (
            <Link to="/ima/crm/invitation">
              <Button
                className={styles.invite}
                type="primary"
                shape="circle"
                size="large"
              >
                <strong>LP+</strong>
              </Button>
            </Link>) : null}
        </div> */}
        {/* <div className={styles.appButtonWrapper}>
          { !investorDashboard ? (
            <Link to="/ima/crm/due-diligence">
              <Button
                className={styles.dueDiligenceButton}
                type="primary"
                shape="circle"
                size="large"
              >
                <strong>DD+</strong>
              </Button>
            </Link>) : null}
        </div> */}
          {/*
        <div className={styles.appButtonWrapper}>
          { !investorDashboard ? (
            <Button
              className={styles.ledgerButton}
              type="primary"
              shape="circle"
              size="large"
              onClick={this.showModal}
            >
              <strong>L$</strong>
            </Button>) : null}
          <LedgerEntryModal />
        </div> */} {/*
        <div className={styles.appButtonWrapper}>
          { !invitedDashboard ? (
            <Button
              className={styles.newInvestorButton}
              type="primary"
              shape="circle"
              size="large"
              onClick={() => this.signIn(investorInvited)}
            >
              <strong>New</strong>
            </Button>):null}
        </div> */} {/*
        <div className={styles.appButtonWrapper}>
          { !investorDashboard ? (
            <Button
              className={styles.InvestorAppButton}
              type="primary"
              shape="circle"
              size="large"
              onClick={() => this.signIn(investor)}
            >
              <strong>I A</strong>
            </Button>) : null}
        </div> */}
          {/* <div>
          { !fundDashboard ? (
            <Button
              className={styles.fundManagerAppButton}
              type="primary"
              shape="circle"
              size="large"
              onClick={() => this.signIn(fund)}
            >
              <strong>FMA</strong>
            </Button>) : null}
        </div> */}
      </div>
    )
  }
}

export default connect((state) => ({ accountStatus: state.user.accountStatus }), (dispatch) => ({ dispatch }))(AppSwitcher)

