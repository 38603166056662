const actions = {
  SET_STATE: 'iopreview/SET_STATE',
  EDIT_IO_INVITATION: 'iopreview/EDIT_IO_INVITATION',
  SEND_IO_INVITATION: 'iopreview/SEND_IO_INVITATION',
  CHANGE_INVESTOR_NAME: 'iopreview/CHANGE_INVESTOR_NAME',
  ON_TEMPLATE_SELECT: 'iopreview/ON_TEMPLATE_SELECT',
  ON_TEMPLATE_SELECT_SUCCESS: 'iopreview/ON_TEMPLATE_SELECT_SUCCESS',
}

export default actions
