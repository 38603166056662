import { takeEvery, call, all, put /* Daniel, put */ } from 'redux-saga/effects'

import Api from 'services/api/Api'
import { notification } from 'antd'
import getEndPoints from '../endpoints'
import actions from './actions'
import * as type from './types'


const api = new Api()

export function* FETCH_INVESTMENT_DETAIL({ resolve, reject, entityId, userId }) {
  try {
    const entityResponse = yield call(api.get, `/entities/${entityId}`)
    console.log('entityResponseFetchInvestmentDetails',entityResponse)
    const accountTypesResponse = yield call(api.get, getEndPoints('entityTypes'))
    console.log('accountTypesResponseFetchInvestmentDetails',entityResponse)
    const userResponse = yield call(api.get, `/users/${userId}`)
    console.log('userResponseFetchInvestmentDetails',entityResponse)
    const response = {
      user: userResponse.data,
      entity: entityResponse.data,
      accountTypes: accountTypesResponse.data,
    }
    yield call(resolve, response)
    yield put({type: type.GET_INVESTMENT_DETAILS_SUCCESS, investmentDetails:response})
  } catch (error) {

    yield call(reject, error)

    yield put({type: type.GET_INVESTMENT_DETAILS_FAILED, message:error.message})
    notification.error({
      message: 'Fetching Investment Details Failed',
    })

  }
}

export function* FETCH_ACCOUNT_DETAIL({ resolve, reject, entityId }) {
  try {
    const response = yield call(api.get, `/entities/${entityId}`)

    yield call(resolve, response.data)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_IDENTITY_DETAILS({ resolve, reject, userId, entityId }) {
  try {
    const userResponse = yield call(api.get, `/users/${userId}`)
    const entityResponse = yield call(api.get, `/entities/${entityId}`)

    const response = {
      user: userResponse.data,
      entity: entityResponse.data,
    }
    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SUBMIT_ONBOARDING_DETAIL({
  resolve,
  reject,
  investmentDetailData,
  contributionData,
  settingsData,
  entityId,
  formEntityType
}) {
  console.log('submitOnboarding -' , investmentDetailData, contributionData, settingsData, entityId, formEntityType )
  try {
    if (JSON.stringify(formEntityType) !== JSON.stringify(investmentDetailData)){
      yield call(api.patch, getEndPoints('entities', entityId), investmentDetailData)
    }
    const response = yield call(api.get, getEndPoints('entities', entityId))


    if(contributionData !== undefined){
      const amount = parseFloat(contributionData.balance)
      const investorAccountsId = response.data.memberEntityMemberships[0].fundMemberSettings[0].id
      const contribution = {contribution:amount}
      yield call(api.patch, getEndPoints('fundMemberSettings', investorAccountsId), contribution)
    }



    if (response.data.memberEntityMemberships[0].fundMemberSettings[0].contribution === undefined ) {
      settingsData.entityMembership = `/api/entity_memberships/${response.data.memberEntityMemberships[0].id}`

      yield call(api.post, getEndPoints('fundMemberSettings'), settingsData)
    }
    else{
      const membershipId = response.data.memberEntityMemberships[0].fundMemberSettings[0].id
      yield call(api.patch, getEndPoints('fundMemberSettings', membershipId), settingsData)
    }
    yield call(resolve, 'success')
    notification.success({
      message: 'Success',
    })

  } catch (error) {
    yield call(reject, error)
    notification.error({
      message: 'Onboard Detail Failed',
      description: error.message
    })
  }
}

export function* SUBMIT_ONBOARDING_IDENTITY({ resolve, reject, onboardingIdentityData, entityId,userObject,formObject}) {
  try {console.log('SAGAValues',formObject.identity.values)
    const entityContactAddressId = userObject.person.entity.entityContactAddresses[0].id
    const formValues = formObject.identity.values.contactInformation
    const contactAddressId = userObject.person.entity.entityContactAddresses[0].contactAddress.id

    const address = {
      "address1": formValues.streetOne,
      "address2": formValues.streetTwo,
      "city": formValues.city,
      "state": `/api/geo_states/${formValues.state}`,
      "postalCode": formValues.zip,
      "country": `/api/geo_countries/${formValues.country}`
    }

   const  entityContactAddressType = {"entityContactAddressType": "/api/entity_contact_address_types/physical"}

    yield call(api.patch, getEndPoints('contactAddresses', contactAddressId), address)
    yield call(api.patch,`entity_contact_addresses/${entityContactAddressId}`,entityContactAddressType)
    const { id: userId } = onboardingIdentityData
    delete onboardingIdentityData.id
    yield call(api.patch, getEndPoints('users', userId), onboardingIdentityData)


    yield call(resolve, 'success')
    notification.success({
      message: 'Success',
    })
  } catch (error) {
    yield call(reject, error)
    notification.error({
      message: 'Onboard Identity Submission Failed',
      description: error.message
    })
  }
}

export function* SUBMIT_ENTITY_DETAILS({
  resolve,
  reject,
  formValues,
  entityId
}) {
  try {
    const {sameAsPersonal, contactInformation} = formValues
    if(sameAsPersonal === "no"){
      const contactAddresses = {
        address1: contactInformation.streetOne,
        address2: contactInformation.streetTwo ? contactInformation.streetTwo : null,
        city: contactInformation.city,
        state: contactInformation.state && `/api/geo_states/${contactInformation.state}`,
        postalCode: contactInformation.zip,
      }
      const response = yield call(api.post,'contact_addresses', contactAddresses )

      const entityAddressData = {
        entity: `/api/entities/${entityId}`,
        isPrimary: true,
        contactAddress: `/api/contact_addresses/${response.data.id}`
      }
      yield call(api.post,'entity_contact_addresses', entityAddressData )
    }
    yield call(resolve, 'success')
    notification.success({
      message: 'Success',
    })
  } catch (error) {
    yield call(reject, error)
    notification.error({
      message: 'Submission of Entity Details Failed',
      description: error.message
    })
  }
}

export function* SUBMIT_ACCOUNT_DETAILS({ resolve, reject , onboardingAccountDetailsData, entityId }) {
  try {
    const response = yield call(api.get, getEndPoints('entities', entityId))
    const membershipId = response.data.memberEntityMemberships[0].fundMemberSettings[0].id
    yield call(api.patch, getEndPoints('fundMemberSettings', membershipId), onboardingAccountDetailsData)

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
    notification.error({
      message: 'Submit Account Details Failed',
      description: error.message
    })
  }
}

export function* SEND_ONBOARDING_ZOHO({ resolve, reject, docusignData, commData,memberId }) {
  try {
    const zohoObject = {
      "templateId": "255722000000018147",
      "entityMembershipId": memberId
    }
    // yield call(api.post, '/communications', commData)
    const response = yield call(api.post, 'zoho_sign_integrations', zohoObject)
    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
    notification.error({
      message: 'Zoho Request Failed',
      description: error.message
    })
  }
}

export function* UPDATE_REVIEW_REDUCER({
  resolve,
  reject,
}) {
  yield put({
    type: actions.SUBMIT_ENTITY_DETAILS_SUCCESS,
    payload: {
      fromReview: true,
    },
  })

  try {
    yield call(resolve, "success")
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_INVESTMENT_DETAIL, FETCH_INVESTMENT_DETAIL),
    takeEvery(type.GET_INVESTMENT_DETAILS, FETCH_INVESTMENT_DETAIL),
    takeEvery(actions.FETCH_ACCOUNT_DETAIL, FETCH_ACCOUNT_DETAIL),
    takeEvery(actions.FETCH_IDENTITY_DETAILS, FETCH_IDENTITY_DETAILS),
    takeEvery(actions.SUBMIT_ONBOARDING_DETAIL, SUBMIT_ONBOARDING_DETAIL),
    takeEvery(actions.SUBMIT_ONBOARDING_IDENTITY, SUBMIT_ONBOARDING_IDENTITY),
    takeEvery(actions.SUBMIT_ENTITY_DETAILS, SUBMIT_ENTITY_DETAILS),
    takeEvery(actions.SUBMIT_ACCOUNT_DETAILS, SUBMIT_ACCOUNT_DETAILS),
    takeEvery(actions.UPDATE_REVIEW_REDUCER, UPDATE_REVIEW_REDUCER),
    takeEvery(actions.SEND_ONBOARDING_ZOHO, SEND_ONBOARDING_ZOHO),

  ])
}
