const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
  SET_SELECTION_COLOR: 'settings/SET_SELECTION_COLOR',
  SET_APPLICATION_COLOR: 'settings/SET_APPLICATION_COLOR',
  SET_HOVER_COLOR: 'settings/SET_HOVER_COLOR',
  SET_TOP_OVERLAY: 'settings/SET_TOP_OVERLAY',
  SET_BOTTOM_OVERLAY: 'settings/SET_BOTTOM_OVERLAY',
  SET_BACKGROUND_OPACITY: 'settings/SET_BACKGROUND_OPACITY',
}

export default actions
