import React, { Component } from 'react'
import { Form } from 'antd'

import StreetOneField from '../formFields/StreetOneField'
import StreetTwoField from '../formFields/StreetTwoField'
import ZipField from '../formFields/ZipField'
import StateField from '../formFields/StateField'
import CityField from '../formFields/CityField'

import styles from './styles/style.module.scss'

class PhysicalAddress extends Component {
  render() {
    return (
      <div className={styles.physicalAddressWrapper}>
        <Form.Item>
          <StreetOneField placeholder="Address Line 1" />
        </Form.Item>
        <Form.Item>
          <StreetTwoField placeholder="Address Line 2" />
        </Form.Item>
        <Form.Item>
          <ZipField placeholder="Postal Code" style={{ marginRight: '10px' }} />
          <div className={styles.inlineFormItem}>
            <StateField placeholder="State" />
          </div>
        </Form.Item>
        <Form.Item>
          <CityField placeholder="City" />
        </Form.Item>
      </div>
    )
  }
}

export default PhysicalAddress
