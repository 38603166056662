import React from 'react'
import { Radio, Alert } from 'antd'
import 'antd/dist/antd.css';

const RadioGroupField = ({ input, defaultValue, meta, label, children, ...custom }) => {
  const { value, onChange, buttonStyle, size } = input
  const { touched, error } = meta
  const hasError = touched && error !== undefined

  return (
    <div className={hasError ? 'has-error' : ''}>
      {label && <span style={{ marginRight: '10px' }}>{label}</span>}
      <Radio.Group
        size={size}
        onChange={onChange}
        value={value || defaultValue || false}
        buttonStyle={buttonStyle}
        {...custom}
        {...input}
      >
        {children}
      </Radio.Group>
      {hasError && <Alert className="alert" type="error" message={error} banner />}
    </div>
  )
}
export default RadioGroupField
