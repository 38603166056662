import React, { Component, Fragment } from 'react'
import { Upload, Modal, Spin, Alert } from 'antd'

export const getExtension = filename => {
  const parts = filename.split('.')
  return parts[parts.length - 1]
}

export const isImage = filename => {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      return true
    default:
      return false
  }
}

class ImageUploader extends Component {
  constructor() {
    super()
    this.state = {
      previewVisible: false,
      previewImage: '',
      loading: false,
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handlePreview = file => {
    if (isImage(file.name)) {
      this.setState({
        previewImage: file.url || file.thumbUrl,
        previewVisible: true,
      })
    } else {
      window.open(file.url, '_blank')
    }
  }

  uploadMedia = ({ file }) => {
    const { onUpload } = this.props
    onUpload(file)
  }

  onRemove = file => {
    const { onRemove } = this.props
    onRemove(file)
  }

  render() {
    const { previewVisible, previewImage, loading } = this.state
    const {
      medias,
      handleImageChange,
      uploadLimit,
      accept,
      meta,
      input,
      beforeUpload,
      uploadButton,
    } = this.props
    const { error, touched } = meta
    const hasError = touched && error !== undefined && medias.length === 0
    // const uploadButton = (
    //   <>
    //     <Icon type="plus" />
    //     <div className="ant-upload-text">Upload</div>
    //   </div>
    // )
    let fileList
    if (medias && Array.isArray(medias)) {
      fileList = medias.map(image => ({
        uid: image.pictureId,
        mediaId: image.id,
        name: image.fileName,
        status: 'done',
        url: `https://funding-dev-pictures-documents.s3.us-east-2.amazonaws.com/${image.path}`,
      }))
    } else {
      const { fileList: mediaFileList } = medias
      fileList = mediaFileList
    }

    return (
      <>
        <Spin spinning={loading} className="clearfix">
          <Upload
            onChange={handleImageChange}
            customRequest={this.uploadMedia}
            onRemove={this.onRemove}
            listType="picture-card"
            fileList={fileList}
            onPreview={this.handlePreview}
            beforeUpload={beforeUpload}
            accept={accept}
            {...input}
          >
            {fileList.length < uploadLimit ? uploadButton : ''}
          </Upload>
          <Modal
            maskClosable={false}
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Spin>
        {hasError && <Alert message={error} type="error" showIcon />}
      </>
    )
  }
}
export default ImageUploader
