import { all, takeEvery, put } from 'redux-saga/effects'
// scss store from 'store'
import actions from './actions'

const fundInfoData = [
  {
    owningEntity: "Cache Private Capital Management",
    fundName: "Prospera Growth",
    fundID: "19456",
    fundEIN: "894-45-8943",
    accreditedInvestor: "Yes",
    openEnded: "Yes",
    typeOfFund: "Preferred",
    preferredReturn: 8.0,
    hurdleOne: 0,
    hurdleTwo: 0,
    hurdleThree: 0,
    hurdleLast: 0,
    gpReturn: 80,
    lpReturn: 20,
    hurdle1GP: 0,
    hurdle2GP: 0,
    hurdle3GP: 0,
    hurdleLastGP: 0,
    hurdle1LP: 0,
    hurdle2LP: 0,
    hurdle3LP: 0,
    hurdleLastLP: 0,
    aumUpdate:"Quarterly",
    distFrequency: "Monthly",
    managementFee: 1.5,
    closeExtensions: 0,
    managementFeeCharge: "Committed",
    preferredPerpetuity: "True",
    managementStepDown: "2022/10/19",
    dropDownDate: "2023/9/23",
    fundCloseDate: "2024/12/31",
  },
  {
    owningEntity: "Cache Private Capital Management",
    fundName: "Prospera Diversified",
    fundID: "19457",
    fundEIN: "894-45-8944",
    accreditedInvestor: "No",
    openEnded: "Yes",
    typeOfFund: "Parri Passu",
    preferredReturn: 10.0,
    hurdleOne: 0,
    hurdleTwo: 0,
    hurdleThree: 0,
    hurdleLast: 0,
    gpReturn: 70,
    lpReturn: 30,
    hurdle1GP: 0,
    hurdle2GP: 0,
    hurdle3GP: 0,
    hurdleLastGP: 0,
    hurdle1LP: 0,
    hurdle2LP: 0,
    hurdle3LP: 0,
    hurdleLastLP: 0,
    aumUpdate:"Quarterly",
    distFrequency: "Monthly",
    managementFee: 3.0,
    closeExtensions: 0,
    managementFeeCharge: "Drawn",
    preferredPerpetuity: "True",
    managementStepDown: "2022/7/19",
    dropDownDate: "2023/6/23",
    fundCloseDate: "2024/5/31",
  },
  {
    owningEntity: "Black Street Management",
    fundName: "Black Street",
    fundID: "19480",
    fundEIN: "874-55-9949",
    accreditedInvestor: "Yes",
    openEnded: "Yes",
    typeOfFund: "Preferred",
    preferredReturn: 9.0,
    hurdleOne: 0,
    hurdleTwo: 0,
    hurdleThree: 0,
    hurdleLast: 0,
    gpReturn: 80,
    lpReturn: 20,
    hurdle1GP: 0,
    hurdle2GP: 0,
    hurdle3GP: 0,
    hurdleLastGP: 0,
    hurdle1LP: 0,
    hurdle2LP: 0,
    hurdle3LP: 0,
    hurdleLastLP: 0,
    aumUpdate:"Monthly",
    distFrequency: "Monthly",
    managementFee: 2,
    closeExtensions: 0,
    managementFeeCharge: "Committed",
    preferredPerpetuity: "True",
    managementStepDown: "2020/11/1",
    dropDownDate: "2023/10/3",
    fundCloseDate: "2024/2/10",
  },
  {
    owningEntity: "Black Street Management",
    fundName: "Black Street Growth",
    fundID: "17518",
    fundEIN: "444-55-9999",
    accreditedInvestor: "No",
    openEnded: "No",
    typeOfFund: "Waterfall",
    preferredReturn: 9.0,
    hurdleOne: 8,
    hurdleTwo: 10,
    hurdleThree: 14,
    hurdleLast: "14+",
    gpReturn: 0,
    lpReturn: 0,
    hurdle1GP: 0,
    hurdle2GP: 20,
    hurdle3GP: 30,
    hurdleLastGP: 50,
    hurdle1LP: 100,
    hurdle2LP: 80,
    hurdle3LP: 70,
    hurdleLastLP: 50,
    aumUpdate:"Monthly",
    distFrequency: "Quarterly",
    managementFee: 2.25,
    closeExtensions: 0,
    managementFeeCharge: "Committed",
    preferredPerpetuity: "True",
    managementStepDown: "2020/1/1",
    dropDownDate: "2023/8/3",
    fundCloseDate: "2024/7/9",
  },
];

export function* CHANGE_ENTITY_VALUE({ payload: { fieldName, value } }) {
  yield put({
    type: 'fundEntities/SET_STATE',
    payload: {
      [fieldName]: value,
    },
  })
}

export function* RENDER_FUND_INFO({ payload: { fundID } }) {
  const currentFundInfo = fundInfoData[fundID - 1]
  yield put({
    type: 'fundEntities/SET_STATE',
    payload: {
      owningEntity: currentFundInfo.owningEntity,
      fundName: currentFundInfo.fundName,
      fundID: currentFundInfo.fundID,
      fundEIN: currentFundInfo.fundEIN,
      openEnded: currentFundInfo.openEnded,
      accreditedInvestor: currentFundInfo.accreditedInvestor,
      typeOfFund: currentFundInfo.typeOfFund,
      preferredReturn: currentFundInfo.preferredReturn,
      hurdleOne: currentFundInfo.hurdleOne,
      hurdleTwo: currentFundInfo.hurdleTwo,
      hurdleThree: currentFundInfo.hurdleThree,
      hurdleLast: currentFundInfo.hurdleLast,
      gpReturn: currentFundInfo.gpReturn,
      lpReturn: currentFundInfo.lpReturn,
      hurdle1GP: currentFundInfo.hurdle1GP,
      hurdle2GP: currentFundInfo.hurdle2GP,
      hurdle3GP: currentFundInfo.hurdle3GP,
      hurdleLastGP: currentFundInfo.hurdleLastGP,
      hurdle1LP: currentFundInfo.hurdle1LP,
      hurdle2LP: currentFundInfo.hurdle2LP,
      hurdle3LP: currentFundInfo.hurdle3LP,
      hurdleLastLP: currentFundInfo.hurdleLastLP,
      aumUpdate: currentFundInfo.aumUpdate,
      distFrequency: currentFundInfo.distFrequency,
      managementFee: currentFundInfo.managementFee,
      closeExtensions: currentFundInfo.closeExtensions,
      managementFeeCharge: currentFundInfo.managementFeeCharge,
      preferredPerpetuity: currentFundInfo.preferredPerpetuity,
      managementStepDown: currentFundInfo.managementStepDown,
      dropDownDate: currentFundInfo.dropDownDate,
      fundCloseDate: currentFundInfo.fundCloseDate,
    },
  })
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_ENTITY_VALUE, CHANGE_ENTITY_VALUE),
    takeEvery(actions.RENDER_FUND_INFO, RENDER_FUND_INFO),
  ])
}
