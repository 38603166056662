// @daniel, what is this?
import React, { Component } from 'react'
import { Layout } from 'antd'

const { Content } = Layout

class AccountLayout extends Component {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <Content>{children}</Content>
      </Layout>
    )
  }
}

export default AccountLayout
