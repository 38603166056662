const endpoints = {
  communications: (id = '') => `communications/${id}`,
  entityMemberships: (id = '') => `entity_memberships/${id}`,
  userSoftwareAccounts: (id = '') => `/user_software_accounts/${id}`,
  investorInvitationInformation : (id ='') => `entity/verification-list/${id}`,
  investorEntityStatusUpdate: (id = '') => `membership_binding_tasks/${id}`,
  onboardingTasks:   (id = '') => `membership/status/${id}`,
  riaInvestorsSummary: (id = '' ) => `ria/${1}/summary`
}

export default endpoints
