const endpoints = {
  users: (id = '') => `users/${id}`,
  contactAddresses: (id = '') => `contact_addresses/${id}`,
  searchUser: (emailAddress) => `users?emailAddress=${emailAddress}`,
  entities: (id = '') => `entities/${id}`,
  entityMembership: (id = '') => `entity_memberships/${id}`,
  entityFunds: (id = '') => `entity_funds?properties[]=id&properties[]=fundName&properties[]=entity`,
  entityTypes: (id = '') => `entity_types/${id}`,
  userSoftwareAccounts: (id = '') => `user_software_accounts/${id}`,
}

export default endpoints
