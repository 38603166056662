import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import EntityUserContainer from './container/EntityUserContainer'

class EntityUser extends Component {
  render() {
    return (
      <Fragment>
        <EntityUserContainer />
      </Fragment>
    )
  }
}

export default connect(
  state => ({
    user: state.user,
  }),
  dispatch => ({ dispatch }),
)(EntityUser)
