import React, { Component } from 'react'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Field, reduxForm } from 'redux-form'

import ImageUploader from 'components/AppComponents/FormSection/formFields/ImageUploader'
import styles from '../appSettingsStyle.module.scss'

const FORM_NAME = 'collapsedLogoForm'

class CollapsedLogo extends Component {
  state = {
    image: [],
  }

  uploadUserPicture = file => {
    console.log(file)
  }

  render() {
    const { image } = this.state

    const uploadButton = <CloudUploadOutlined className={styles.uploadIcon} />

    return (
      <Field
        name="collapsedLogo"
        component={ImageUploader}
        medias={image || []}
        onRemove={this.deleteUserPicture}
        onUpload={this.uploadUserPicture}
        beforeUpload={this.beforeUserPictureUpload}
        uploadLimit={1}
        accept="image/*"
        uploadButton={uploadButton}
      />
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
})(CollapsedLogo)
