import React from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const { Search } = Input

const SearchField = ({ showPrefixIcon, onChange, placeholder }) => (
  <Search
    onChange={onChange}
    placeholder={placeholder}
    prefix={showPrefixIcon && <SearchOutlined />}
  />
)

export default SearchField
