import React from 'react'
import { Input } from 'antd'

const InputField = ({
  input,
  meta,
  defaultValue,
  saveFieldValue,
  duplicateEmailError,
  ...custom
}) => {
  const { value, onChange } = input
  const { touched, error } = meta
  const hasError = (touched && error !== undefined) || duplicateEmailError


  return (
    <Input
      onChange={onChange}
      value={value || defaultValue || ''}
      className={hasError && 'hasError'}
      {...input}
      {...custom}
    />
  )
}
export default InputField
// the code above needs to be changed, it will break or send crap into db
