import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Progress } from 'antd'
import classNames from 'classnames'
import { getCookies } from 'utils/Cookies'
import Auth0 from 'services/auth0'
import actions from 'redux/user/actions'
import styles from './loaderStyle.module.scss'

const cookies = getCookies()
const auth0 = new Auth0()

class Loader extends Component {
  constructor() {
    super()

    this.state = {
      loadingPercent: 0,
    }

    this.loadingTimer = null
  }

  componentDidMount() {
    this.checkTokenExpiration()

    const {
      user: { id },
    } = this.props

    this.loadingTimer = setInterval(() => {
      const { loadingPercent } = this.state
      this.setState({
        loadingPercent: loadingPercent + 2,
      })
    }, 60)

    this.initializeUser()
    this.initializeInvestorAccount(id)

  }



  componentWillUnmount() {
    clearInterval(this.loadingTimer)
  }

  checkTokenExpiration = () => {
    if (auth0.isTokenExpired()) {
      const { dispatch } = this.props

      dispatch({
        type: 'user/LOGOUT',
      })
    }
  }

  initializeUser = () => {
    const { dispatch } = this.props

    dispatch({
      type: actions.LOAD_CURRENT_ACCOUNT,
      idToken: cookies.id_token,
    })
  }

  progressBar = (loadingPercentage) => (
    <div className={styles.progressBarWrapper}>
      <div className={classNames(styles.progressBarOne, styles.progressBar)}>
        <Progress
          percent={loadingPercentage}
          strokeColor="#316248"
          showInfo={false}
          strokeWidth={15}
        />
      </div>
      <div className={classNames(styles.progressBarTwo, styles.progressBar)}>
        <Progress
          percent={loadingPercentage}
          strokeColor="#009444"
          showInfo={false}
          strokeWidth={15}
        />
      </div>
      <div className={classNames(styles.progressBarThree, styles.progressBar)}>
        <Progress
          percent={loadingPercentage}
          strokeColor="#319159"
          showInfo={false}
          strokeWidth={15}
        />
      </div>
      <div className={classNames(styles.progressBarFour, styles.progressBar)}>
        <Progress
          percent={loadingPercentage}
          strokeColor="#008900"
          showInfo={false}
          strokeWidth={15}
        />
      </div>
      <div className={classNames(styles.progressBarFive, styles.progressBar)}>
        <Progress
          percent={loadingPercentage}
          strokeColor="#009300"
          showInfo={false}
          strokeWidth={15}
        />
      </div>
      <div className={classNames(styles.progressBarSix, styles.progressBar)}>
        <Progress
          percent={loadingPercentage}
          strokeColor="#009444"
          showInfo={false}
          strokeWidth={15}
        />
      </div>
    </div>
  )

  initializeInvestorAccount(userId) {
    const { dispatch } = this.props

    dispatch({
      type: actions.LOAD_INVESTOR_ACCOUNT,
      userId,
    })
  }

  render() {
    const { loadingPercentage } = this.props
    const { loadingPercent } = this.state
    const loading = loadingPercentage === 0 ? loadingPercent : loadingPercentage

    return (
      <div className={styles.loadingBg}>
        <div className={styles.loaderWrapper}>
          <>
            <img
              src='/resources/images/brand/prospera/logo/prospera-main-logo-horizontal-small.png'
              alt='Prospera Logo'
              className={styles.prosperaLogoImage}
            />
          </>
          <div className={styles.main}>
            <div className={styles.loader}>{this.progressBar(loading)}</div>
            <p className={styles.loadingMessage}>One moment. We are loading your dashboard.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({ loadingPercentage: state.user.loadingPercentage, user: state.user }),
  (dispatch) => ({ dispatch }),
)(Loader)
