import { takeEvery, all, call, put } from 'redux-saga/effects'
import Api from 'services/api/Api'
import actions from './actions'
import getEndPoints from '../endpoints'
import utility from '../../utils/uitility'

const api = new Api()
const { noId } = utility

export function* CHANGE_LEDGER_MODAL_STATUS({ payload: { value } }) {
  yield put({
    type: 'general/SET_STATE',
    payload: {
      ledgerModalStatus: value,
    },
  })
}

export function* SAVE_RESOURCE(endpoint, data, formsection) {
  if (!data) {
    return {}
  }

  let resource
  try {
    if (data.id) {
      resource = yield call(api.patch, getEndPoints(endpoint, data.id), noId(data))
    } else {
      resource = yield call(api.post, getEndPoints(endpoint), noId(data))
    }
    return resource.data[0]
  } catch (error) {
    if (formsection) {
      throw {
        [formsection]: error.response.data,
      }
    } else {
      throw error.response.data
    }
  }
}

export function* handleRollback(endpoint) {
  yield call(DELETE_RESOURCE, endpoint)
}

export function* DELETE_RESOURCE(endpoint) {
  try {
    return yield call(api.delete, endpoint)
  } catch (error) {
    throw error
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SAVE_RESOURCE, SAVE_RESOURCE)])
  yield all([takeEvery(actions.CHANGE_LEDGER_MODAL_STATUS, CHANGE_LEDGER_MODAL_STATUS)])
}
