import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const { emptyValidator } = Validators

const CityField = ({ placeholder,check,isCityRequired }) => (
  <Field
    disabled={check}
    name="city"
    placeholder={placeholder}
    component={inputType}
    validate={isCityRequired ===true ? [emptyValidator]:""}
  />
)

export default CityField
