import React from 'react'
import { Checkbox, Form } from 'antd'
import { FormSection } from 'redux-form'
import styles from '../entityUserStyle.module.scss'

const EntityUserPermissions = () => (
  <FormSection name="EntityUserPermissions">
    <div className={styles.investorNameInputWrapper}>
      <Form.Item label="Special Permissions">
        <div className={styles.firstNameFieldWidth}>
          <Checkbox disabled>Manage Passwords</Checkbox>
          <br />
          <Checkbox disabled>Manage Process</Checkbox>
          <br />
          <Checkbox disabled>Manage Settings</Checkbox>
          <br />
          <Checkbox disabled>Statements Permission</Checkbox>
          <br />
          <Checkbox disabled>Document Edit</Checkbox>
          <br />
          <Checkbox disabled>File Edit and Delete Permission</Checkbox>
          <br />
          <Checkbox disabled>Dropbox Sync</Checkbox>
        </div>
      </Form.Item>
      <Form.Item label=" ">
        <div className={styles.lastNameFieldWidth}>
          <Checkbox disabled>CashFlow</Checkbox>
          <br />
          <Checkbox disabled>Grant Access to Loans</Checkbox>
          <br />
          <Checkbox disabled>LoanFlow Servicing</Checkbox>
          <br />
          <Checkbox disabled>Investor Flow</Checkbox>
          <br />
          <Checkbox disabled>Service Soft</Checkbox>
          <br />
          <Checkbox disabled>Investor Accounting/Creation</Checkbox>
        </div>
      </Form.Item>
    </div>
  </FormSection>
)

export default EntityUserPermissions
