import React from 'react'
import { Checkbox, Form, Select, Row, Col } from 'antd'
import { Field, FormSection } from 'redux-form'

import SelectField from 'components/AppComponents/FormSection/formFields/SelectField'
import InputFieldComponent from 'components/AppComponents/FormSection/component/InputFieldComponent'

import styles from '../entityUserStyle.module.scss'

const { Option } = Select

const handleBlur = event => {
  event.preventDefault()
}

const EntityTypeInfo = () => (
  <FormSection name="EntityUserInfo">
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Form.Item style={{width: "100%"}} label="Type of User">
          <SelectField name="userType" placeholder="type" handleBlur={handleBlur}>
            <Option value={1}>Staff</Option>
            <Option value={2}>Admin</Option>
            <Option value={3}>Investor</Option>
            <Option value={4}>Fund Manager</Option>
          </SelectField>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Choose an Entity">
          <SelectField name="userEntity" placeholder="entity name" handleBlur={handleBlur}>
            <Option value={1}>Prospera Growth Fund</Option>
            <Option value={2}>Cache Private Capital</Option>
          </SelectField>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Leadership?">
          <SelectField name="leadership" placeholder="position" handleBlur={handleBlur}>
            <Option value={1}>C-Level</Option>
            <Option value={2}>VP-Level</Option>
            <Option value={3}>Director-Level</Option>
            <Option value={4}>Staff</Option>
          </SelectField>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 0]}>
      <Col span={8}>
        <Form.Item label="Department">
          <div className="mailingAddressFieldWidth">
            <Field name="department" placeholder="department" component={InputFieldComponent} />
          </div>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Department">
          <div className="mailingAddressFieldWidth">
            <Field name="department" placeholder="department" component={InputFieldComponent} />
          </div>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item label="Password">
          <div className={styles.firstNameFieldWidth}>
            <Field name="password" placeholder="password" component={InputFieldComponent} />
          </div>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item>
          <div style={{ display: 'flex' }}>
            <div className="bold">Bio?&nbsp;&nbsp;&nbsp;</div>
            <Checkbox>Yes</Checkbox>
          </div>
        </Form.Item>
      </Col>
    </Row>
  </FormSection>
)

export default EntityTypeInfo
