export const UPDATE_INVESTOR_STATUS                   = 'investorVerification/UPDATE_INVESTOR_STATUS'
export const UPDATE_INVESTOR_STATUS_SUCCESS           = 'investorVerification/UPDATE_INVESTOR_STATUS_SUCCESS'
export const UPDATE_INVESTOR_STATUS_FAILED            = 'investorVerification/UPDATE_INVESTOR_STATUS_FAILED'

export const GET_ACTIVE_INVITATIONS                   = 'investorVerification/GET_ACTIVE_INVITATIONS'
export const GET_ACTIVE_INVITATIONS_SUCCESS           = 'investorVerification/GET_ACTIVE_INVITATIONS_SUCCESS'
export const GET_ACTIVE_INVITATIONS_FAILED            = 'investorVerification/GET_ACTIVE_INVITATIONS_FAILED'

export const GET_ONBOARDING_TASKS                     = 'investorVerification/GET_ONBOARDING_TASKS'
export const GET_ONBOARDING_TASKS_SUCCESS             = 'investorVerification/GET_ONBOARDING_TASKS_SUCCESS'
export const GET_ONBOARDING_TASKS_FAILED              = 'investorVerification/GET_ONBOARDING_TASKS_FAILED'
