import React from 'react'
import SelectField from 'components/AppComponents/FormSection/formFields/SelectField'
import { Select } from 'antd'
import States from 'utils/states.json'

const { Option } = Select

const handleBlur = (event) => {
  event.preventDefault()
}

const getStates = () => {
  const states = States.slice()
  return states.map((state) => (
    <Option key={state.abbreviation} value={state.abbreviation}>
      {state.abbreviation} - {state.name}
    </Option>
  ))
}

const StateField = ({ placeholder, check }) => (
  <SelectField
    dis={check}
    name="state"
    showSearch
    handleBlur={handleBlur}
    placeholder={placeholder}
    optionFilterProp="children"
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {getStates()}
  </SelectField>
)

export default StateField
