import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
import { setCookie, removeCookies, getCookies } from 'utils/Cookies'
// import { notification } from 'antd'
// import _ from 'lodash'
// const cookies = getCookies()

export default class Auth {
  userProfile

  tokenRenewalTimeout

  requestedScopes = 'openid profile'

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_TENANT_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH_CALLBACK_URL,
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    responseType: 'token id_token',
    scope: this.requestedScopes,
  })

  auth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_TENANT_DOMAIN,
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
  })

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.loginPasswordLess = this.loginPasswordLess.bind(this)
    Auth.isAuthenticated = Auth.isAuthenticated.bind(this)
    Auth.getAccessToken = Auth.getAccessToken.bind(this)
    Auth.findScope = Auth.findScope.bind(this)
    this.scheduleRenewal = this.scheduleRenewal.bind(this)
    this.handleCallback = this.handleCallback.bind(this)
    this.passwordLessCallback = this.passwordLessCallback.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  handleCallback() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)

        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        resolve(authResult)
        return authResult
      })
    })
  }

  passwordLessCallback() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)

        if (!authResult || !authResult.access_token) {
          return reject(err)
        }
        resolve(authResult)
        console.log('authResult',authResult)
        return authResult
      })
    })
  }

  login(username, password) {
    const self = this
    return new Promise((resolve, reject) => {
      self.auth0.client.login(
        { realm: process.env.REACT_APP_AUTH_DB_CONNECTION_NAME, username, password },
        (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            self.setSession(authResult)
            // setCookie('role', 'admin')
            resolve(authResult)
          } else {
            // check the old db for an account
            self.auth0.client.login(
              { realm: process.env.REACT_APP_AUTH_IDEALSUITE_CONNECTION_NAME, username, password },
              (err2, authResult2) => {
                if (authResult2 && authResult2.accessToken && authResult2.idToken) {
                  // need to migrate the user to the new database

                  const metaNamespace = `${process.env.REACT_APP_AUTH0_NAMESPACE}/user_metadata`;
                  console.log('metaNamespace', metaNamespace)
                  const userMetadata = jwtDecode(authResult2.idToken)[
                    metaNamespace
                  ]
                  if (userMetadata.status === -1) {
                    reject({
                      description:
                        "Your old account has been disabled. If you've migrated, please login with your new password. Contact support if you believe this is in error.",
                    })
                  }
                  if (userMetadata.status === 0) {
                    reject({
                      description: 'Your account has not been set up yet. Please contact client support.',
                    })
                  }
                  setCookie('migrateProfile', JSON.stringify(userMetadata))
                  resolve({
                    migrate: true,
                    migrateProfile: userMetadata,
                  })
                } else {
                  if (err2.description == null) {
                    reject(err2.original)
                  }
                  reject(err2)
                }
              },
            )
          }
        },
      )
    })
  }


  loginPasswordLess(){
    const self = this;

    const url = window.location.href;
    const params = url.split('#')[1];
    const result = params.split('&').reduce( (res, item) =>  {
      const parts = item.split('=');
      // eslint-disable-next-line prefer-destructuring
      res[parts[0]] = parts[1];
      return res;
    }, {});

    const accessToken = result.access_token;
    const bearerToken = result.id_token;

    console.log('tokenHash', accessToken);
    console.log('bearerToken', bearerToken);

    return new Promise((resolve, reject) => {

      if(bearerToken){
        self.setPwlSession(accessToken,bearerToken)
        resolve (bearerToken, accessToken)
      }else{
        reject (reject)
      }
    })
  }


  loginWithGoogle = () => {
    const self = this
    return new Promise((resolve, reject) => {
      self.auth0.authorize(
        {
          connection: 'google-oauth2',
          scope: 'openid email profile',
        },
        (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            // setCookie('role', 'admin')
            self.setSession(authResult)
            resolve(authResult)
          } else {
            reject(err)
          }
        },
      )
    })
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
    setCookie('access_token', authResult.accessToken)
    setCookie('id_token', authResult.idToken)
    setCookie('expires_at', expiresAt)
    // schedule a token renewal
    this.scheduleRenewal()
  }

  setPwlSession(accessToken, bearerToken) {
    const expiresAt = JSON.stringify(7200 * 1000 + new Date().getTime())
    setCookie('access_token', bearerToken)
    setCookie('id_token', bearerToken)
    setCookie('expires_at', expiresAt)
    // schedule a token renewal
    this.scheduleRenewal()
  }

  renewPasswordLessToken(){
    this.auth.checkSession({}, (err, result) => {
      if (err) {
        console.log(err)
      } else {
        console.log('tokenRefresh',result)
        this.setSession(result)
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  setPasswordLessSession(authResult){
    const expiresAt = JSON.stringify(7200 * 1000 + new Date().getTime())
    setCookie('access_token', authResult)
    setCookie('expires_at', expiresAt)
    // schedule a token renewal
    // this.scheduleRenewal()
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    const delay = expiresAt - Date.now()
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken()
      }, delay)
    }
  }

  renewToken() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err)
      } else {
        this.setSession(result)
        this.setPwlSession(result)
      }
    })
  }

  static getAccessToken() {
    const cookies = getCookies()
    const accessToken = cookies.access_token
    if (!accessToken) {
      return null
    }
    return accessToken
  }

  static getProfileFromIdToken(idToken) {
    return jwtDecode(idToken)
  }

  isTokenExpired = () => {
    const accessToken = Auth.getAccessToken()
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken)
      const currentTime = new Date().getTime()
      const expTime = decodedToken.exp

      return currentTime * 0.001 > expTime
    }
    return false
  }

  static isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }

  getCurrentUserId = () => {
    const cookies = getCookies()
    const accessToken = cookies.access_token
    const { sub } = jwtDecode(accessToken)
    const extractId = sub.split('|')
    return extractId[1]
  }

  static userHasScope(requiredScope, accessTokens) {
    const cookies = getCookies()

    const accessToken = !cookies.access_token ? accessTokens : cookies.access_token
    if (!accessToken) {
      return false
    }

    const keyPermission = 'http://s2n.permissions'

    const { [keyPermission]: permissions } = jwtDecode(accessToken)

    const grantedScopes = permissions || []
    let grantedScope = []
    if (Array.isArray(requiredScope)) {
      const foundScopes = requiredScope.map((scope) => Auth.findScope(grantedScopes, scope))

      // Removes false value from array i.e undefined, null
      grantedScope = foundScopes.filter(Boolean)
      return {
        hasScope: grantedScope.length > 0,
        grantedScope: grantedScope.pop(),
      }
    }

    grantedScope = Auth.findScope(grantedScopes, requiredScope)
    return {
      hasScope: grantedScope !== undefined,
      grantedScope,
    }
  }

  static findScope(grantedScopes, scope) {
    return grantedScopes.find((grantedScope) => {
      const pattern = new RegExp(`^${scope}`)
      return pattern.test(grantedScope)
    })
  }

  static userHasRole(requiredRoles, accessTokens) {
    // console.log('requireRoles',requiredRoles)
    // console.log('accessTokens',accessTokens)
    const cookies = getCookies()

    const accessToken = !cookies.access_token ? accessTokens : cookies.access_token
    // console.log('accessToken',accessToken)
    if (!accessToken) {
      return false
    }
    // const authRoles =  process.env.REACT_APP_AUTH0_ROLES
    const authRoles = `${process.env.REACT_APP_AUTH0_NAMESPACE}/roles`;


    const { [authRoles]: roles } = jwtDecode(accessToken)
    const rolesArr = roles
    let grantedRole = []
    if (Array.isArray(requiredRoles)) {
      const foundRole = requiredRoles.map((requiredRole) => Auth.findRole(rolesArr, requiredRole))

      // Removes falsey value from array i.e undefined, null
      grantedRole = foundRole.filter(Boolean)

      return {
        hasRole: grantedRole.length > 0,
        grantedRole: grantedRole.pop(),
      }
    }
// console.log('rolesArr',rolesArr.includes(requiredRoles))
// console.log('grantedRoles',requiredRoles)
    return {
      hasRole: rolesArr.includes(requiredRoles),
      grantedRole: requiredRoles,
    }
  }

  static findRole(roles, requiredRole) {
    return roles.find((role) => {
      const pattern = new RegExp(`^${role}`)
      return pattern.test(requiredRole)
    })
  }

  hasWebLoginAccess = () => {
    const accessToken = localStorage.getItem('access_token')
    const { scope } = jwtDecode(accessToken)
    const regExp = new RegExp('login:web')
    return regExp.test(scope)
  }

  logout() {
    // Clear access token and ID token from local storage
    removeCookies('access_token')
    removeCookies('id_token')
    removeCookies('expires_at')
    // removeCookies('role')
    this.userProfile = null
    clearTimeout(this.tokenRenewalTimeout)
    // TODO REMOVE THIS AFTER ISSUES WITH INITIAL DATA IS FIXED
    // window.location.replace('/')
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      // console.log('resetting password...')

      this.auth0.changePassword(
        {
          email,
          connection: process.env.REACT_APP_AUTH_DB_CONNECTION_NAME`user_metadata`,
        },
        (err, result) => {
          if (err) {
            return reject(err)
          }
          return resolve({ success: true, message: result })
        },
      )
    })
  }
}
