import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import InputFieldComponent from '../component/InputFieldComponent'

const { emptyValidator } = Validators

const IncorporationStateField = () => (
  <Field name="name" component={InputFieldComponent} validate={[emptyValidator]} />
)

export default IncorporationStateField
