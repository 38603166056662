import React from 'react'
import { Field } from 'redux-form'

import Validators from 'utils/Validators'
import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'

const { emptyValidator, nameValidator } = Validators
const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const FirstNameField = ( { onBlur,check } ) => (
  <Field
    disabled={check}
    name="firstName"
    placeholder="First Name"
    maxLength={25}
    component={inputType}
    validate={[emptyValidator, nameValidator]}
    onBlur={onBlur}
  />
)

export default FirstNameField
