export default function getMessageMenu() {
  return [
    {
      title: 'Message menu 1',
      key: 'message-menu-1',
      permissionRequired: 'read:investor_dashboard',
    },
    {
      title: 'Message menu 2',
      key: 'message-menu-2',
      permissionRequired: 'read:investor_dashboard',
    },
    {
      title: 'Message menu 3',
      key: 'message-menu-3',
      permissionRequired: 'read:investor_dashboard',
    },
    {
      title: 'Message menu 4',
      key: 'message-menu-4',
      permissionRequired: 'read:investor_dashboard',
    },
  ]
}
