import { all, takeEvery, put } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import { history, store as reduxStore } from 'index'
import actions from './actions'

export function* CHANGE_SETTING({ payload: { setting, value } }) {
  yield store.set(`app.settings.${setting}`, value)
  yield put({
    type: 'settings/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SETUP() {
  // load settings from url on app load
  const changeSettings = search => {
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    Object.keys(query).forEach(key => {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: key,
          value: query[key] === 'true',
        },
      })
    })
  }
  yield changeSettings(history.location.search)
  yield history.listen(params => {
    const { search } = params
    changeSettings(search)
  })

  // detect isSidebarView setting on app load and window resize
  const isSidebarView = (load = false) => {
    const currentState = global.window.innerWidth < 768
    const prevState = store.get('app.settings.isSidebarView')
    if (currentState !== prevState || load) {
      reduxStore.dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarView',
          value: currentState,
        },
      })
    }
  }
  yield isSidebarView(true)
  yield window.addEventListener('resize', () => {
    isSidebarView()
  })
}

export function* SET_SELECTION_COLOR({ payload: { color } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#primaryColor')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --kit-color-primary: ${color};}`)
    styleEl.setAttribute('id', 'primaryColor')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'selectionColor',
      value: color,
    },
  })
}

export function* SET_APPLICATION_COLOR({ payload: { color } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#secondaryColor')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --kit-color-secondary: ${color};}`)
    styleEl.setAttribute('id', 'secondaryColor')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'applicationColor',
      value: color,
    },
  })
}

export function* SET_HOVER_COLOR({ payload: { color } }) {
  // document.documentElement.style.setProperty('--secondary', color)
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'hoverColor',
      value: color,
    },
  })
}

export function* SET_TOP_OVERLAY({ payload: { color } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#bgOverlayTopColor')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --background-overlay-top: ${color};}`)
    styleEl.setAttribute('id', 'bgOverlayTopColor')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'backgroundOverlayTop',
      value: color,
    },
  })
  // document.documentElement.style.setProperty('--background-SvgEmblem-top', color)

  // yield reduxStore.dispatch({
  //   type: 'settings/CHANGE_SETTING',
  //   payload: {
  //     setting: 'backgroundOverlayTop',
  //     value: color,
  //   },
  // })
}

export function* SET_BOTTOM_OVERLAY({ payload: { color } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#bgOverlayBottomColor')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --background-overlay-bottom: ${color};}`)
    styleEl.setAttribute('id', 'bgOverlayBottomColor')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'backgroundOverlayBottom',
      value: color,
    },
  })
  /* 
  document.documentElement.style.setProperty('--background-SvgEmblem-bottom', color)

  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'backgroundOverlayBottom',
      value: color,
    },
  }) */
}

export function* SET_BACKGROUND_OPACITY({ payload: { opacity } }) {
  const addStyles = () => {
    const styleElement = document.querySelector('#backgroundOpacity')
    if (styleElement) {
      styleElement.remove()
    }
    const body = document.querySelector('body')
    const styleEl = document.createElement('style')
    const css = document.createTextNode(`:root { --background-opacity: ${opacity};}`)
    styleEl.setAttribute('id', 'backgroundOpacity')
    styleEl.appendChild(css)
    body.appendChild(styleEl)
  }

  yield addStyles()
  yield reduxStore.dispatch({
    type: 'settings/CHANGE_SETTING',
    payload: {
      setting: 'backgroundOpacity',
      value: opacity,
    },
  })
  // document.documentElement.style.setProperty('--background-opacity', opacity)

  // yield reduxStore.dispatch({
  //   type: 'settings/CHANGE_SETTING',
  //   payload: {
  //     setting: 'backgroundOpacity',
  //     value: opacity,
  //   },
  // })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHANGE_SETTING, CHANGE_SETTING),
    takeEvery(actions.SET_SELECTION_COLOR, SET_SELECTION_COLOR),
    takeEvery(actions.SET_APPLICATION_COLOR, SET_APPLICATION_COLOR),
    takeEvery(actions.SET_HOVER_COLOR, SET_HOVER_COLOR),
    takeEvery(actions.SET_TOP_OVERLAY, SET_TOP_OVERLAY),
    takeEvery(actions.SET_BOTTOM_OVERLAY, SET_BOTTOM_OVERLAY),
    takeEvery(actions.SET_BACKGROUND_OPACITY, SET_BACKGROUND_OPACITY),
    SETUP(), // run once on app load to init listeners
  ])
}
