import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Table, Button, Spin, Tooltip /* Tag */ } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  PlusCircleFilled,
  EyeOutlined,
  EyeFilled,
} from '@ant-design/icons'
import staticUser from '../internalUser.json'

// scss actions from 'app/redux/userprofile/actions'

import styles from '../internalUserStyle.module.scss'
import settingsStyles from '../../settingStyle.module.scss'

class InternalUser extends Component {
  constructor() {
    super()
    this.state = {
      //  users: {},
      loading: false,
      //   pagination: {},
    }
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        //  render: (value, row) => <div>{`${row.person.firstName} ${row.person.lastName}`}</div>,
      },
      // {
      //   title: 'Address',
      //   dataIndex: 'address',
      //   key: 'address',
      // },
      // {
      //   title: 'Phone',
      //   dataIndex: 'phone',
      //   key: 'phone',
      // },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      // {
      //   title: 'City',
      //   dataIndex: 'city',
      //   key: 'city',
      // },
      // {
      //   title: 'State',
      //   dataIndex: 'state',
      //   key: 'state',
      // },
      // {
      //   title: 'Zip',
      //   dataIndex: 'postalCode',
      //   key: 'zip',
      // },
      {
        title: 'Role',
        dataIndex: 'status',
        key: 'status',
        /*      render: (role, row) => {
          const { accountMemberships } = row
          const tags = accountMemberships.reduce(
            (accumulator, { accountMembershipType }) => [
              ...accumulator,
              { id: accountMembershipType.id, name: accountMembershipType.name },
            ],
            [],
          )
          return tags.map(tag => (
            <div key={tag}>
              <Tag className={`${core[tag.id.toLowerCase()]}`}>{tag.name}</Tag>
            </div>
          ))
        }, */
      },
      {
        title: 'Last Login',
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        render: (role, row) => {
          const { lastLogin } = row

          if (lastLogin) {
            const { date } = lastLogin
            const UtahTime = moment
              .utc(date)
              .utcOffset(-7)
              .format('YYYY-MM-DD h:mm a')

            return UtahTime
          }

          return 'N/A'
        },
      },
      {
        title: 'Action',
        render: record => (
          <Fragment>
            <Tooltip title="Delete User">
              <Button shape="circle" className={styles.delete}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Edit User">
              <Button shape="circle" onClick={() => this.editThisUser(record)}>
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Reset Password">
              <Button shape="circle">
                <LockOutlined />
              </Button>
            </Tooltip>
          </Fragment>
        ),
      },
      {
        title: '',
        dataIndex: 'visible',
        key: 'visible',
        render: visible => {
          if (visible) {
            return (
              <div className={styles.centered}>
                <EyeFilled />
              </div>
            )
          }
          return (
            <div className={styles.centered}>
              <EyeOutlined />
            </div>
          )
        },
      },
    ]
  }

  componentDidMount() {
    //  this.fetchUserList()
  }

  handleClickAddNewUser = () => {
    const { history } = this.props
    history.push('/setting/users/create')
  }
  /*
  fetchUserList = (params = {}) => {
    const {
      dispatch,
      user: { accountId },
    } = this.props
    const { pagination } = this.state
    this.setState({ loading: true })
    return new Promise((resolve, reject) => {
      dispatch({
        type: actions.FETCH_INTERNAL_USER_LIST,
        data: {
          'filter[account][id]': accountId,
          pageSize: 10,
          ...params,
        },
        resolve,
        reject,
      })
    })
      .then(response => {
        const nextPagination = { ...pagination }
        nextPagination.total = response && response.data && response.data[0].total
        this.setState({
          users: response && response.data,
          loading: false,
          pagination: nextPagination,
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }
*/

  handleTableChange = (paginations, filters, sorter) => {
    const { pagination } = this.state
    const pager = { ...pagination }
    pager.current = paginations.current
    this.setState({
      pagination: pager,
    })
    this.fetchUserList({
      pageSize: paginations.pageSize,
      page: paginations.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    })
  }

  editThisUser = record => {
    const { id: userId } = record
    const { history } = this.props
    history.push(`/setting/users/${userId}/edit`)
  }

  render() {
    const rowSelection = {
      onChange: () => {},
      onSelect: () => {},
      onSelectAll: () => {},
    }
    const { loading /* users, pagination */ } = this.state

    return (
      <div className={styles.internalUserWrapper}>
        <Spin spinning={loading}>
          <div className={styles.leadTable}>
            <Table
              rowKey="id"
              size="small"
              columns={this.columns}
              dataSource={staticUser} // {(users[0] && users[0].results) || []}
              rowSelection={rowSelection}
              bordered
              // pagination={pagination}
              onChange={this.handleTableChange}
            />
            <button type="submit" className={settingsStyles.btngray}>
              <PlusCircleFilled /> ADD NEW ENTITY USER
            </button>
            <br />
            <br />
          </div>
        </Spin>
      </div>
    )
  }
}

export default connect(
  state => ({
    user: state.user,
  }),
  dispatch => ({ dispatch }),
)(InternalUser)
