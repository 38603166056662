import { all, takeEvery, call, put } from 'redux-saga/effects'
import actions from './actions'
import Api from '../../services/api/Api'

const api = new Api()

export function* FETCH_STATEMENTS_OVERVIEW({ resolve, reject, fundMemberSettingsId }) {
  try {
    const response = yield call(api.get, `/overview/${fundMemberSettingsId}`)
    const responseData = response.data

    const newData = responseData.reverse();

    yield call(resolve, newData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_ACCOUNT_GROWTH({ resolve, reject, fundMemberSettingsId }) {
  try {
    const accountGrowthData = []

    const response = yield call(api.get, `/overview/${fundMemberSettingsId}`)
    const responseData = response.data

    for(let i=0; i < responseData.length; i+=1){
      const temp = [responseData[i].date, 'CPC',responseData[i].endingBalance]
      accountGrowthData.push(temp)
    }
    yield call(resolve, accountGrowthData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_STATEMENTS_DETAIL({ resolve, reject, fundMemberSettingsId, month, year }) {
  try {
    const response = yield call(api.get, `/detail/${fundMemberSettingsId}?for=${month}%2F${year}`)
    const responseData = response.data

    yield call(resolve, responseData)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SET_CURRENT_ACCOUNT({ resolve, reject, account }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      currentAccount: account
    },
  })

  try {
    yield call(resolve, "success")
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_CURRENT_ACCOUNT, SET_CURRENT_ACCOUNT),
    takeEvery(actions.FETCH_STATEMENTS_OVERVIEW, FETCH_STATEMENTS_OVERVIEW),
    takeEvery(actions.FETCH_ACCOUNT_GROWTH, FETCH_ACCOUNT_GROWTH),
    takeEvery(actions.FETCH_STATEMENTS_DETAIL, FETCH_STATEMENTS_DETAIL)
  ])
}
