import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'

import Auth0 from 'services/auth0'
import Loader from 'layouts/Loader'

const auth0 = new Auth0()

class Callback extends Component {
  async componentDidMount() {
    const { dispatch } = this.props
    auth0
      .handleCallback()
      .then(async (authResult) => {
        const accessToken = (await jwtDecode(authResult.accessToken)) || []
        const accountId = accessToken['http://s2n.account_id']
        if (accountId) {
          auth0.setSession(authResult)
          await dispatch({
            type: 'user/LOAD_CURRENT_ACCOUNT',
            idToken: authResult.idToken,
            accessToken: authResult.accessToken,
            // role: 'admin',
          })
          dispatch(push('/home'))
        } else {
          dispatch({
            type: 'user/SET_GOOGLE_INFO',
            payload: authResult.idTokenPayload,
          })
          dispatch(push('/user/signup'))
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  render() {
    return <Loader />
  }
}

export default connect(null, (dispatch) => ({ dispatch }))(Callback)
