import { takeEvery, all, call, put } from 'redux-saga/effects'
import moment from 'moment'
import Api from 'services/api/Api'
import { push } from 'connected-react-router'
import { notification } from 'antd'
import getEndPoints from '../endpoints'
import actions from './actions'
import * as type from './types'

const api = new Api()

export function* SUBMIT_IO_INVITATION_REDUCERS({
  resolve,
  reject,
  formValues
}) {
  yield put({
    type: type.SUBMIT_TO_INVITATION_SUCCESS,
    payload: {
      formType: formValues.contactWish,
      fund: formValues.fund,
      accountType: formValues.account.id,
      expectedContribution: formValues.expectedContribution,
      isExistingInvestor: formValues.investorName.isExistingInvestor,
      firstName: formValues.contactInformation.firstName,
      middleInitial: formValues.contactInformation.middleInitial,
      lastName: formValues.contactInformation.lastName,
      emailAddress: formValues.contactInformation.email,
      phoneNumber: formValues.contactInformation.phoneNumber,
      sms: formValues.contactInformation.isMobile,
      addressLine1: formValues.contactInformation.streetOne,
      addressLine2: formValues.contactInformation.streetTwo,
      zipCode: formValues.contactInformation.zip,
      state: formValues.contactInformation.state,
      city: formValues.contactInformation.city,
      country: formValues.contactInformation.country
    },
  })

  try {
    yield call(resolve, "success")
  } catch (error) {console.log(error)
    yield call(reject, error)
  }
}

// eslint-disable-next-line require-yield
export function* sendInvitation(payload) {
  const { isExistingInvestor } = payload.investoronboarding
  const state = payload.investoronboarding.state === "" ? undefined:`/api/geo_states/${payload.investoronboarding.state}`
  const contactAddresses = {
    isPrimary: true,
    "contactAddress":{
      address1: payload.investoronboarding.addressLine1,
      address2: payload.investoronboarding.addressLine2 ? payload.investoronboarding.addressLine2 : "",
      city: payload.investoronboarding.city,
      state ,
      country: '/api/geo_countries/USA',
      postalCode: payload.investoronboarding.zipCode,
    },
    entityContactAddressType: '/api/entity_contact_address_types/physical'
  }
  const users = {
    id: payload.userId,
    emailAddress: payload.investoronboarding.emailAddress,
    userStatusType: '/api/user_status_types/active',
    person: {
      // '@id': `/api/people/${personId}`,
      firstName: payload.investoronboarding.firstName,
      middleName: payload.investoronboarding.middleInitial ? payload.investoronboarding.middleInitial : null,
      lastName: payload.investoronboarding.lastName,
      entity: {
        // '@id': `/api/entities/${payload.entityId}`,
        entityType: `/api/entity_types/${payload.investoronboarding.accountType}`,
        entityContactAddresses: [contactAddresses],
        entityContactPhones: [
          {
            // '@id': `/api/entity_contact_phones/${entityContactPhonesId}`,
            isPrimary: true,
            phoneNumber: payload.investoronboarding.phoneNumber,
            isSms: payload.investoronboarding.isSms === 'yes',
            phoneNumberOption: 1,
            phoneNumberFormat: 'hello',
            contactPhoneType: '/api/contact_phone_types/mobile'
          },
        ],
        entityContactEmails: [
          {
            // '@id': `/api/entity_contact_emails/${entityContactEmailsId}`,
            emailAddress: payload.investoronboarding.emailAddress,
          },
        ],
      },
    },
  }

  let entityFund = payload.investoronboarding

  if (users.person.middleName === null){
    users.person.entity.name = users.person.firstName.concat(" ", users.person.lastName)
  } else {
    users.person.entity.name = users.person.firstName.concat(" ", users.person.middleName, ". ", users.person.lastName)
  }

  try {
    let method
    let contactEndPoint
    let userEndPoint
    let entityMembershipEndPoint

    if (isExistingInvestor === 'yes') {
      method = api.post

      /**
       * Step 1, make a entityMembership by passing the entityFund object to entityMembership endpoint.
       */
      const { id: entityMembershipsID } = entityFund
      entityMembershipEndPoint = getEndPoints('entityMembership', entityMembershipsID)
      entityFund = {
        entityMembershipType: 'api/entity_membership_types/investor_accredited',
        entityFund:`/api/entity_funds/${entityFund.fund.id}`,
        entity: `/api/entities/${entityFund.fund.entity.id}`,
        memberEntity: `/api/entities/${payload.entityId}`,
      }
      const entityMembershipResponse = yield call(method, entityMembershipEndPoint, entityFund)
      console.log('entityMembershipResponse',entityMembershipResponse)

      // Post Investor Data
      const investorAccountData = {
        entityMembership: `/api/entity_memberships/${entityMembershipResponse.data.id}`,  // grabs the latest entityMembershipId
        contribution:  parseFloat(payload.investoronboarding.expectedContribution)
      }
      yield call(api.post, '/fund_member_settings', investorAccountData)


      /**
       *
       * Step 2, send email invite via sendgrid
       */
      const commData = {
        sender: `/api/users/${payload.investorId}`,
        receiver: `/api/users/${users.id}`,
        dateSent: moment().format("YYYY-MM-DDThh:mm:ss"),
        expiredDate: moment().add(336 , 'h').format("YYYY-MM-DDThh:mm:ss"),
        communicationType: '/api/communication_types/software-invitation',
        // todo this needs to come from the api.
        // this is the invitation template
        template: '/api/templates/d-d7af63628b614b1c88bd9813e70fc198',
        var: {
          investorName: users.person.entity.name,

        }
      }
      // send sendgrid email
      yield call(api.post, '/communications', commData)


      yield put({
        type: type.SUBMIT_TO_INVITATION_SUCCESS,
        payload: {
          entityId: payload.entityId,
          userId: users.id,
          // emailAddress: userResponse.data.emailAddress,
          entityMembershipId: entityMembershipResponse.data.id,
          isExistingInvestor: 'yes',
        },
      })
      notification.success({
        message: 'Invite Successfully Sent!',
        description: 'You have successfully sent an invite!',
      })
      yield put (push('/ima/crm/investor'))
    }
    if (isExistingInvestor === 'no') {

      entityMembershipEndPoint = getEndPoints('entityMembership')
      userEndPoint = getEndPoints('user')
      method = api.post

      // create/update user in Prospera
      const userResponse = yield call(method, userEndPoint, users)
      const { data: { person: { id: userID, entity: { id: entityID }, }, }, } = userResponse

      // Creat oAuth User
      const authData = {
         email: payload.investoronboarding.emailAddress,
       }

      const authResponse = yield call(api.post, '/a0u-p/create', authData)
      console.log(authResponse)
      // connect auth0 to user account and Create a membershipEntity
      const userData = {
        sub: authResponse.data.id
      }
      yield call(api.patch, `/users/${userID}`, userData )
      entityFund = {
        entityMembershipType: 'api/entity_membership_types/investor_accredited',
        entityFund:`/api/entity_funds/${entityFund.fund.id}`,
        entity: `/api/entities/${entityFund.fund.entity.id}`,
        memberEntity: `/api/entities/${entityID}`,
      }
      const entityMembershipResponse = yield call(method, entityMembershipEndPoint, entityFund)

      // Post Investor Data
      const investorAccountData = {
        entityMembership: `/api/entity_memberships/${entityMembershipResponse.data.id}`,  // grabs the latest entityMembershipId
        contribution: parseFloat(payload.investoronboarding.expectedContribution)
      }
      yield call(api.post, '/fund_member_settings', investorAccountData)

      // send sendgrid email
      // const commData = {
      //  sender: "support@s2n.solutions",
      //  user: `/api/users/${userID}`,
      //  dateSent: moment().format("YYYY-MM-DDThh:mm:ss"),
      //  expiredDate: moment().add(336 , 'h').format("YYYY-MM-DDThh:mm:ss"),
      //  communicationType: '/api/communication_types/software-invitation',
      //  // todo this needs to come from the api.
      //  // this is the invitation template
      //  template: '/api/templates/d-d7af63628b614b1c88bd9813e70fc198',
      //  var: {
      //    investorName: users.person.entity.name,
      //    newAccountLink: authResponse.data[0].changePasswordURL
      //   }
      // }
      // yield call(api.post, '/communications', commData)

      yield put({
        type: type.SUBMIT_TO_INVITATION_SUCCESS,
        payload: {
          entityId: userResponse.data.person.entity.id,
          userId: userResponse.data.id,
          // emailAddress: userResponse.data.emailAddress,
          entityMembershipId: entityMembershipResponse.data.id,
          isExistingInvestor: 'No',
        },
      })
      notification.success({
        message: 'Invite Successfully Sent!',
        description: 'You have successfully sent an invite!',
      })
      yield put (push('/ima/crm/investor'))
    }
  } catch (error) {console.log(error)
    yield put({ type: type.SUBMIT_TO_INVITATION_FAIL, message: error.message })
    notification.error({
      message: 'Invite Failed to send',
      description: 'Email Address Already Exist, please double check the entered email or select Yes to Existing Investor'
    })
  }
}

export function* getFundTypes(action) {
  try {
    const fundsResponse = yield call(api.get, getEndPoints('entityFunds'))
    yield put({ type: type.GET_FUND_TYPES_SUCCESS, entityFundsTypes: fundsResponse.data })
  } catch (error) {
    yield put({ type: type.GET_FUND_TYPES_FAIL, message: error.message })
  }
}


export function* getAccountTypes(action){
  try {
    const accountTypesResponse = yield call(api.get, getEndPoints('entityTypes'))
    yield put({type: type.GET_ACCOUNT_TYPES_SUCCESS, entityAccountTypes: accountTypesResponse.data })
  }catch (error){
    yield put({ type: type.GET_ACCOUNT_TYPES_FAIL,error })
  }

}


export function* SEARCH_INVESTOR({ resolve, reject, email }) {console.log('SAGA TRIGGERED')
  try {
    const response = yield call(api.get, getEndPoints(['searchUser', email]))
    console.log('resp',response)
    yield call(resolve, response.data)
    yield put({type: type.SEARCH_INVESTOR_SUCCESS, investor: response.data})
  } catch (error) {
    yield put({type: type.SEARCH_INVESTOR_FAIL, error})
  }
}

export function* FETCH_INVESTOR({ resolve, reject, userId }) {
  try {
    const userResponse = yield call(api.get, getEndPoints('users', userId))
    yield put({type: type.LOAD_INVESTOR_INFO_SUCCESS, investorInfo: userResponse.data})
    const {
      person: { entityId },
    } = userResponse.data

    const contactResponse = yield call(api.get, getEndPoints('entities', entityId))
    yield put({type: type.LOAD_INVESTOR_ENTITY_SUCCESS, investorEntity: contactResponse.data})
    const userData = userResponse.data
    const contactData = contactResponse.data

    yield call(resolve, { userData, contactData })
  } catch (error) {
    yield call(reject, 'error')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(type.SUBMIT_TO_INVITATION, sendInvitation),
    takeEvery(actions.SUBMIT_IO_INVITATION_REDUCERS, SUBMIT_IO_INVITATION_REDUCERS),
    takeEvery(type.GET_FUND_TYPES, getFundTypes),
    takeEvery(type.GET_ACCOUNT_TYPES,getAccountTypes),
    takeEvery(actions.SEARCH_INVESTOR, SEARCH_INVESTOR),
    takeEvery(actions.FETCH_INVESTOR, FETCH_INVESTOR),
  ])
}
