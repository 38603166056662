import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import { getLeftMenu } from 'services/menu'

import { getSeachableMenu } from './selectors'

import actions from './actions'

export function* GET_DATA({ resolve, reject, accountType, accountStatus }) {
  try {
    let menuLeftData = yield call(getLeftMenu)
    // const menuTopData = yield call(getTopMenuData)

    const menuArray = []
    const statusMenuArray = []

    for (let i = 0; i < menuLeftData.length; i += 1) {
      if ((menuLeftData[i].role !== undefined) && (menuLeftData[i].role.includes(accountType[0]))) {
        menuArray.push(menuLeftData[i])
      }
    }

    if (accountStatus !== undefined){
      for (let i = 0; i < menuArray.length; i += 1) {
        if ((menuArray[i].status !== undefined) && (menuArray[i].status.includes(accountStatus))) {
          statusMenuArray.push(menuArray[i])
        }
      }
      menuLeftData = statusMenuArray
    }


    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
        searchableMenu: menuLeftData,
        // menuTopData,
      },
    })

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

// Code below is for getting accounts as subchildren in accounts menu

// export function* GET_INVESTOR_ACCOUNTS() {
//   // const menuLeftData = yield call(getInvestorLeftMenuData)
//   const investorAccounts = yield select(getInvestorAccounts)
//   return investorAccounts.map((investorAccount) => ({
//     key: investorAccount.id,
//     title: investorAccount.fundName,
//     url: `/accounts/${investorAccount.id}`,
//     role: 'investor_accredited',
//   }))
// }

export function* SEARCH_ON_MENU({ keyword }) {
  let searchedMenu
  const seachableMenu = yield select(getSeachableMenu)
  const menuLeftData = [...seachableMenu]
  const flattendedMenuData = []

  menuLeftData.forEach((menu) => {
    if (menu.children) {
      menu.children.forEach((childMenu) => {
        flattendedMenuData.push(childMenu)

        if (childMenu.children) {
          childMenu.children.forEach((childrenMenu) => {
            flattendedMenuData.push(childrenMenu)
          })
        }
      })

      flattendedMenuData.push(menu)
    } else {
      flattendedMenuData.push(menu)
    }
  })

  if (keyword !== '') {
    searchedMenu = flattendedMenuData.filter(
      (menu) => menu.key && menu.key.toLowerCase().indexOf(keyword.toLowerCase()) > -1,
    )
  }

  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData: searchedMenu || menuLeftData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // GET_DATA(), // run once on app load to fetch menu data,
    takeEvery(actions.GET_DATA, GET_DATA),
    takeEvery(actions.SEARCH_ON_MENU, SEARCH_ON_MENU),
    // takeEvery(actions.BROKER_LEFT_MENU, BROKER_LEFT_MENU),
    // takeEvery(actions.INVESTOR_LEFT_MENU, INVESTOR_LEFT_MENU),
    // takeEvery(actions.FUND_LEFT_MENU, FUND_LEFT_MENU),
  ])
}
