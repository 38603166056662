const noEmptyValues = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === '') delete obj[key]
    if (key === 'souceOfValue') delete obj[key]
  })
  return obj
}

const utility = {
  noId: obj => {
    const { id, ...newObj } = obj
    return noEmptyValues(newObj)
  },
}



export default utility
