import React from 'react'
import { Field } from 'redux-form'
import Validators from 'utils/Validators'
// scss Validators from 'app/utils/Validators'
import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const { emptyValidator } = Validators

// eslint-disable-next-line no-return-assign
const StreetOneField = ({ placeholder,check,isStreetRequired }) => (
  <Field
    disabled={check}
    name="streetOne"
    placeholder={placeholder}
    component={inputType}
    validate={isStreetRequired ===true ? [emptyValidator]:""}
  />
)
export default StreetOneField
