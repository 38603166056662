import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Callback from 'pages/shared/user/callback/Callback'
// import style from './LayoutStyle.module.scss'
import Loader from './Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import SettingsLayout from './Main/Settings'
import InvestorLayout from './Investor'
// import AuthLayout from './Auth'


const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  settings: SettingsLayout,
  investor: InvestorLayout,
  //   auth: AuthLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {
  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      // user: { role },
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }

      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }

      if (/^\/setting/i.test(pathname)) {
        return 'settings'
      }

      if (pathname === '/callback') {
        return 'callback'
      }

      if (/investor/i.test(user.accountType)) {
        return 'investor'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLoginLayout = getLayout() === 'login'
    const isCallback = getLayout() === 'callback'
    const isSettings = getLayout() === 'settings'
    const isInvestor = getLayout() === 'investor'
    const isPublic = getLayout() === 'public'
    // const isAuthLayout = getLayout() === 'auth'
    // const hasGuestRole = role === 'guest'

    const BootstrappedLayout = () => {
      // redirect to login if layout is public
      // changed  if (isUserLoading && !isUserAuthorized && !isAuthLayout)
      if (isPublic && !isUserAuthorized) {
        return <Redirect to="/user/login" />
      }

      // show loader in initial login when user is authorized but user is loading
      if (isUserLoading && isUserAuthorized) {
        return <Loader />
      }

      // show loader on refresh
      if (!isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }

      // redirect to login page if current is not login page and user not authorized
      /* if (!isLoginLayout && !isUserAuthorized) {
        console.log('redirect')
        return <Redirect to="/user/login" />
      } */

      // show loader when user in check authorization process, not authorized yet and not on login pages
      /* if (!isUserAuthorized && !isLoginLayout) {
        console.log('in refresh')
        return <UnknownLoader />
      } */

      /* if (!isUserAuthorized && isLoginLayout) {
        console.log('first login')
        return <UnknownLoader />
      } */

      // show loader when user is redirect to /callback url from the passwordless auth0
      if (isCallback) {
        return <Callback />
      }

      // redirect to login page if current is not login page and user not authorized
      /* if (!isLoginLayout && !isUserAuthorized) {
        return <Redirect to="/user/login" />
      } */

      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized) {
        if(user.accountStatus === 'invited'){
          return <Redirect to="/lobby/onboarding" />
        }
        return <Redirect to="/home" />
      }

      if (isSettings) {
        return (
          <MainLayout>
            <Container>{children}</Container>
          </MainLayout>
        )
      }
      if (isUserAuthorized && isInvestor) {
        return (
          <MainLayout isInvestor={isInvestor}>
            <Container>{children}</Container>
          </MainLayout>
        )
      }

      return <Container>{children}</Container>
    }

    return (
      <div>
        <Helmet titleTemplate="S2N | %s" title="S2N" />
        {BootstrappedLayout()}
      </div>
    )
  }
}
// export default withRouter(connect(mapStateToProps)(Layout))
export default IndexLayout
