import React, { Component, useRef } from 'react'
import { Form, Radio, Space } from 'antd'
import { FormSection } from 'redux-form'
import { connect } from 'react-redux'
import RadioField from 'components/AppComponents/FormSection/formFields/RadioField'
import SearchAndSelectField from 'components/AppComponents/FormSection/formFields/SearchAndSelectField'
import FormPartContainer from './FormPartContainer'
import styles from './styles/style.module.scss'

export const Paragraph =(text) =>{
  const myRef = useRef();
  return (
    <p ref={myRef} className={styles.newInvestor}>{text.name}</p>
  )
}

class NameContainer extends Component {

  handleBlur = (event) => {
    event.preventDefault()
  }

  changeInvestorName = (event) => {
    const { dispatch } = this.props
    dispatch({
      type: 'iopreview/CHANGE_INVESTOR_NAME',
      payload: {
        value: event.target.value,
      },
    })
  }

render() {
  const {
    title,
    onChange,
    showExistingInvestorField,
    isExistingInvestor,
    initializeWithExistingInvestor,
    handleLoadingInvestor
  }
    = this.props
  const isDisable = isExistingInvestor === 'yes' ? 'disabled':''
    return(
      <FormSection name="investorName">
          {title && (<h3 className="h3Border">{title}</h3>)}
          {showExistingInvestorField && (
            <Form.Item style={{paddingLeft: "1%"}} colon={false} label={<Paragraph name="Is this a new investor?" />}>
              <div style={{paddingLeft: "1%"}} className={styles.isExistingInvestorBlock}>
                <RadioField buttonStyle="solid" name="isExistingInvestor" onChange={onChange}>
                  <Space size="large">
                    <Radio.Button value="no">Yes</Radio.Button>
                    <Radio.Button value="yes">No</Radio.Button>
                  </Space>
                </RadioField>
                {isExistingInvestor === 'yes' && (
                  <div className={styles.searchAndSelectField} style={{marginLeft: "1em"}}>
                    <SearchAndSelectField
                      handleBlur={this.handleBlur}
                      initializeWithExistingInvestor={initializeWithExistingInvestor}
                      handleLoadingInvestor={handleLoadingInvestor}
                    />
                  </div>
                )}
              </div>
            </Form.Item>
          )}
      </FormSection>
    )
  }
}

export default connect(null, dispatch => ({ dispatch }))(NameContainer)
