import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Col, Form, Input, Modal, Row, Select, Checkbox, Radio } from 'antd'
import { CreditCardOutlined } from '@ant-design/icons'

import InputField from 'components/CoreComponents/InputField'
import Validators from 'utils/Validators'
import Formatters from 'utils/Formatters'
import States from 'utils/states.json'
import SelectField from 'components/AppComponents/FormSection/component/SelectFieldComponent'
import Free from './FreePlanComponent'
import Standard from './StandardPlanComponent'
import Extra from './PremiumPlanComponent'

import styles from '../../../investor/onboarding/onboardingStyle.module.scss'

const { emptyValidator, validatePC } = Validators
const { formatPC, formatPhone, parseSSN } = Formatters // formatPhone

const FormItem = Form.Item
const SelectOption = Select.Option
const FORM_NAME = 'PricingTables'

const getStates = () => {
  const states = States.slice()
  return states.map(state => (
    <SelectOption key={state.abbreviation} value={state.abbreviation}>
      {state.name}
    </SelectOption>
  ))
}

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class PricingTables extends React.Component {
  state = {
    sameAddress: true,
  }

  removeBilling = value => {
    this.setState({ sameAddress: value })
  }

  closeCCModal = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'showCCModal',
        value: false,
      },
    })
  }

  render() {
    const {
      settings: { showCCModal, autoFocus },
    } = this.props
    const { sameAddress } = this.state

    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-4">
            <Free />
          </div>
          <div className="col-lg-4">
            <Standard />
          </div>
          <div className="col-lg-4">
            <Extra />
          </div>
        </div>
        <Modal
          title="Subscriber Information"
          visible={showCCModal}
          onOk={this.closeCCModal}
          onCancel={this.closeCCModal}
        >
          <Form>
            <h3>Phone Number</h3>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem>
                  <Field
                    name="phone"
                    component={InputField}
                    placeholder="Phone Number"
                    format={value => formatPhone(value, '-')}
                    parse={parseSSN}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <Radio.Group className={styles.phoneType}>
                  <strong>Mobile:</strong> &nbsp;
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <h3>Mailing Address</h3>
            <Row gutter={16}>
              <Col span={12}>
                <FormItem>
                  <Field
                    name="street"
                    component={InputField}
                    placeholder="Address Line 1"
                    validate={emptyValidator}
                    autoFocus={autoFocus}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem>
                  <Field name="street2" component={InputField} placeholder="Address Line 2" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <FormItem>
                  <Field
                    name="city"
                    component={InputField}
                    placeholder="City"
                    validate={emptyValidator}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem>
                  <Field
                    style={{ width: '100%' }}
                    name="state"
                    showSearch
                    component={SelectField}
                    placeholder="State"
                    validate={emptyValidator}
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    refProps={this.ref}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <SelectOption value="">State</SelectOption>
                    {getStates()}
                  </Field>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem>
                  <Field
                    name="postalCode"
                    component={InputField}
                    placeholder="Zip Code"
                    format={value => formatPC(value, '-')}
                    validate={validatePC}
                  />
                </FormItem>
              </Col>
            </Row>
            <Checkbox
              defaultChecked={sameAddress}
              onChange={e => this.removeBilling(e.target.checked)}
            >
              Billing address is same as mailing address
            </Checkbox>
            <br />
            <br />
            {sameAddress ? null : (
              <Fragment>
                <h3>Billing Address</h3>
                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem>
                      <Field
                        name="billingStreet"
                        component={InputField}
                        placeholder="Address Line 1"
                        validate={emptyValidator}
                        autoFocus={autoFocus}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem>
                      <Field
                        name="billingStreet2"
                        component={InputField}
                        placeholder="Address Line 2"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <FormItem>
                      <Field
                        name="billingCity"
                        component={InputField}
                        placeholder="City"
                        validate={emptyValidator}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem>
                      <Field
                        style={{ width: '100%' }}
                        name="billingState"
                        showSearch
                        component={SelectField}
                        placeholder="State"
                        validate={emptyValidator}
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        refProps={this.ref}
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <SelectOption value="">State</SelectOption>
                        {getStates()}
                      </Field>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem>
                      <Field
                        name="billingPostalCode"
                        component={InputField}
                        placeholder="Zip Code"
                        format={value => formatPC(value, '-')}
                        validate={validatePC}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Fragment>
            )}
            <h3>Credit Card Information</h3>
            <div className="form-group">
              <Form.Item
                label="Card Number"
                name="cardNumber"
                rules={[{ required: true, message: 'Please input Card Number!' }]}
              >
                <Input addonBefore={<CreditCardOutlined />} placeholder="Card Number" />
              </Form.Item>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="form-group">
                  <Form.Item
                    label="Expiration Date"
                    name="expirationDate"
                    rules={[{ required: true, message: 'Please input Card Expiration Date!' }]}
                  >
                    <Input id="checkout-cardexpdate" placeholder="MM / YY" />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-5 pull-right">
                <div className="form-group">
                  <Form.Item
                    label="Card CVC"
                    name="cardcvc"
                    rules={[{ required: true, message: 'Please input Card CVC!' }]}
                  >
                    <Input id="checkout-cardholder" placeholder="CVC" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="form-group">
              <Form.Item
                label="Card Holder Name"
                name="cardholdername"
                rules={[{ required: true, message: 'Please input Card Holder Name!' }]}
              >
                <Input id="checkout-cardholder" placeholder="Name and Surname" />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </Fragment>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
})(PricingTables)
