import actions from './actions'
import * as type from './types'
import { GET_ACCOUNT_TYPES } from './types'

const initialState = {
  entityId: '',
  userId: '',
  formType: '',
  fund: null,
  accountType: '',
  expectedContribution: '',
  isExistingInvestor: '',
  firstName: '',
  middleInitial: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  sms: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '',
  state: '',
  city: '',
  country: 'USA',
  entityMembershipId: '',
  communicationsId: '',
  userSoftwareAccountsId: '',
  userSoftwareAccountMembershipsId: '',
  entityFundsTypes: [],
  entityAccountTypes: [],
  error: null,
  loading: false,
  investor: "",
  investorEntity: "",
  investorInfo: "",
  invitationSend: false
}

export default function investorOnBoardingReducer(state = initialState, action) {
  switch (action.type) {
    case type.SUBMIT_TO_INVITATION:
      return {
        ...state
      }
    case type.SUBMIT_TO_INVITATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        invitationSend: true
      }
    case type.SUBMIT_TO_INVITATION_FAIL:
      return {
        ...state,
        error: action.message,
        invitationSend: true
      }

    case type.LOAD_INVESTOR_INFO_SUCCESS:{
      return {
        ...state,
        investorInfo: action.investorInfo
      }
    }
    case type.LOAD_INVESTOR_INFO_FAIL:{
      return {
        ...state,
        error: action.message
      }
    }

    case type.LOAD_INVESTOR_ENTITY_SUCCESS:{
      return {
        ...state,
        investorEntity: action.investorEntity
      }
    }
    case type.LOAD_INVESTOR_ENTITY_FAIL:{
      return {
        ...state,
        error: action.message
      }
    }


    case actions.FETCH_INVESTOR_SUCCESS:
      return {
        ...state,
        communicationsId: action.payload.communicationsId,
        userSoftwareAccountsId: action.payload.userSoftwareAccountsId,
        userSoftwareAccountMembershipsId: action.payload.userSoftwareAccountMembershipsId,
      }

    case type.GET_FUND_TYPES:
      return {
        ...state,
      }
    case type.GET_FUND_TYPES_SUCCESS:
      return {
        ...state,
        entityFundsTypes: action.entityFundsTypes
      }
    case type.GET_FUND_TYPES_FAIL:
        return {
          ...state,
          error: action.message
        }

    case type.GET_ACCOUNT_TYPES:
      return {
        ...state,
      }
    case type.GET_ACCOUNT_TYPES_SUCCESS:
       return {
         ...state,

         entityAccountTypes: action.entityAccountTypes
       }
    case type.GET_ACCOUNT_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.message
      }

    case type.SEARCH_INVESTOR:{
      return {
        ...state
      }
    }
    case type.SEARCH_INVESTOR_SUCCESS:{
      return {
        ...state,
        investor: action.investor
      }
    }
    case type.SEARCH_INVESTOR_FAIL:{
      return {
        ...state,
        error: action.message
      }
    }

    default:
      return state
  }
}
