import 'rc-drawer/assets/index.css'
import React from 'react'
import DrawerMenu from 'rc-drawer'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  isMenuTop: settings.isMenuTop,
  isSidebarOpen: settings.isSidebarOpen,
  isSidebarView: settings.isSidebarView,
  isLightTheme: settings.isLightTheme,
})

@withRouter
@connect(mapStateToProps)
class AppMenu extends React.Component {
  toggleOpen = () => {
    const { dispatch, isSidebarOpen } = this.props
    document
      .querySelector('#root')
      .setAttribute(
        'style',
        !isSidebarOpen ? 'overflow: hidden; width: 100%; height: 100%;' : '',
      )
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      },
    })
  }

  render() {
    const { isMenuTop, isSidebarOpen, isSidebarView, isLightTheme } = this.props
    const BootstrappedMenu = () => {
      if (isSidebarView && !isMenuTop) {
        return (
          <DrawerMenu
            getContainer={null}
            level="all"
            open={isSidebarOpen}
            onHandleClick={this.toggleOpen}
            className={classNames(isLightTheme ? 'drawer-light' : '', styles.drawerMenu)}
          >
            <MenuLeft />
          </DrawerMenu>
        )
      }
      if (isMenuTop) {
        return <MenuTop />
      }
      return <MenuLeft />
    }

    return BootstrappedMenu()
  }
}

export default AppMenu
