import { takeEvery, put, all, call } from 'redux-saga/effects'

import actions from './actions'

export function* STORE_ARTICLE({ resolve, reject, editorValue }) {
  try {
    yield put({
      type: actions.STORE_ARTICLE_SUCCESS,
      payload: { editorValue },
    })

    yield call(resolve, 'success')
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.STORE_ARTICLE, STORE_ARTICLE)])
}
