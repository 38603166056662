import React from 'react'
import { connect } from 'react-redux'
import { Switch, Input, Select, Slider, Row, Col } from 'antd'

import ExpandedLogo from './components/ExpandedLogo'
import CollapsedLogo from './components/CollapsedLogo'
import BackgroundImage from './components/BackgroundImage'

import styles from './appSettingsStyle.module.scss'
import settingsStyles from '../settingStyle.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

// const pics = {
//   action: '//jsonplaceholder.typicode.com/posts/',
//   listType: 'picture',
// }

@connect(mapStateToProps)
class ThemeSettings extends React.Component {
  changeSetting = (setting, value) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    })
  }

  selectRouterAnimation = value => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'routerAnimation',
        value,
      },
    })
  }

  selectApplicationColor = color => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_APPLICATION_COLOR',
      payload: {
        color,
      },
    })
  }

  selectSelectionColor = color => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_SELECTION_COLOR',
      payload: {
        color,
      },
    })
  }

  selectHoverColor = color => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_HOVER_COLOR',
      payload: {
        color,
      },
    })
  }

  selectTopOverlay = color => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_TOP_OVERLAY',
      payload: {
        color,
      },
    })
  }

  selectBottomOverlay = color => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_BOTTOM_OVERLAY',
      payload: {
        color,
      },
    })
  }

  selectBackgroundOpacity = opacity => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/SET_BACKGROUND_OPACITY',
      payload: {
        opacity,
      },
    })
  }

  render() {
    const {
      settings: {
        isSettingsOpen,
        isMenuTop,
        isMenuCollapsed,
        isGrayTopbar,
        isBorderless,
        isMenuShadow,
        isCardShadow,
        isGrayBackground,
        isRoundedCorners,
        isAppMaxWidth,
        selectionColor,
        applicationColor,
        hoverColor,
        isContentMaxWidth,
        routerAnimation,
        leftMenuWidth,
        logo,
        backgroundOverlayTop,
        backgroundOverlayBottom,
        backgroundOpacity,
      },
    } = this.props

    function percentFormatter(value) {
      return `${value}%`
    }

    return (
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Company Name</span>
            <Input
              value={logo}
              onChange={e => {
                const { value } = e.target
                this.changeSetting('logo', value)
              }}
            />
          </div>
          <div className={styles.uploadWrapper}>
            <ExpandedLogo />
            <span>Expanded Logo</span>
          </div>
          <div className={styles.uploadWrapper}>
            <CollapsedLogo />
            <span>Collapsed Logo</span>
          </div>
          <div className={styles.uploadWrapper}>
            <BackgroundImage />
            <span>Background Image</span>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.title}>Theme Settings</div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Application Color</span>
            <input
              className={styles.colorPicker}
              type="color"
              id="colorPicker"
              onChange={e => this.selectApplicationColor(e.target.value)}
              value={applicationColor}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Selection Color</span>
            <input
              className={styles.colorPicker}
              type="color"
              id="colorPicker"
              onChange={e => this.selectSelectionColor(e.target.value)}
              value={selectionColor}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Hover Color</span>
            <input
              className={styles.colorPicker}
              type="color"
              id="colorPicker"
              onChange={e => this.selectHoverColor(e.target.value)}
              value={hoverColor}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Background Overlay Top</span>
            <input
              className={styles.colorPicker}
              type="color"
              id="colorPicker"
              onChange={e => {
                this.selectTopOverlay(e.target.value)
              }}
              value={backgroundOverlayTop}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Background Overlay Bottom</span>
            <input
              className={styles.colorPicker}
              type="color"
              id="colorPicker"
              onChange={e => this.selectBottomOverlay(e.target.value)}
              value={backgroundOverlayBottom}
            />
          </div>
          <div className={styles.item}>
            <span>Background Opacity</span>
            <Slider
              className={styles.slider}
              value={backgroundOpacity}
              /* eslint-disable-next-line react/jsx-no-bind */
              tipFormatter={percentFormatter}
              min={0}
              max={1}
              step={0.01}
              onChange={value => {
                this.selectBackgroundOpacity(value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span>Left Menu Width</span>
            <Slider
              className={styles.slider}
              disabled={isSettingsOpen}
              value={leftMenuWidth}
              min={256}
              max={330}
              onChange={value => {
                this.changeSetting('leftMenuWidth', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Page Transition</span>
            <Select
              defaultValue={routerAnimation}
              style={{ width: '100%' }}
              onChange={this.selectRouterAnimation}
            >
              <Select.Option value="none">None</Select.Option>
              <Select.Option value="slide-fadein-up">Slide Up</Select.Option>
              <Select.Option value="slide-fadein-right">Slide Right</Select.Option>
              <Select.Option value="fadein">Fade In</Select.Option>
              <Select.Option value="zoom-fadein">Zoom</Select.Option>
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Collapsed Menu</span>
            <Switch
              className={styles.switch}
              disabled={isMenuTop}
              checked={isMenuCollapsed && !isMenuTop}
              onChange={value => {
                this.changeSetting('isMenuCollapsed', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Menu Shadow</span>
            <Switch
              className={styles.switch}
              disabled={isMenuTop}
              checked={isMenuShadow && !isMenuTop}
              onChange={value => {
                this.changeSetting('isMenuShadow', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Borderless Cards</span>
            <Switch
              className={styles.switch}
              checked={isBorderless}
              onChange={value => {
                this.changeSetting('isBorderless', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Rounded Corners</span>
            <Switch
              className={styles.switch}
              checked={isRoundedCorners}
              onChange={value => {
                this.changeSetting('isRoundedCorners', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Card Shadow</span>
            <Switch
              className={styles.switch}
              disabled={isSettingsOpen}
              checked={isCardShadow}
              onChange={value => {
                this.changeSetting('isCardShadow', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Topbar Gray Background</span>
            <Switch
              className={styles.switch}
              disabled={isSettingsOpen}
              checked={isGrayTopbar}
              onChange={value => {
                this.changeSetting('isGrayTopbar', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Content Max-Width</span>
            <Switch
              className={styles.switch}
              disabled={isSettingsOpen}
              checked={isContentMaxWidth}
              onChange={value => {
                this.changeSetting('isContentMaxWidth', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Max-Width</span>
            <Switch
              className={styles.switch}
              disabled={isSettingsOpen}
              checked={isAppMaxWidth}
              onChange={value => {
                this.changeSetting('isAppMaxWidth', value)
              }}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.itemLabel}>Gray Background</span>
            <Switch
              className={styles.switch}
              disabled={isSettingsOpen}
              checked={isGrayBackground}
              onChange={value => {
                this.changeSetting('isGrayBackground', value)
              }}
            />
            <button
              type="submit"
              className={settingsStyles.btngray}
              onClick={() => {
                this.selectSelectionColor('#7d9c6e')
                this.selectApplicationColor('#748e67')
                this.selectHoverColor('#7d9c6e')
              }}
            >
              RESTORE DEFAULTS
            </button>
          </div>
        </Col>
      </Row>
    )
  }
}

export default ThemeSettings
