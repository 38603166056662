import { takeEvery, call, all } from 'redux-saga/effects'

import Api from 'services/api/Api'
import actions from './actions'

const api = new Api()

export function* FETCH_FUND_DATA({ resolve, reject, fundType }) {
  try {
    const response = yield call(api.get, `${fundType}/statements`)
    yield call(resolve, response.data.results)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_FUND_DATA, FETCH_FUND_DATA)])
}
