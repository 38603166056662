import actions from './actions'
import * as type from './types'

const initialState = {
  sameAsPersonal: false,
  fromReview: false,
  userResponse: '',
  entityTypeId: '',
  detailFormValues: '',
  accountDetailFormValues: '',
  verificationFormValues: '',
  investmentDetails: '',
  formValues: '',
  message: ''
}

export default function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SUBMIT_ENTITY_DETAILS_SUCCESS:
      return { ...state, ...action.payload }
    case actions.SUBMIT_ONBOARDING_DETAIL_SUCCESS:
      return {
        ...state,
        entityTypeId: action.payload.entityTypeId,
        detailFormValues: action.payload.detailFormValues,
      }
    case actions.SUBMIT_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetailFormValues: action.payload.accountDetailFormValues,
      }
    case actions.SUBMIT_VERIFICATION_SUCCESS:

      return {
        ...state,
        verificationFormValues: action.payload.verificationFormValues,
      }
    case  type.ENTITY_FORM_DETAILS:
      return {
        ...state,
        formValues: action.payload
      }
    case type.GET_INVESTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        investmentDetails: action.investmentDetails
      }
    case type.GET_INVESTMENT_DETAILS_FAILED:
      return {
        ...state,
        message: action.message
      }
    default:
      return state
  }
}
