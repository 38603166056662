import React from 'react'
import { Field } from 'redux-form'

import IdentityInputFieldComponent from '../component/IdentityInputFieldComponent'
import InputFieldComponent from '../component/InputFieldComponent'


const path = window.location.pathname
const inputType = path === '/onboarding/identity' ? IdentityInputFieldComponent: InputFieldComponent

const StreetTwoField = ({ placeholder,check }) => (
  <Field name="streetTwo" placeholder={placeholder} component={inputType} disabled={check} />
)

export default StreetTwoField
