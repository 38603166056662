import { all, call, takeEvery } from 'redux-saga/effects'

import actions from './actions'

export function* FETCH_ADVISOR({ resolve, reject }) {
  try {
    const Advisor = [
      {
        id: 1,
        advisorAvatar: '/resources/images/avatars/avatar.png',
        advisorImage: '/resources/images/advisor/ria1.jpg',
        advisorName: 'True North Advisors',
        advisorAddress: '123 Mains St. Covington, CO 90101',
        advisorEmpCount: '44',
        dbaDate: 'Since 1996',
      },
      {
        id: 2,
        advisorAvatar: '/resources/images/avatars/avatar.png',
        advisorImage: '/resources/images/advisor/ria2.jpg',
        advisorName: 'Waldron Private Wealth',
        advisorAddress: '123 Mains St. Covington, CO 90101',
        advisorEmpCount: '10',
        dbaDate: 'Since 2015',
      },
      {
        id: 3,
        advisorAvatar: '/resources/images/avatars/avatar.png',
        advisorImage: '/resources/images/advisor/ria3.jpg',
        advisorName: 'Tolleson Wealth Management',
        advisorAddress: '123 Mains St. Covington, CO 90101',
        advisorEmpCount: '10',
        dbaDate: 'Since 2014',
      },
      {
        id: 4,
        advisorAvatar: '/resources/images/avatars/avatar.png',
        advisorImage: '/resources/images/advisor/ria4.png',
        advisorName: 'Wealth Management',
        advisorAddress: '123 Mains St. Covington, CO 90101',
        dbaDate: 'Since 2018',
        advisorEmpCount: '33',
      },
            {
        id: 5,
              advisorAvatar: '/resources/images/avatars/avatar.png',
              advisorImage: '/resources/images/advisor/ria5.png',
              advisorName: 'PNC Wealth Management',
              advisorAddress: '123 Mains St. Covington, CO 90101',
              advisorEmpCount: '33',
              dbaDate: 'Since 2001',
      },
    ]

    yield call(resolve, Advisor)
  } catch (error) {
    yield call(reject, error)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_ADVISOR, FETCH_ADVISOR)])
}
