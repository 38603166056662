import React, { Component } from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Field, reduxForm } from 'redux-form'

import ImageUploader from 'components/AppComponents/FormSection/formFields/ImageUploader'
import styles from 'layouts/Settings/SettingsStyle.module.scss'

const FORM_NAME = 'userAvatarForm'

class UserAvatar extends Component {
  state = {
    image: [],
  }

  uploadUserPicture = file => {
    console.log(file)
  }

  render() {
    const { image } = this.state

    const uploadButton = <Avatar size={95} icon={<UserOutlined />} /* height='1em' width='1em' */ />

    return (
      <div className={styles.userAvatar}>
        <Field
          name="userAvatar"
          component={ImageUploader}
          medias={image || []}
          onRemove={this.deleteUserPicture}
          onUpload={this.uploadUserPicture}
          beforeUpload={this.beforeUserPictureUpload}
          uploadLimit={1}
          accept="image/*"
          uploadButton={uploadButton}
        />
      </div>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
})(UserAvatar)
