import actions from './actions'

const initialState = {
  owningEntity: "Entity",
  fundName: "Fund",
  fundID: "ID",
  fundEIN: "EIN",
  typeOfFund: "Type",
  preferredReturn: 0,
  hurdleOne: 0,
  hurdleTwo: 0,
  hurdleThree: 0,
  hurdleLast: 0,
  openEnded: "",
  accreditedInvestor: "",
  gpReturn: 0,
  lpReturn: 0,
  hurdle1GP: 0,
  hurdle2GP: 0,
  hurdle3GP: 0,
  hurdleLastGP: 0,
  hurdle1LP: 0,
  hurdle2LP: 0,
  hurdle3LP: 0,
  hurdleLastLP: 0,
  aumUpdate:"Frequency",
  distFrequency: "Frequency",
  managementFee: 0,
  closeExtensions: 0,
  managementFeeCharge: "Charge",
  preferredPerpetuity: "T/F",
  managementStepDown: "yyyy/mm/dd",
  dropDownDate: "yyyy/mm/dd",
  fundCloseDate: "yyyy/mm/dd",
}

export default function fundEntitiesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
