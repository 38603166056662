export const GET_FUND_TYPES               = 'investorInvitation/GET_FUND_TYPES'
export const GET_FUND_TYPES_SUCCESS       = 'investorInvitation/GET_FUND_TYPES_SUCCESS'
export const GET_FUND_TYPES_FAIL          = 'investorInvitation/GET_FUND_TYPES_FAIL'

export const GET_ACCOUNT_TYPES            = 'investorInvitation/GET_ACCOUNT_TYPES'
export const GET_ACCOUNT_TYPES_SUCCESS    = 'investorInvitation/GET_ACCOUNT_TYPES_SUCCESS'
export const GET_ACCOUNT_TYPES_FAIL       = 'investorInvitation/GET_ACCOUNT_TYPES_FAIL'

export const SUBMIT_TO_INVITATION         = 'investorInvitation/SUBMIT_TO_INVITATION'
export const SUBMIT_TO_INVITATION_SUCCESS = 'investorInvitation/SUBMIT_TO_INVITATION_SUCCESS'
export const SUBMIT_TO_INVITATION_FAIL    = 'investorInvitation/SUBMIT_TO_INVITATION_FAIL'

export const SEARCH_INVESTOR              = 'investorInvitation/SEARCH_INVESTOR'
export const SEARCH_INVESTOR_SUCCESS      = 'investorInvitation/SEARCH_INVESTOR_SUCCESS'
export const SEARCH_INVESTOR_FAIL         = 'investorInvitation/SEARCH_INVESTOR_FAIL'

export const LOAD_INVESTOR_INFO           = 'investorInvitation/LOAD_INVESTOR_INFO'
export const LOAD_INVESTOR_INFO_SUCCESS   = 'investorInvitation/LOAD_INVESTOR_INFO_SUCCESS'
export const LOAD_INVESTOR_INFO_FAIL      = 'investorInvitation/LOAD_INVESTOR_INFO_FAIL'

export const LOAD_INVESTOR_ENTITY         = 'investorInvitation/LOAD_INVESTOR_ENTITY'
export const LOAD_INVESTOR_ENTITY_SUCCESS = 'investorInvitation/LOAD_INVESTOR_ENTITY_SUCCESS'
export const LOAD_INVESTOR_ENTITY_FAIL    = 'investorInvitation/LOAD_INVESTOR_ENTITY_FAIL'

/*
Not Used it Boiler Plate to rewire this
 */
// export const  FETCH_INVESTOR              = 'investorInvitation/FETCH_INVESTOR'
// export const  FETCH_INVESTOR_SUCCESS      = 'investorInvitation/SUBMIT_IO_INVITATION_SUCCESS'
// export const  FETCH_INVESTOR_FAIL         = 'investorInvitation/FETCH_INVESTOR_FAIL'

