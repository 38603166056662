import { takeEvery, all, call /* , put */ } from 'redux-saga/effects'
// scss { push } from 'react-router-redux'
import { EntityTypes } from 'pages/shared/ProfileComponents/entityTypes'
// scss { notification } from 'antd'
import Api from 'services/api/Api'
import utility from 'utils/uitility'
import { FETCH_RESOURCE, FETCH_ENTITY, SAVE_ENTITY } from '../entity/sagas'
import { SAVE_RESOURCE } from '../general/sagas'
import actions from './actions'

const api = new Api()
const { noId } = utility

export function* FETCH_USER_DETAIL({ resolve, reject, userId }) {
  try {
    const userInfo = yield call(FETCH_RESOURCE, ['user', userId])
    const {
      id: userId_,
      person: {
        firstName,
        lastName,
        id,
        entity: { id: entityId },
      },
      accountMemberships: [
        {
          account: { id: accountId },
          accountMembershipType: { id: accountMembershipTypeId },
        },
      ],
      userAvatar,
    } = userInfo

    const entity = yield call(FETCH_ENTITY, { entityId })
    const { emailInfo, phoneInfo, addressInfo } = entity

    const userSection = { person: { firstName, lastName } }
    const userAvatarSection = { userAvatar }

    const oldIds = {}

    let contactSection
    if (emailInfo.results.length) {
      const {
        results: [{ email, id: emailId }],
      } = emailInfo
      oldIds.emailId = emailId
      contactSection = { contact: { email } }
    } else {
      contactSection = { contact: { email: userInfo.email } }
    }

    let phoneSection
    if (phoneInfo.results.length) {
      const {
        results: [{ phone, contactPhoneType, id: phoneId }],
      } = phoneInfo

      phoneSection = { phone }

      if (contactPhoneType) {
        phoneSection.contactPhoneType = contactPhoneType.phone
      }

      phoneSection = { phone: { ...phoneSection } }
      oldIds.phoneId = phoneId
    }

    let addressSection
    if (addressInfo.results.length) {
      const {
        results: [{ id: entityAdressId, contactAddress: address }],
      } = addressInfo
      addressSection = { address }
      oldIds.addressId = address.id
      oldIds.entityAddressId = entityAdressId
    }

    const accountSection = { accountMembershipType: accountMembershipTypeId }

    oldIds.entityId = entityId
    oldIds.userId = userId_
    oldIds.personId = id
    oldIds.accountId = accountId

    const response = {
      ...userSection,
      ...userAvatarSection,
      ...contactSection,
      ...accountSection,
      ...phoneSection,
      ...addressSection,
      oldIds,
    }

    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* CREATE_USER({ resolve, reject, formData }) {
  try {
    const { PersonEntityForm, EmailEntityForm, account } = formData

    const entity = { entityType: { id: EntityTypes.INDIVIDUAL } }
    const entityData = {
      entity,
      PersonEntityForm,
      EmailEntityForm,
    }

    const savedEntity = yield call(SAVE_ENTITY, reject, entityData)

    const user = yield call(
      SAVE_RESOURCE,
      'user',
      {
        ...formData.EmailEntityForm,
        ...formData.password,
        person: { id: savedEntity.savedPerson.id },
        userStatus: { id: 'active' },
        accountMemberships: [1].reduce(
          accumulator => [
            ...accumulator,
            {
              account: {
                id: account.id,
              },
              accountMembershipType: { id: account.accountType },
            },
          ],
          [],
        ),
      },
      'user',
    )

    yield call(resolve, user)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* UPDATE_USER({ resolve, reject, formData }) {
  const { oldIds } = formData
  try {
    const {
      account: accountSection,
      EmailEntityForm: contactSection,
      PersonEntityForm: personSection,
      PhoneEntityForm: phoneSection,
      AddressEntityForm: addressSection,
    } = formData

    const entityId = { id: EntityTypes.INDIVIDUAL }
    const entity = { entityType: entityId, id: oldIds ? oldIds.entityId : undefined }
    const PersonEntityForm = { ...personSection, id: oldIds ? oldIds.personId : undefined }
    const EmailEntityForm = {
      ...contactSection,
      isPrimary: true,
      id: oldIds ? oldIds.emailId : undefined,
    }

    const PhoneEntityForm = {}
    if (phoneSection) {
      PhoneEntityForm.phone = phoneSection.phone
      PhoneEntityForm.note = ''
      PhoneEntityForm.weight = 1
      PhoneEntityForm.isPrimary = true
      PhoneEntityForm.isSms = true
      PhoneEntityForm.id = oldIds.phoneId
      if (phoneSection.contactPhoneType)
        PhoneEntityForm.contactPhoneType = { id: phoneSection.contactPhoneType }
    }

    let AddressEntityForm = {}
    let contactAddress = {}
    if (addressSection) {
      AddressEntityForm = {
        ...addressSection,
        country: 'USA',
        id: oldIds.addressId,
      }

      contactAddress = {
        note: 'xyz',
        weight: 1,
        isPrimary: true,
        id: oldIds.entityAddressId,
      }
    }

    const entityData = {
      entity,
      PersonEntityForm,
      EmailEntityForm,
      PhoneEntityForm,
      AddressEntityForm,
      contactAddress,
    }

    const savedEntity = yield call(SAVE_ENTITY, reject, entityData)

    let userData = {
      ...formData.EmailEntityForm,
      ...formData.password,
      person: { id: savedEntity.savedPerson.id },
      userStatus: { id: 'active' },
      accountMemberships: [1].reduce(
        accumulator => [
          ...accumulator,
          {
            account: {
              id: accountSection.id,
            },
            accountMembershipType: { id: accountSection.accountType },
          },
        ],
        [],
      ),
      id: oldIds ? oldIds.userId : undefined,
    }

    // add userAvatar if exist (i.e if uploaded)

    if (formData.userAvatar.userAvatar.id) {
      userData = { ...userData, ...formData.userAvatar }
    }

    const user = yield call(SAVE_RESOURCE, 'user', userData, 'user')

    yield call(resolve, user)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* FETCH_INTERNAL_USER_LIST({ resolve, reject, data = {} }) {
  try {
    const response = yield call(api.get, 'users', data)
    yield call(resolve, response)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* CHANGE_PASSWORD({ resolve, reject, formData }) {
  try {
    const {
      changePassword: { firstPassword },
      id,
    } = formData

    const newPassword = {
      password: firstPassword,
      id,
    }

    yield call(SAVE_PASSWORD, newPassword, 'changePassword')
    yield call(resolve)
  } catch (error) {
    yield call(reject, error)
  }
}

export function* SAVE_PASSWORD(data, formSection) {
  if (!data) {
    return {}
  }

  let resource
  try {
    resource = yield call(api.patch, `users/${data.id}`, noId(data))
    return resource.data[0]
  } catch (error) {
    if (formSection) {
      throw {
        [formSection]: error.response.data,
      }
    } else {
      throw error.response.data
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_USER_DETAIL, FETCH_USER_DETAIL),
    takeEvery(actions.FETCH_INTERNAL_USER_LIST, FETCH_INTERNAL_USER_LIST),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
    takeEvery(actions.CREATE_USER, CREATE_USER),
  ])
}
